import React, { useEffect, useState } from "react";
import ReportsTable from "../../../components/ReportsTable/ReportsTable";
import "./AdminReports.css";
import { makeRequest } from "../../../services/requests";
import Loading from "../../../components/Loading";
import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

const styles = {
  select: {
    borderRadius: "10px",
    height: 50,
    minWidth: 132,
    "&.MuiPaper-root": {
      maxHeight: 300,
    },
    "&.Mui-selected": {
      bgcolor: "#322830 !important",
      backgroundColor: "#322830 !important",
    },
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
      color: "#495057",
      borderRadius: 0,
      fontFamily: "Inter",
      borderBottom: "1px solid #000",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        // border: "1px solid #656DFC !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {},
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& span": {
      color: "#9c9c9c",
    },
    "& svg": {
      color: "unset !important",
    },
  },
  menuItem: {
    "&.Mui-selected": {
      bgcolor: "#322830 !important",
    },
    // py:0
  },
  menuItemMulti: {
    "&.Mui-selected": {
      bgcolor: "#fff !important",
      color: "#000 !important",
    },
    padding: "5px 20px 5px 40px",
  },
  chip: {
    backgroundColor: "#8F6FC4",
    // padding: "2px 8px 2px 10px",
    borderRadius: 16,
    "& span": {
      color: "#fff",
    },
  },
  checkbox: {
    "& svg": {
      color: "#000 !important",
    },
  },
};

const ITEMS_PER_SCROLL = 7;

const AdminReports = () => {
  const [tableType, setTableType] = useState("Full Revenue");
  const [queryData, setQueryData] = useState({
    report_field: "revenue",
    freelancer_id: ["all"],
    category_id: ["all"],
    treatment_id: ["all"],
    location: ["all"],
    area: ["all"]
  });
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([{ id: "all", value: "All" }]);
  const [treatments, setTreatments] = useState([{ id: "all", value: "All" }]);
  const [freelancers, setFreeLancers] = useState();
  const [freeLancerArray, setFreeLancerArray] = useState([
    { id: "all", value: "All" },
  ]);
  const [page, setPage] = useState(1)

  const [county, setCounty] = useState([{ id: "all", value: "All" }])
  const [area, setArea] = useState([{ id: "all", value: "All" }])

  const [loading, setLoading] = useState(false);
  const [allLoading, setAllLoading] = useState(false);

  const [visibleItems, setVisibleItems] = useState(ITEMS_PER_SCROLL);

  const handleReportChange = (event) => {
    const value = event.target.value;
    setTableType(value);

    setQueryData({
      ...queryData,
      report_field: value,

    });

    setQueryData((prevData) => ({
      ...prevData,
      report_field: value,
      freelancer_id: prevData.freelancer_id,
      category_id: ["all"],
      treatment_id: ["all"],
      location: value !== "location" ? ["all"] : prevData.location,
      area: value !== "location" ? ["all"] : prevData.area
    }))
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all" || value.length === 0)
      setQueryData((prevData) => ({
        ...prevData,
        category_id: ["all"],
      }));
    else setQueryData((prevData) => ({
      ...prevData,
      category_id: value.filter((item) => item !== "all"),
    }));
  };

  const handleTreatmentChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all" || value.length === 0) {
      setQueryData((prevData) => ({
        ...prevData,
        treatment_id: ["all"],
      }));
    } else {
      setQueryData((prevData) => ({
        ...prevData,
        treatment_id: value.filter((item) => item !== "all"),
      }));
    }
  };

  const handleFreelancerChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all" || value.length === 0) {
      setQueryData((prevData) => ({
        ...prevData,
        freelancer_id: ["all"],
      }));
    } else {
      setQueryData((prevData) => ({
        ...prevData,
        freelancer_id: value.filter((item) => item !== "all"),
      }));
    }
  };

  const handleLocationChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all" || value.length === 0) {
      setQueryData((prevData) => ({
        ...prevData,
        location: ["all"],
      }));
    } else {
      setQueryData((prevData) => ({
        ...prevData,
        location: value.filter((item) => item !== "all"),
      }));
    }
  }

  const handleAreaChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all" || value.length === 0) {
      setQueryData((prevData) => ({
        ...prevData,
        area: ["all"],
      }));
    } else {
      setQueryData((prevData) => ({
        ...prevData,
        area: value.filter((item) => item !== "all"),
      }));
    }
  }

  const handleMenuScroll = (event, type, freelancer) => {
    const target = event.target;

    if (target.scrollTop + target.clientHeight === target.scrollHeight) {
      const remainingItems = type.length - visibleItems;
      const itemsToLoad = Math.min(ITEMS_PER_SCROLL, remainingItems);

      if (freelancer && remainingItems <= 2 && freelancers.total - freeLancerArray.length - 1 > 0) {
        fetchMoreFreelancers();
      }

      setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsToLoad);
    }
  };

  useEffect(() => {
    fetchSystemReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.report_field, queryData.category_id, queryData.treatment_id, queryData.freelancer_id, queryData.location, queryData.area]);


  const fetchSystemReports = async () => {
    setLoading(true);

    let params = {
      report_field: queryData.report_field,
      freelancer_id: queryData.freelancer_id.includes('all') ? 'all' : queryData.freelancer_id,
    };

    if (queryData.report_field === 'revenue' || queryData.report_field === 'cancellation') {
      params.category_id = queryData.category_id.includes('all') ? 'all' : queryData.category_id;
      params.treatment_id = queryData.treatment_id.includes('all') ? 'all' : queryData.treatment_id;
    } else if (queryData.report_field === 'location') {
      params.location_id = queryData.location.includes('all') ? 'all' : queryData.location;
      params.area_id = queryData.area.includes('all') ? 'all' : queryData.area;
    } else if (queryData.report_field === 'customer') {
      params.freelancer_id = queryData.freelancer_id.includes('all') ? 'all' : queryData.freelancer_id;
    }

    const resp = await makeRequest(
      "admin/system-report",
      params,
      "POST",
      true
    );

    if (resp.result === "success") {
      setData(resp.data);
    }
    setLoading(false);
  };


  const fetchOptions = async () => {
    setAllLoading(true);

    const categories = await makeRequest(
      "admin/treatment-category",
      null,
      "GET",
      true
    );

    const treatmentsRes = await makeRequest(
      "admin/treatment",
      null,
      "GET",
      true
    );

    const freelancerRes = await makeRequest(
      "admin/freelancers",
      null,
      "GET",
      true
    );

    if (categories.result) {
      let newCategory = [...category];
      newCategory.push(
        ...categories.data?.map((item) => ({
          id: item.id,
          value: item.name,
        }))
      );
      setCategory(newCategory);
    }

    if (treatmentsRes) {
      let newTreatments = [...treatments];
      newTreatments.push(
        ...treatmentsRes?.map((item) => ({
          id: item.id,
          value: item.name,
        }))
      );
      setTreatments(newTreatments);
    }

    if (freelancerRes) {
      let newFreeLancers = [...freeLancerArray];
      newFreeLancers.push(
        ...freelancerRes.data?.map((item) => ({
          id: item.user_id,
          value: `${item.first_name} ${item.last_name} `,
        }))
      );
      setFreeLancerArray(newFreeLancers);
      setFreeLancers(freelancerRes);
    }

    setAllLoading(false);
  };

  const fetchMoreFreelancers = async () => {
    setPage((prevPage) => prevPage + 1);

    const freelancerRes = await makeRequest(
      `admin/freelancers?page=${page + 1}`,
      null,
      "GET",
      true
    );
    if (freelancerRes) {
      let newFreeLancers = [...freeLancerArray];
      newFreeLancers.push(
        ...freelancerRes.data?.map((item) => ({
          id: item.user_id,
          value: `${item.first_name} ${item.last_name} `,
        }))
      );
      setFreeLancerArray(newFreeLancers);
      setFreeLancers(freelancerRes);
    }
  }

  const fetchLocations = async () => {
    const countyRes = await makeRequest(
      `admin/location`,
      null,
      "GET",
      true
    );

    const areaRes = await makeRequest(
      `admin/location-area`,
      null,
      "GET",
      true
    );

    if (countyRes.result === "success") {
      let newCoutny = [...county]
      newCoutny.push(
        ...countyRes.data?.map(item => ({
          id: item.id,
          value: item.name
        }))
      )
      setCounty(newCoutny)
    }

    if (areaRes.result === "success") {
      let newArea = [...area]
      newArea.push(
        ...areaRes.data?.map(item => ({
          id: item.id,
          value: item.name
        }))
      )
      setArea(newArea)
    }
  }

  useEffect(() => {
    fetchSystemReports();
    fetchOptions();
    fetchLocations()
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  if (allLoading) {
    return <Loading />;
  }

  return (
    <main id="main-section">
      <div className="container-fluid">
        <div id="blog-page">
          <div>
            <h2
              style={{ fontSize: 30, paddingBottom: 32 }}
              className="titleHeading2"
            >
              Reports
            </h2>

            <div className="controls-wrapper">
              <div className="controls-block">
              <p><b>Report field</b></p>
                <Select
                  value={queryData.report_field}
                  onChange={handleReportChange}
                  sx={styles.select}
                  inputProps={{ IconComponent: () => null }}
                >
                  <MenuItem value="revenue" sx={styles.menuItem}>
                    Full Revenue
                  </MenuItem>
                  <MenuItem value="cancellation" sx={styles.menuItem}>
                    Cancellations
                  </MenuItem>
                  <MenuItem value="customer" sx={styles.menuItem}>
                    Customer
                  </MenuItem>
                  <MenuItem value="location" sx={styles.menuItem}>
                    Locations
                  </MenuItem>
                </Select>
              </div>

              {queryData.report_field !== "customer" && queryData.report_field !== "location" && <>
                <div className="controls-block">
                  <p><b>Category</b></p>
                  <Select
                    value={queryData.category_id}
                    onChange={handleCategoryChange}
                    sx={styles.select}
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                        onScroll: (event) => handleMenuScroll(event, category)
                      }
                    }}
                    onClose={() => setVisibleItems(ITEMS_PER_SCROLL)}
                    multiple
                    renderValue={(selected) => {
                      return selected.includes("all") ? (
                        "All"
                      ) : (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.slice(0, 1).map((value) => (
                            <Chip
                              key={category.find((item) => item.id === value)?.value}
                              label={category.find((item) => item.id === value)?.value}
                              sx={styles.chip}
                              clickable
                            />
                          ))}
                          {selected.length > 1 && (
                            <Chip
                              label={`${selected.length - 1} +`}
                              sx={styles.chip}
                              clickable
                            />
                          )}
                        </Box>
                      );
                    }}
                  >

                    {category?.slice(0, visibleItems).map((item) => (
                      <MenuItem value={item.id} sx={styles.menuItemMulti} key={item.id}>
                        <Checkbox
                          checked={queryData?.category_id?.find((newItem) => newItem === item.id) || false}
                          sx={styles.checkbox}
                        />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="controls-block">
                  <p><b>Treatment</b></p>
                  <Select
                    value={queryData.treatment_id}
                    onChange={handleTreatmentChange}
                    sx={styles.select}
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                        onScroll: (event) => handleMenuScroll(event, treatments)
                      }
                    }}
                    onClose={() => setVisibleItems(ITEMS_PER_SCROLL)}
                    multiple
                    renderValue={(selected) => {
                      return selected.includes("all") ? (
                        "All"
                      ) : (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.slice(0, 1).map((value) => (
                            <Chip
                              key={treatments.find((item) => item.id === value)?.value}
                              label={treatments.find((item) => item.id === value)?.value}
                              sx={styles.chip}
                              clickable
                            />
                          ))}
                          {selected.length > 1 && (
                            <Chip
                              label={`${selected.length - 1} +`}
                              sx={styles.chip}
                              clickable
                            />
                          )}
                        </Box>
                      );
                    }}
                  >
                    {treatments?.slice(0, visibleItems).map((item) => (
                      <MenuItem
                        value={item.id}
                        sx={styles.menuItemMulti}
                        key={item.id}
                      >
                        <Checkbox
                          checked={
                            queryData?.treatment_id?.find(
                              (newItem) => newItem === item.id
                            ) || false
                          }
                          sx={styles.checkbox}
                        />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </>}

              {queryData.report_field !== "location" && <div className="controls-block">
                <p><b>Freelancer</b></p>
                <Select
                  value={queryData.freelancer_id}
                  onChange={handleFreelancerChange}
                  sx={styles.select}
                  inputProps={{ IconComponent: () => null }}
                  multiple
                  onClose={() => setVisibleItems(ITEMS_PER_SCROLL)}
                  renderValue={(selected) => {
                    return selected.includes("all") ? (
                      "All"
                    ) : (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.slice(0, 1).map((value) => (
                          <Chip
                            key={freeLancerArray.find((item) => item.id === value)?.value}
                            label={freeLancerArray.find((item) => item.id === value)?.value}
                            sx={styles.chip}
                            clickable
                          />
                        ))}
                        {selected.length > 1 && (
                          <Chip
                            label={`${selected.length - 1} +`}
                            sx={styles.chip}
                            clickable
                          />
                        )}
                      </Box>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                      onScroll: (event) => handleMenuScroll(event, freeLancerArray, true)
                    }
                  }}
                >
                  {freeLancerArray?.slice(0, visibleItems).map((item) => (
                    <MenuItem
                      value={item.id}
                      sx={styles.menuItemMulti}
                      key={item.id}
                    >
                      <Checkbox
                        checked={
                          queryData?.freelancer_id?.find(
                            (newItem) => newItem === item.id
                          ) || false
                        }
                        sx={styles.checkbox}
                      />
                      <ListItemText primary={item.value} />
                    </MenuItem>
                  ))}
                </Select>
              </div>}

              {queryData.report_field === "location" && <>
                <div className="controls-block">
                  <p><b>County</b></p>
                  <Select
                    value={queryData.location}
                    onChange={handleLocationChange}
                    sx={styles.select}
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                        onScroll: (event) => handleMenuScroll(event, county)
                      }
                    }}
                    onClose={() => setVisibleItems(ITEMS_PER_SCROLL)}
                    multiple
                    renderValue={(selected) => {
                      return selected.includes("all") ? (
                        "All"
                      ) : (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.slice(0, 1).map((value) => (
                            <Chip
                              key={county.find((item) => item.id === value)?.value}
                              label={county.find((item) => item.id === value)?.value}
                              sx={styles.chip}
                              clickable
                            />
                          ))}
                          {selected.length > 1 && (
                            <Chip
                              label={`${selected.length - 1} +`}
                              sx={styles.chip}
                              clickable
                            />
                          )}
                        </Box>
                      );
                    }}
                  >

                    {county?.slice(0, visibleItems).map((item) => (
                      <MenuItem value={item.id} sx={styles.menuItemMulti} key={item.id}>
                        <Checkbox
                          checked={queryData?.location?.find((newItem) => newItem === item.id) || false}
                          sx={styles.checkbox}
                        />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className="controls-block">
                  <p><b>Area</b></p>
                  <Select
                    value={queryData.area}
                    onChange={handleAreaChange}
                    sx={styles.select}
                    inputProps={{ IconComponent: () => null }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                        onScroll: (event) => handleMenuScroll(event, area)
                      }
                    }}
                    onClose={() => setVisibleItems(ITEMS_PER_SCROLL)}
                    multiple
                    renderValue={(selected) => {
                      return selected.includes("all") ? (
                        "All"
                      ) : (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.slice(0, 1).map((value) => (
                            <Chip
                              key={area.find((item) => item.id === value)?.value}
                              label={area.find((item) => item.id === value)?.value}
                              sx={styles.chip}
                              clickable
                            />
                          ))}
                          {selected.length > 1 && (
                            <Chip
                              label={`${selected.length - 1} +`}
                              sx={styles.chip}
                              clickable
                            />
                          )}
                        </Box>
                      );
                    }}
                  >

                    {area?.slice(0, visibleItems).map((item) => (
                      <MenuItem value={item.id} sx={styles.menuItemMulti} key={item.id}>
                        <Checkbox
                          checked={queryData?.area?.find((newItem) => newItem === item.id) || false}
                          sx={styles.checkbox}
                        />
                        <ListItemText primary={item.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </>}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <ReportsTable type={tableType} data={data} />
            )}
          </div>
        </div>
      </div>
    </main >
  );
};

export default AdminReports;
