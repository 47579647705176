import React, {useEffect, useState} from "react";
import {
    createLocation,
    createLocationArea,
    deleteLocation,
    deleteLocationArea,
    getLocationAreas,
    getLocations,
    updateLocation,
    updateLocationArea
} from "../../../services/requests";
import "./AdminLocations.css"
import { ReactComponent as IconDelete } from '../../../assets/icon/IconDelete.svg';
import { ReactComponent as IconEdit } from '../../../assets/icon/IconEdit.svg';
import ModalForm from "../../../components/ModalForm";
import ModalWindow from "../../../components/ModalWindow";
import LocationForm from "../../../components/LocationForm";
import LocationAreaForm from "../../../components/LocationAreaForm";

const AdminLocations = () => {
    const [inputs, setInput] = useState({country: null});
    const [createAreas, setCreateAreas] = useState([{name: null, area: null}]);
    const [locations, setLocations] = useState([]);
    const [locationAreas, setLocationAreas] = useState([]);
    const [filteredLocationsAreas, setFilteredLocationsAreas] = useState([]);
    const [updateLocationItem] = useState(null);
    
    const [editLocation, setEditLocation] = useState(null);
    const [addLocation, setAddLocation] = useState(null);
    const [deleteLocationModal, setDeleteLocationModal] = useState(false);
    const [locationData, setLocationData] = useState(null);

    const [editLocationArea, setEditLocationArea] = useState(null);
    const [addLocationArea, setAddLocationArea] = useState(null);
    const [deleteLocationAreaModal, setDeleteLocationAreaModal] = useState(false);
    const [locationAreaData, setLocationAreaData] = useState(null);

    const [updateArea] = useState(null);    
    
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageLocations, setCurrentPageLocations] = useState([]);

    const pageSize = 5;

    useEffect(() => {
        getLocations().then(resp => {
            setLocations(resp.data)
        })
        getLocationAreas().then(resp => {
            setLocationAreas(resp.data)
        })
    }, [])

    useEffect(() => {
        filterLocationsAreas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, locationAreas])

    useEffect(() => {
        let splicedArr = [...filteredLocationsAreas];
        setCurrentPageLocations([...splicedArr.splice(currentPage === 1 ? 0 : (currentPage - 1) * pageSize, currentPage * pageSize)]);
        showLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [filteredLocationsAreas, currentPage])

    const filterLocationsAreas = async (event = null, filterType = null) => {
        let matchingElements = locations ? locations.map(location => {
            return {...location, areas: []}
        }) : [];

        if (locations.length) {
            for (const [idx, location] of locations.entries()) {
                for (const area of locationAreas) {
                    if (area.location_id === location.id) {
                        matchingElements[idx].areas.push(area)
                    }
                }
            }
        }

        let splicedArr = [];

        if (event && filterType) {
            if (event.target.value === 'default') {
                setFilteredLocationsAreas(matchingElements);
                splicedArr = [...matchingElements];
            } else {
                switch (filterType) {
                    case 'area':
                        setFilteredLocationsAreas([...[...matchingElements].filter(item => item.areas.some(area => area.name === event.target.value))]);
                        splicedArr = [...[...matchingElements].filter(item => item.areas.some(area => area.name === event.target.value))];
                        break;
                    case 'location':
                        setFilteredLocationsAreas([...[...matchingElements].filter(item => item.name === event.target.value)]);
                        splicedArr = [...[...matchingElements].filter(item => item.name === event.target.value)];
                        break;
                    default:
                        break;
                }
            }
        } else {
            setFilteredLocationsAreas(matchingElements);
            splicedArr = [...matchingElements];
        }

        setCurrentPageLocations([...splicedArr.splice(currentPage === 1 ? 0 : (currentPage - 1) * pageSize, currentPage * pageSize)]);

        let locationTens = 0;

        filteredLocationsAreas.forEach((location, idx) => {
            if (idx % pageSize === 0) {
                ++locationTens
            }
        })

        setPageCount(locationTens);
    }

    const handleOnChangeAreas = (event, idx) => {
        const value = event.target.value;
        let areasArr = createAreas;
        areasArr[idx] = {name: value};
        setCreateAreas([...areasArr]);
    }

    const inputChange = (event) => {
        setInput({...inputs, [event.target.name]: event.target.value});
    }

    const showLocations = () => {
        return currentPageLocations.map(location => {
            return (
                <React.Fragment key={location.id}>
                    <tr>
                        <td colSpan="2">{location.name}</td>
                        <td className="tbl_last_item">
            <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setLocationData(location);
                    setEditLocation(true);
                }}
              >
                <IconEdit />
              </button>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setLocationData(location);
                    setDeleteLocationModal(true);
                }}
              >
                <IconDelete />
              </button>

                            <div className="areaBtnWrap">
                                <button type="button" className="btnAddArea" onClick={() => {
                                            setLocationData(location)
                                            setAddLocationArea(true)
                                }}>
                                    Add Area
                                </button>
                            </div>

                        </td>
                    </tr>

                    {
                        location.areas.map(area => {
                            return (
                                <tr key={area.id} style={{background:'#F7F0E8'}}>
                                    <td></td>
                                    <td>{area.name}</td>
                                    <td className="tbl_last_item">
                                        <div className="btn-group">

            <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setLocationAreaData(area);
                    setEditLocationArea(true);
                }}
              >
                <IconEdit />
              </button>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setLocationAreaData(area);
                    setDeleteLocationAreaModal(true);
                }}
              >
                <IconDelete />
              </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </React.Fragment>
            )
        })
    }

    return (
        <>
<main id="main-section">
        <div className="container-fluid">
            <div className="row header-section">
                <div className="col-sm-8">
                    <h2 className="titleHeading2">Locations</h2>
                </div>
                <div className="col-sm-4 add_freelancer_wrap">
                    <button type="button" className="btn btn-primary" 
                                    onClick={() => {
                                        setAddLocation(true)
                                    }}                                        
                                        >Add Location
                    </button>
                </div>
            </div>

                    <div className="locations_info">
                        <div className="table-responsive">
                            <table className="table ">
                                <thead>
                                <tr className="table-warning">
                                    <th scope="col" className="thb_col">Location</th>
                                    <th scope="col" className="thb_col">Area</th>
                                    <th scope="col" className="thb_col tbl_last_item">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    showLocations()
                                }
                                </tbody>

                                {
                                    locations.length > pageSize ?
                                        <tfoot>
                                        <tr>
                                            <td colSpan="4">
                                                <div className="tbl_pagi">
                                                    
                                                    <div className="tbl_pagi_prev">
                                                    {currentPage > 1 ?
                                                        <button onClick={() => setCurrentPage(currentPage - 1)}
                                                                type="button" disabled={currentPage === 1}>Previous
                                                        </button>
                                                        : ''}
                                                    </div>
                                                    
                                                    <div className="tbl_pagi_counter">
                                                        <ul>
                                                            {
                                                                [...Array.from({length: pageCount}, (_, i) => i + 1)].map(page => {
                                                                    return (
                                                                        <li key={page}
                                                                            className={page === currentPage ? 'active' : ''}
                                                                            onClick={() => setCurrentPage(page)}>                                                                                
                                                                            <a href="/#">{page}</a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="tbl_pagi_next">
                                                        <button onClick={() => setCurrentPage(currentPage + 1)}
                                                                type="button" disabled={currentPage === pageCount}>Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tfoot> : <></>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </main>

            <div className="modal fade smModal" id="addCountry" tabIndex="-1" aria-labelledby="addCountry"
                 aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Location</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="inputCountry">Location</label>
                                    <input onChange={inputChange} name="country" type="text" className="form-control"
                                           id="inputCountry" placeholder="Limeric"/>
                                </div>
                                {
                                    createAreas.map((area, idx) => {
                                        return (
                                            <div className="form-group" key={idx}>
                                                <label htmlFor={'inputArea' + idx}>Area</label>
                                                <div className="d-flex align-items-center w-100">
                                                    <input onChange={(event) => handleOnChangeAreas(event, idx)}
                                                           type="text" className="form-control"
                                                           id={'inputArea' + idx} placeholder="Garryowen"/>
                                                    {
                                                        idx ? <span
                                                            onClick={() => {
                                                                let deletingArr = [...createAreas];
                                                                deletingArr.splice(idx, 1);
                                                                setCreateAreas(deletingArr)
                                                            }}
                                                            className="ml-2 delete-area-input"
                                                            aria-hidden="true">&times;</span> : <></>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <button onClick={() => setCreateAreas([...createAreas, {name: null}])} type="button"
                                        className="btn btnAddArea">Add Area
                                </button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" onClick={addLocation}
                                    className="btn btn-primary">Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade smModal" id="addAreaModal" tabIndex="-1" aria-labelledby="addAreaModal"
                 aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Area</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <p><strong>{updateLocationItem?.name}</strong></p>
                                {
                                    createAreas.map((area, idx) => {
                                        return (
                                            <div className="form-group" key={idx}>
                                                <label htmlFor={'inputArea' + idx}>Area</label>
                                                <div className="d-flex align-items-center w-100">
                                                    <input onChange={(event) => handleOnChangeAreas(event, idx)}
                                                           type="text" className="form-control"
                                                           id={'inputArea' + idx} placeholder="Garryowen"/>
                                                    {
                                                        idx ? <span
                                                            onClick={() => {
                                                                let deletingArr = [...createAreas];
                                                                deletingArr.splice(idx, 1);
                                                                setCreateAreas(deletingArr)
                                                            }}
                                                            className="ml-2 delete-area-input"
                                                            aria-hidden="true">&times;</span> : <></>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <button onClick={() => setCreateAreas([...createAreas, {name: null}])} type="button"
                                        className="btn btnAddArea">Add Area
                                </button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" onClick={addLocationArea}
                                    className="btn btn-primary">Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ModalForm show={editLocation} title={'Edit Location'}
                                       body={<LocationForm saveText={'Save'}
                                                           id={locationData?.id} hideAction={
                                           async (data) => {
                                                await updateLocation(locationData?.id, data);
                                                await getLocations().then(res => {
                                                    setLocations(res.data)
                                                })
                                                getLocationAreas().then(res => {
                                                    setLocationAreas(res.data)
                                                })
                                                setEditLocation(false);
                                           }
                                       }/>}
                                       handleClose={() => {
                                        setEditLocation(false)
                                       }}
                            />

            <ModalForm show={addLocation} title={'Add Location'}
                               body={
                                   <LocationForm 
                                        hideAction={
                                       async (data) => {
                                            await createLocation(data);
                                            await getLocations().then(res => {
                                                setLocations(res.data)
                                            })
                                            getLocationAreas().then(res => {
                                                setLocationAreas(res.data)
                                            })
                                           setAddLocation(false);
                                       }
                                   }/>
                               }
                               saveText={'Add'}
                               handleClose={() => {
                                   setAddLocation(false)
                               }}
                    />

                <ModalWindow show={deleteLocationModal}
                                         title={`Delete Location`}
                                         body={'Are you sure you want to delete \'' + locationData?.name + '\'?'}
                                         hideAction={async () => {
                                            await deleteLocation(locationData?.id);
                                            await getLocations().then(res => {
                                                setLocations(res.data)
                                            })
                                            getLocationAreas().then(res => {
                                                setLocationAreas(res.data)
                                            })
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteLocationModal(false)
                                         }}
                            />


            <ModalForm show={editLocationArea} title={'Edit Area'}
                                       body={<LocationAreaForm saveText={'Save'}
                                                           id={locationAreaData?.id} hideAction={
                                           async (data) => {
                                                await updateLocationArea(locationAreaData?.id, data);
                                                await getLocations().then(res => {
                                                    setLocations(res.data)
                                                })
                                                getLocationAreas().then(res => {
                                                    setLocationAreas(res.data)
                                                })
                                                setEditLocationArea(false);
                                           }
                                       }/>}
                                       handleClose={() => {
                                        setEditLocationArea(false)
                                       }}
                            />

            <ModalForm show={addLocationArea} title={'Add Area'}
                               body={
                                   <LocationAreaForm
                                        locationId={locationData?.id} 
                                        hideAction={
                                       async (data) => {
                                            await createLocationArea(data);
                                            await getLocations().then(res => {
                                                setLocations(res.data)
                                            })
                                            getLocationAreas().then(res => {
                                                setLocationAreas(res.data)
                                            })
                                           setAddLocationArea(false);
                                       }
                                   }/>
                               }
                               saveText={'Add'}
                               handleClose={() => {
                                   setAddLocationArea(false)
                               }}
                    />

                <ModalWindow show={deleteLocationAreaModal}
                                         title={`Delete Area`}
                                         body={'Are you sure you want to delete \'' + locationAreaData?.name + '\'?'}
                                         hideAction={async () => {
                                            await deleteLocationArea(locationAreaData?.id);
                                            await getLocations().then(res => {
                                                setLocations(res.data)
                                            })
                                            getLocationAreas().then(res => {
                                                setLocationAreas(res.data)
                                            })
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteLocationAreaModal(false)
                                         }}
                            />





            <div className="modal fade smModal" id="editArea" tabIndex="-1" aria-labelledby="editArea"
                 aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Area</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="inputArea">Area</label>
                                    <input onChange={inputChange} name="area" value={inputs.area} type="text"
                                           className="form-control"
                                           id="inputArea" placeholder="Garryowen"/>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal"
                                    onClick={(event) => editLocationArea(event, updateArea)}
                                    className="btn btn-primary">Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminLocations;
