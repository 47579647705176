import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import FreelancerProfile from './FreelancerProfile/FreelancerProfile';
import FreelancerReports from './FreelancerReports/FreelancerReports';
import FreelancerCalendar from './FreelancerCalendar/FreelancerCalendar';
import FreelancerBlog from './FreelancerBlog/FreelancerBlog';
import FreelancerTreatmentCategories from './FreelancerTreatmentCategories/FreelancerTreatmentCategories';

const FreelancerMain = () => {
  return (
    <div className='wrapper'>
      <Sidebar role='freelancer' />
      <Routes>
        <Route path={'/settings'} element={<FreelancerProfile />} />
        <Route path={'/calendar'} element={<FreelancerCalendar />} />
        <Route path={'/reports'} element={<FreelancerReports />} />
        <Route path={'/treatments'} element={<FreelancerTreatmentCategories />} />
        <Route path={'/news'} element={<FreelancerBlog />} />
        <Route path={'/settings/:categories'} element={<FreelancerProfile />} />
      </Routes>
    </div>
  );
};

export default FreelancerMain;
