import React from "react";
import "./Privacy.css";
import "../../App.css";
import WebsiteHeader from "../../components/Website/Header";
import WebsiteFooter from "../../components/Website/Footer";

const Privacy = () => {

  return (
    <>
      <WebsiteHeader />
      <section id="" className="vh-100" style={{marginBottom:450}}>
        <div className="line line-1"></div>
        <div className="line line-2"></div>
        <div className="line line-3"></div>
        <div className="line line-4"></div>
        <div className="custom-container">
          <div className="row h-100">
            <div className="col-lg-5 col-12 offset-lg-1 d-flex align-items-center main-login-form"
                style={{marginTop: 10}}>
              <div className="login-form d-flex flex-column m-lg-0 m-auto">
                <span className="login-title" style={{marginBottom: 20}}>Privacy policy.</span>
                
                <div className="">

    <p>Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our app, Tangl. By using Tangl, you agree to the terms outlined in this policy.</p>

    <h2>Information We Collect</h2>
    <p>We collect the following types of information:</p>

    <h3>Personal Information</h3>
    <ul>
        <li><strong>Professionals:</strong> Name, contact details, professional credentials, and payment information.</li>
        <li><strong>Customers:</strong> Name, contact details, appointment history, and payment information.</li>
    </ul>

    <h3>Usage Data</h3>
    <p>Information about how you use the app, including your interactions, preferences, and app settings.</p>

    <h2>How We Use Your Information</h2>
    <p>We use your information to:</p>
    <ul>
        <li>Provide and improve our services.</li>
        <li>Process transactions and manage accounts.</li>
        <li>Communicate with you regarding updates, promotions, and support.</li>
        <li>Personalize user experience and provide recommendations.</li>
        <li>Ensure compliance with legal obligations.</li>
    </ul>

    <h2>Sharing Your Information</h2>
    <p>We do not sell your personal information. We may share your information with:</p>
    <ul>
        <li><strong>Service Providers:</strong> Third-party vendors who assist in providing our services (e.g., payment processors, customer support).</li>
        <li><strong>Legal Authorities:</strong> When required by law or to protect the rights, property, or safety of Tangl, our users, or others.</li>
    </ul>

    <h2>Data Security</h2>
    <p>We implement security measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>

    <h2>Your Rights</h2>
    <p>You have the right to:</p>
    <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt-out of receiving marketing communications.</li>
        <li>Request data portability.</li>
        <li>Object to the processing of your personal information.</li>
    </ul>

    <h2>Changes to This Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. Changes are effective as of the date indicated.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WebsiteFooter />
    </>
  );
};

export default Privacy;
