import React, { useState, useEffect } from 'react';
import { CustomInput } from '../../../../components/CustomInput';
import DragAndDrop from '../DragAndDrop';
import ProfileImg from '../../../../assets/img/Profile.png';
import { CustomTextarea } from '../../../../components/CustomTextarea';
import Loading from '../../../../components/Loading';
import { ReactComponent as FileUploadWhite } from '../../../../assets/icon/FileUploadWhite.svg';
import {
  getFreelancerSettings,
  updateFreelancerSettings,
} from '../../../../services/requests';

import '../FreelancerProfile.css'
const General = () => {

  const API_URL = process.env.REACT_APP_IMAGE;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null)
  const [messageError, setMessageError] = useState(null)
  const [file, setFile] = useState(null);

  //File
  const handleFileChange = (event) => {
    setFile(event?.target?.files[0]);
  };

  const handleSave = async () => {
    try {
      const body = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.user?.email,
        city: data?.city,
        description: data?.description,
        photo: selectedImage,
        facebook: data?.facebook === null ? '': data?.facebook,
        instagram: data?.instagram === null ? '': data?.instagram,
        tiktok: data?.tiktok === null ? '': data?.tiktok,
        status: 1,
      };  

      const resp = await updateFreelancerSettings({ ...body, _method: 'PUT' });
      if (resp?.result === 'success') {
        setMessageError(null)
        setMessageSuccess("Your profile settings have now been saved.")
      }
      else {
        setMessageSuccess(null)
        setMessageError('Error saving profile settings. ', resp.result)
      }
    } catch (error) {
      console.error('Error saving profile settings. ', error);
    }
  };

  const loadData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const resp = await getFreelancerSettings();
      setData(resp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
   return <Loading />;
  }

  return (
    <div>
       {messageSuccess ? (
        <div className={'alert alert-success'}>{messageSuccess}</div>
        ) : null}                

      {messageError ? (
        <div className={'alert alert-danger'}>{messageError}</div>
        ) : null}                

      <div className='user-email mt-4'>
        <div className='profile-title align-items-center'>
          <div className='title'>Photo</div>
          <span className='subtitle'></span>
        </div>
        <div className='profile-image'>

        <input
            type='file'
            className='custom-file-input'
            id='myProfilePhoto'
            name='file'
            onChange={handleFileChange}
            accept='image/*'
          />

        {(file ? (
              uploadFile(file)
            ) : data?.photo ? (
              <>
                <img
                  alt='profile-img'
                  className='profile-img'
                  src={selectedImage ? URL?.createObjectURL(selectedImage) : `${API_URL}freelancers/${data?.photo}`}
                />
                <label htmlFor='myProfilePhoto' className='image-label'>
                  <FileUploadWhite />
                </label>
              </>
            ) : (
              <>
                {' '}
                <img src={ProfileImg} className='upload_file' alt='profile_img' />
                <label htmlFor='myProfilePhoto' className='image-label'>
                  <FileUploadWhite />
                </label>
              </>
            ))}

        </div>
        <div className='email'>
          <DragAndDrop selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
        </div>
      </div>
      <div className='user-name align-items-center '>
        <div className='title'>Name</div>
        <div className='fname'>
          <CustomInput
            className='custom-input form-control'
            placeholder=''
            type='text'
            value={data?.first_name}
            onChange={(e) => setData({ ...data, first_name: e?.target?.value })}
          />
        </div>
        <div className='lname'>
          <CustomInput
            className='custom-input form-control'
            type='text'
            placeholder=''
            value={data?.last_name}
            onChange={(e) => setData({ ...data, last_name: e?.target?.value })}
          />
        </div>
      </div>
      <div className='user-email d-block '>
        <div className='d-flex align-items-center'>
          <p className='title'>Facebook URL</p>
          <div className='email'>
            <CustomInput
              className='custom-input form-control'
              type='text'
              placeholder='e.g. Facebook.com/YourUsername'
              value={data?.facebook}
              onChange={(e) => setData({ ...data, facebook: e?.target?.value })}
            />
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <p className='title'>Instagram URL</p>
          <div className='email'>
            <CustomInput
              className='custom-input form-control'
              placeholder='e.g. Instagram.com/YourUsername'
              type='text'
              value={data?.instagram}
              onChange={(e) => setData({ ...data, instagram: e?.target?.value })}
            />
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <p className='title'>Tik Tok URL</p>
          <div className='email'>
            <CustomInput
              className='custom-input form-control'
              placeholder='e.g. Tiktok.com/YourUsername'
              type='text'
              value={data?.tiktok}
              onChange={(e) => setData({ ...data, tiktok: e?.target?.value })}
            />
          </div>
        </div>
        <div className='user-email mt-3' id='editor-profile'>
          <div className='profile-title align-items-center'>
            <div className='title'>Description</div>
            <span className='subtitle'></span>
          </div>
          <div className='email overflow-x-hidden' style={{marginTop: '-10px'}}>
          <CustomTextarea
          name='description'
          type='text'
          label=''
          placeholder=''
          value={data?.description}
          onChange={(event) => setData({ ...data, description: event?.target?.value })}
        />

            {/*<CustomEditor toolbarConfig={CustomEditorListSettings} data={editorData} onChange={handleChange} />*/}
           {/* <span className='settings-title'> {remainingCharacters } characters left</span> */}
          </div>
        </div>
        <div className='user-email mt-4'>
          <div className='profile-title'>
            <div className='title'></div>
          </div>
          <button className='btn btn-dark' onClick={handleSave} style={{ maxWidth: '170px', width: '100%' }}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
function uploadFile(file) {
  return (
    <>
      <img src={URL.createObjectURL(file)} className='upload_file' alt='profile_img' />
      <label htmlFor='myProfilePhoto' className='image-label'>
        <FileUploadWhite />
      </label>
    </>
  );
}

export default General;
