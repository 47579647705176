// import "./styles.css";
import React from 'react';
import { ReactComponent as DrapdropIcon } from '../../../assets/icon/DragDropIcon.svg';
export default function DragAndDrop({setSelectedImage,selectedImage}) {
  const isFileValid = (file) => {
    return file.type === 'image/jpeg' || file.type === 'image/png';
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
      if (isFileValid(file)) {
      setSelectedImage(file);
    } else {
      // Handle invalid file type here
      console.error('Invalid file type. Please upload only JPEG or PNG images.');
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  return (
    <div id='dragAndDrop'>
      <div className='image-uploader' onDrop={handleDrop} onDragOver={handleDragOver}>
          <label htmlFor='file-upload' className='upload-area'>
            <input
              id='file-upload'
              type='file'
              accept='image/jpeg, image/png' 
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
            />

            <div style={{ textAlign: 'center', marginBottom: '24px' }}>
              <DrapdropIcon />
            </div>
            <div>
              <span className='uploader-click'>Click to upload</span>
              <span className='uploader-drag'>or drag and drop</span>
            </div>
            <div className='uploader-text'> PNG or JPG (max. 800x400px)</div>
          </label>
      </div>
    </div>
  );
}
