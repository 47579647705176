import React from 'react';
import Modal from 'react-bootstrap/Modal';

const FreelancerModalWindow = ({show, handleClose, title, body, children,centered ,className}) => {
    return (
            <Modal show={show} onHide={handleClose} centered={centered} className={className}>
                <Modal.Header>
                    {title ? <Modal.Title>{title}</Modal.Title> : null}
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                   {children}
                </Modal.Footer>
            </Modal>
    );
}
export default FreelancerModalWindow;
