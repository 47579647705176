import React, { useEffect, useRef, useState } from 'react';
import '../components/style.css';
import {
  createFreelancer,
  getFreelancer,
  getLocationAreas,
  getTreatmentCategories,
  updateFreelancer,
} from '../services/requests';
import Loading from './Loading';
import { CustomInput } from './CustomInput';
import { ReactComponent as ChevronDown } from '../assets/icon/ChevronDown.svg';
import { ReactComponent as ChevronUp } from '../assets/icon/ChevronUp.svg';
import { ReactComponent as Cancel } from '../assets/icon/Cancel.svg';
import { ReactComponent as Close } from '../assets/icon/Close.svg';
import { ReactComponent as FileUploadWhite } from '../assets/icon/FileUploadWhite.svg';
import ProfileImg from '../assets/img/Profile.png';
import { CustomTextarea } from './CustomTextarea';
import { Toast } from 'react-bootstrap';

const FreelancerForm = ({ id, setAddFreelancer, setEditFreelancer, loadDataList }) => {
  const API_URL = process.env.REACT_APP_IMAGE;
  const [data, setData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [treatmentData, setTreatmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [isMenuOpenArea, setIsMenuOpenArea] = useState(false);
  const [treatmentMenuOpen, setTreatmentMenuOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);
  const [result, setResult] = useState('');

  //Areas
  const handleOptionChangeArea = (option, isChecked) => {
    if (isChecked) {
      if (data?.areas) {
        setData({ ...data, areas: [...data?.areas, option] });
      } else {
        setData({ ...data, areas: [option] });
      }
    } else {
      const updatedOptions = data?.areas?.filter((o) => o.id !== option.id);
      setData({ ...data, areas: updatedOptions });
    }}
  //Areas

  const handleChipDeleteArea = (option) => {
    const updatedOptions = data?.areas?.filter((o) => o.id !== option.id);
    setData({ ...data, areas: updatedOptions });
    setTimeout(() => {
      setIsMenuOpenArea(false);
    }, 1);
  };
  //Treatment
  const handleOptionChange = (option, isChecked) => {
    if (isChecked) {
      if (data?.treatment_categories) {
        setData({
          ...data,
          treatment_categories: [...data?.treatment_categories, option],
        });
      } else {
        setData({ ...data, treatment_categories: [option] });
      }
    } else {
      const updatedOptions = data?.treatment_categories?.filter((o) => o?.id !== option?.id);
      setData({ ...data, treatment_categories: updatedOptions });
    }
  };

  const handleChipDelete = (option) => {
    const updatedOptions = data?.treatment_categories?.filter((o) => o?.id !== option?.id);
    setData({ ...data, treatment_categories: updatedOptions });
    setTimeout(() => {
      setTreatmentMenuOpen(false);
    }, 1);
  };
  //File
  const handleFileChange = (event) => {
    setFile(event?.target?.files[0]);
  };
  //Document
  const fileArray = documents?.map((document) => {
    const fileContent = [document];
    const file = new File(fileContent, document?.name);
    return file;
  });

  const handleDocumentsFileChange = (event) => {
    const selectedFiles = event?.target?.files;
    const updatedFiles = [...documents];
    for (let i = 0; i < selectedFiles?.length; i++) {
      const file = selectedFiles[i];
      const isDuplicate = updatedFiles?.some((doc) => doc?.name === file?.name);
      if (!isDuplicate) {
        updatedFiles?.push(file);
      }
    }
    setDocuments(updatedFiles);
  };

  const handleDocumentsDelete = (index) => {
    const updatedFiles = documents?.filter((_, i) => i !== index);
    setDocuments(updatedFiles);
  };

  //Document Objects
  const setReqData = () => {
    const areasObj = data?.areas?.reduce((obj, item, index) => {
      return {
        ...obj,
        [`areas[${index}]`]: item?.id,
      };
    }, {});
    const treatmentObj = data?.treatment_categories?.reduce((obj, item, index) => {
      return {
        ...obj,
        [`freelancer_treatment_categories[${index}]`]: item?.id,
      };
    }, {});

    const docFileReq = fileArray?.map((item, index) => ({
      [`documents[${index}][file]`]: item,
      [`documents[${index}][name]`]: item?.name?.split('.')[0],
    }));
    const docObj = Object?.assign({}, ...docFileReq);
    return { ...areasObj, ...treatmentObj, ...docObj };
  };

  //API call
  const handleSubmit = async (e) => {
    e.preventDefault();
    let ObjData = setReqData();
    const body = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.user?.email,
      
      address1: data?.address1,
      address2: data?.address2,
      city: data?.city,
      county: data?.county,
      postcode: data?.postcode,

      description: data?.description,
      photo: file,
      facebook: data?.facebook === null ? '': data?.facebook,
      instagram: data?.instagram === null ? '': data?.instagram,
      tiktok: data?.tiktok === null ? '': data?.tiktok,
      status: 1,
      ...ObjData,
    };
    if (id) {
      try {
        const resp = await updateFreelancer(id, { ...body, _method: 'PUT' });
        if (resp?.result === 'success') {
          setEditFreelancer(false);
          loadDataList();
        }
      } catch (error) {
        console.error('Error updating freelancer:', error);
      }
    } else {
      const res = await createFreelancer(body);
      if (res?.result === 'success') {
        setAddFreelancer(false);
        loadDataList();
      }
      setResult(res);
    }
  };

  const loadData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const resp = await getFreelancer(id);
      setData(resp);
      setDocuments(resp?.documents);
      setIsLoading(false);
    }
  };

  const APIData = async () => {
    const locationData = await getLocationAreas();
    setLocationData(locationData?.data);
    const treatmentData = await getTreatmentCategories();
    setTreatmentData(treatmentData?.data);
  };

  useEffect(() => {
    APIData();
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }
  const isSubmitDisabled =
    !data?.first_name ||
    !data?.last_name ||
    !data?.user?.email ||
    !data?.city ||
    !data?.description ||
    data?.treatment_categories?.length === 0 ||
    data?.areas?.length === 0;
  return (
    <form onSubmit={handleSubmit} className='freelancer-form'>
      <Toast show={result?.result === 'error'}>
        <Toast.Body>
          {result.messages &&
          result?.messages?.length > 0 &&
          result?.messages[0]?.email &&
          result?.messages[0]?.email.length > 0
            ? result?.messages[0].email[0]
            : ''}
        </Toast.Body>
      </Toast>

      <div className='profile_basic'>
        <div className='profile_photo'>
          <input
            type='file'
            className='custom-file-input'
            id='myProfilePhoto'
            name='file'
            onChange={handleFileChange}
            accept='image/*'
          />
          {id &&
            (file ? (
              uploadFile(file)
            ) : data?.photo ? (
              <>
                <img
                  src={`${API_URL}freelancers/${data?.photo}`}
                  className='upload_file'
                  alt='profile_img'
                />
                <label htmlFor='myProfilePhoto' className='image-label'>
                  <FileUploadWhite />
                </label>
              </>
            ) : (
              <>
                {' '}
                <img src={ProfileImg} className='upload_file' alt='profile_img' />
                <label htmlFor='myProfilePhoto' className='image-label'>
                  <FileUploadWhite />
                </label>
              </>
            ))}
          {!id &&
            (file ? uploadFile(file) : <label className='file_placeholder' htmlFor='myProfilePhoto'></label>)}
        </div>
        <div className='profile_name'>
          <div className='form-group'>
            <CustomInput
              value={data?.first_name}
              type='text'
              label='First Name'
              placeholder=''
              onChange={(event) => setData({ ...data, first_name: event?.target?.value })}
            />
          </div>
          <div className='form-group'>
            <CustomInput
              value={data?.last_name}
              name='lastName'
              type='text'
              label='Last Name'
              placeholder=''
              onChange={(event) => setData({ ...data, last_name: event?.target?.value })}
            />
          </div>
        </div>
      </div>
      <div className='form-group'>
        <CustomInput
          value={data?.user?.email}
          name='email'
          type='email'
          label='Email'
          placeholder=''
          onChange={(event) => setData({ ...data, user: { email: event?.target?.value } })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          className='custom-input form-control'
          value={data?.facebook}
          name='city'
          type='text'
          label='Facebook URL'
          placeholder='e.g. Facebook.com/YourUsername'
          onChange={(event) => setData({ ...data, facebook: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          className='custom-input form-control'
          value={data?.instagram}
          name='city'
          type='text'
          label='Instagram URL'
          placeholder='e.g. Instagram.com/YourUsername'
          onChange={(event) => setData({ ...data, instagram: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          className='custom-input form-control'
          value={data?.tiktok}
          name='city'
          type='text'
          label='Tik Tok URL'
          placeholder='e.g. Tiktok.com/YourUsername'
          onChange={(event) => setData({ ...data, tiktok: event?.target?.value })}
        />
      </div>


      <div className='form-group'>
        <CustomInput
          value={data?.address1}
          name='address1'
          type='text'
          label='Address 1'
          placeholder=''
          onChange={(event) => setData({ ...data, address1: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          value={data?.address2}
          name='address2'
          type='text'
          label='Address 2'
          placeholder=''
          onChange={(event) => setData({ ...data, address2: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          value={data?.city}
          name='city'
          type='text'
          label='City'
          placeholder=''
          onChange={(event) => setData({ ...data, city: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          value={data?.county}
          name='county'
          type='text'
          label='County'
          placeholder=''
          onChange={(event) => setData({ ...data, county: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <CustomInput
          value={data?.postcode}
          name='postcode'
          type='text'
          label='Postcode'
          placeholder=''
          onChange={(event) => setData({ ...data, postcode: event?.target?.value })}
        />
      </div>

      <div className='form-group'>
        <div>
          <label className='label-text'>Area</label>
          <div style={{ position: 'relative' }}>
            <div
              className='toggle'
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setTreatmentMenuOpen(false);
                setIsMenuOpenArea(!isMenuOpenArea);
              }}
            >
              {!data?.areas || data?.areas?.length === 0 ? (
                <div className='arrow'>
                  <span className='arrow-inner'>All</span>
                </div>
              ) : (
                ''
              )}
              <div className='main-chip'>
                {data?.areas?.map((option) => (
                  <div key={option?.id} className='chip'>
                    <div className='chip-text'> {option?.name}</div>
                    <div onClick={() => handleChipDeleteArea(option)}>
                      <Cancel />
                    </div>
                  </div>
                ))}
              </div>
              <span>{!isMenuOpenArea ? <ChevronDown /> : <ChevronUp />}</span>
            </div>
            {isMenuOpenArea && (
              <div className='main-toggle'>
                {locationData?.map((option) => (
                  <div key={option?.id} className='option-div checkbox'>
                    <input
                      id={`checkboxId${option?.id}`}
                      className='check checkbox-input d-none'
                      type='checkbox'
                      checked={data?.areas?.some((o) => o.id === option?.id) || false}
                      onChange={(e) => handleOptionChangeArea(option, e.target?.checked)}
                    />
                    <label className='chackbox-label' htmlFor={`checkboxId${option?.id}`}>
                      <span className='custom-checkbox'> </span>
                      <span className='checkbox-text'>
                        {' '}
                        {option?.location?.name}-{option?.name}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='form-group'>
        <div>
          <label className='label-text'>Treatment Category</label>
          <div style={{ position: 'relative' }}>
            <div
              className='toggle'
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setTreatmentMenuOpen(!treatmentMenuOpen);
                setIsMenuOpenArea(false);
              }}
            >
              {!data?.treatment_categories ||
              data?.treatment_categories?.length === 0 ? (
                <div className='arrow'>
                  <span className='arrow-inner'>Select from list..</span>
                </div>
              ) : null}
              <div className='main-chip'>
                {data?.treatment_categories?.map((option) => (
                  <div key={option?.id} className='chip'>
                    <div className='chip-text'> {option?.name}</div>
                    <div onClick={() => handleChipDelete(option)}>
                      <Cancel />
                    </div>
                  </div>
                ))}
              </div>
              <span>{!treatmentMenuOpen ? <ChevronDown /> : <ChevronUp />}</span>
            </div>
            {treatmentMenuOpen && (
              <div className='main-toggle'>
                {treatmentData?.map((option) => (
                  <div key={option?.id} className='option-div checkbox'>
                    <input
                      id={`checkboxId${option?.id}`}
                      className='check checkbox-input d-none'
                      type='checkbox'
                      checked={data?.treatment_categories?.some((o) => o?.id === option?.id) || false}
                      onChange={(e) => handleOptionChange(option, e?.target?.checked)}
                    />
                    <label className='chackbox-label' htmlFor={`checkboxId${option?.id}`}>
                      <span className='custom-checkbox'> </span>
                      <span className='checkbox-text'>{option?.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='form-group'>
        <CustomTextarea
          value={data?.description}
          name='description'
          type='text'
          label='Description'
          placeholder=''
          onChange={(event) => setData({ ...data, description: event?.target?.value })}
        />
      </div>
      <div className='form-group'>
        <label style={{fontWeight: '500'}} htmlFor='inputDocuments' className="input-label">Documents</label>
        {documents && (
          <ul className='listDocuments'>
            {documents?.map((file, index) => (
              <li key={index} className='listName'>
                <div>{file?.name?.split('.')[0]}</div>
                <div onClick={() => handleDocumentsDelete(index)} className='close-icon'>
                  <Close className='svg-icon' />
                </div>
              </li>
            ))}
          </ul>
        )}
        <input
          type='file'
          style={{ display: 'none' }}
          className='custom-file-input'
          ref={fileInputRef}
          id='myProfilePhotoDocuments'
          name='Documents'
          multiple
          onChange={handleDocumentsFileChange}
        />
        <label>
          <button
            type='button'
            className='btn addDocument'
            onClick={() => fileInputRef.current.click()}
            htmlFor='myProfilePhoto'
          >
            Add Document
          </button>
        </label>
      </div>
      <div>
        <button
          type='submit'
          className='btn btn-primary-save'
          disabled={isSubmitDisabled}
        >
          Save
        </button>
      </div>
    </form>
  );
};
export default FreelancerForm;
function uploadFile(file) {
  return (
    <>
      <img src={URL.createObjectURL(file)} className='upload_file' alt='profile_img' />
      <label htmlFor='myProfilePhoto' className='image-label'>
        <FileUploadWhite />
      </label>
    </>
  );
}
