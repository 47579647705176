import React, { useState, useEffect } from "react";
import DashboardChart from "./../../components/Charts/DashboardChart";
import { makeRequest } from "../../services/requests";
import Loading from "../../components/Loading";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Box, Typography, Stack, Popover } from "@mui/material";
import moment from "moment";

import dayjs from "dayjs";

const styles = {
  popOver: {
    "& .MuiPopover-paper": {
      borderRadius: "12px",
      border: "1px solid #F8F9FC",
      boxShadow:
        "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
      mt: "20px",
    },
  },
  datePickerWrapper: {
    "& .MuiPickersMonth-monthButton:hover": {
      padding: 0,
      background: "#322830 !important",
      margin: 0,
      width: "100%",
      height: "100%",
      borderRadius: "8px",
      color: "#fff",
    },
    ".MuiPickersYear-yearButton:hover ": {
      padding: 0,
      background: "#322830 !important",
      margin: 0,
      width: "100%",
      height: "100%",
      borderRadius: "8px",
      color: "#fff",
    },
    bgcolor: "#fff",
    boxShadow: "0px 1px 2px 0px #1018280F",

    p: "12px",
    "& h2": {
      textAlign: "center",
      color: "#322830",
      fontWeight: 500,
    },
  },
  datePicker: {
    "& .MuiPickersCalendarHeader-root": {
      display: "none",
    },

    "& .MuiPickersMonth-root": {
      border: "1px solid #EAECF5",
      borderRadius: "8px",
    },
    "& .MuiPickersYear-root": {
      border: "1px solid #EAECF5",
      borderRadius: "8px",
    },
    "& .Mui-selected": {
      padding: 0,
      background: "#322830 !important",
      margin: 0,
      width: "100%",
      height: "100%",
      borderRadius: "8px",
    },
  },
  btnWrapper: {
    textAlign: "end",
    mt: 2,
  },
};

class ExtendedAdapterDateFns extends AdapterDayjs {
  getYearRange = (start, end) => {
    const startDate = dayjs(start).startOf("year");
    const endDate = dayjs(end).endOf("year");
    const years = [];

    let currentYear = endDate;
    while (dayjs(startDate).isBefore(currentYear)) {
      years.push(currentYear);
      currentYear = dayjs(currentYear).subtract(1, "year");
    }
    return years;
  };
}

const AdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});

  const [queryData, setQueryData] = useState({
    year: moment().year(),
    month: moment().month() + 1,
    treatment_category_id: "",
  });
  const [currentView, setCurrentView] = useState("year");
  const [dashboardReportData, setDashboardReportData] = useState({});
  const [currentDate, setCurrentDate] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const [chartSettings, setChartSettings] = useState({
    totalCustomer: "Month",
    totalFreelancer: "Month",
    totalRevenue: "Month",
    totalCancellation: "Month",
  });

  const chartSwitcher = (data, chartType) => {
    return chartType === "Year" ? data?.year : data?.month;
  };

  const fetchdashBoardReport = async () => {
    setLoading(true);
    const resp = await makeRequest(
      "admin/dashboard-report?year=" +
        queryData.year +
        "&month=" +
        queryData.month +
        "&treatment_category_id=" +
        queryData.treatment_category_id,
      null,
      "GET",
      true
    );
    setDashboardReportData(resp);
    setLoading(false);
    setChartSettings({
      totalCustomer: "Month",
      totalFreelancer: "Month",
      totalRevenue: "Month",
      totalCancellation: "Month",
    });
  };

  const fetchCategories = async () => {
    // setLoading(true)
    const resp = await makeRequest(
      "admin/treatment-category",
      null,
      "GET",
      true
    );

    if (resp.result === "success") {
      setCategory(resp?.data?.[0]);
      setCategories(resp.data);
      setQueryData((preValue) => ({
        ...preValue,
        treatment_category_id: resp?.data?.[0]?.id,
      }));
    }
    // setLoading(false)
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (Object?.keys(queryData)?.length > 0) {
      fetchdashBoardReport();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const handleCustomerTotalSettings = (event) => {
    setChartSettings({ ...chartSettings, totalCustomer: event.target.value });
  };
  const handleTotalFreelancersSettings = (event) => {
    setChartSettings({ ...chartSettings, totalFreelancer: event.target.value });
  };
  const handleTotalRevenueSettings = (event) => {
    setChartSettings({ ...chartSettings, totalRevenue: event.target.value });
  };
  const handleTotalCancellationsSettings = (event) => {
    setChartSettings({
      ...chartSettings,
      totalCancellation: event.target.value,
    });
  };

  const handleMonthChange = (newDate) => {
    const selectedMonth = newDate.month() + 1;
    setQueryData({
      ...queryData,
      year: currentDate.year,
      month: selectedMonth,
    });
    setCurrentView("year");
    handleClosePopover();
  };

  const handleYearChange = (newDate) => {
    const selectedYear = newDate.year();

    setCurrentDate({ year: selectedYear });
  };

  const handleDecreaseMonth = () => {
    setQueryData((preValue) => {
      const newMonth = preValue.month === 1 ? 12 : preValue.month - 1;
      const newYear = preValue.month === 1 ? preValue.year - 1 : preValue.year;
      return { ...preValue, year: newYear, month: newMonth };
    });
  };

  const handleIncreaseMonth = () => {
    setQueryData((preValue) => {
      const newMonth = preValue.month === 12 ? 1 : preValue.month + 1;
      const newYear = preValue.month === 12 ? preValue.year + 1 : preValue.year;
      return { ...preValue, year: newYear, month: newMonth };
    });
  };

  const isNextMonthInFuture = () => {
    const currentDate = moment(
      `${queryData.year}-${queryData.month}`,
      "YYYY-MM"
    );
    const nextMonth = currentDate.clone().add(1, "month");
    const currentMonth = moment();

    return nextMonth.isAfter(currentMonth, "month");
  };

  const handleCategorClick = (item) => {
    setCategory(item);
    setQueryData({ ...queryData, treatment_category_id: item.id });
  };
  const handeleViewChange = (newView) => {
    setCurrentView(newView);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <main id="main-section">
      <div className="container-fluid">
        <div className="row header-section">
          <div className="col-md-4">
            <h2 className="titleHeading2">Dashboard</h2>
            <br />
            {/* <p>
              <i>The administrator dashboard is still under development.</i>
            </p>
            <p>
              <i>The administrator dashboard is still under development.</i>
            </p> */}
          </div>
          <div className="col-md-8">
            <div className="date_category">
              <div className="date_holder">
                <button className="nextPrevBtn" onClick={handleDecreaseMonth}>
                  <span className="date_nav_prev">
                    <img
                      src={require("../../assets/img/chevron-left.png")}
                      alt=""
                    />
                  </span>
                </button>
                <Box
                  component="span"
                  className="date_value date_value_cat"
                  aria-describedby={id}
                  variant="contained"
                  onClick={handleClick}
                >
                  <span
                    className="date_value_month"
                    id="date_value_month_change"
                  >
                    {/* Feb */}
                    {moment()
                      .month(queryData?.month - 1)
                      .format("MMM")}
                  </span>
                  <span className="date_value_year" id="date_value_year_change">
                    {/* 2023 */}
                    {` ${queryData?.year}`}
                  </span>
                  <div className="date_value_months_holder">
                    <h4>Month </h4>
                    <ul className="date_value_ul">
                      {/*
                            <li><a href="#">Jan</a></li>
                            <li><a href="#">Feb</a></li>
                            <li><a href="#">Mar</a></li>
                            <li><a href="#">Apr</a></li>
                            <li><a href="#">May</a></li>
                            <li><a href="#">Jun</a></li>
                            <li><a href="#">Jul</a></li>
                            <li><a href="#">Aug</a></li>
                            <li><a href="#">Sep</a></li>
                            <li><a href="#">Oct</a></li>
                            <li><a href="#">Nov</a></li>
                            <li><a href="#">Dec  </a></li>*/}
                    </ul>
                  </div>

                  <div className="date_value_year_holder">
                    <h4>Year</h4>
                    <ul className="date_value_ul">
                      {/*
                            <li><a href="#">2023</a></li>
                            <li><a href="#">2022</a></li>
                            <li><a href="#">2021</a></li>
                            <li><a href="#">2020</a></li>
                            <li><a href="#">2019</a></li>
                            <li><a href="#">2018</a></li>
                            <li><a href="#">2017</a></li>
                            <li><a href="#">2016</a></li>
                            <li><a href="#">2015</a></li>
                            <li><a href="#">2014</a></li>
                            <li><a href="#">2013</a></li>*/}
                    </ul>
                  </div>
                </Box>
                <Popover
                  sx={styles.popOver}
                  id={id}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box sx={styles.datePickerWrapper}>
                    <Typography component="h2">
                      {currentView === "year" ? "Year" : "Month"}
                    </Typography>
                    <Stack direction="row" justifyContent="center">
                      <LocalizationProvider
                        dateAdapter={ExtendedAdapterDateFns}
                        maxDate={new Date()}
                      >
                        <DemoContainer components={["DateCalendar"]}>
                          <DateCalendar
                            sx={styles.datePicker}
                            referenceDate={dayjs(
                              `${queryData?.year}-${queryData?.month}`
                            )}
                            views={["year", "month"]}
                            view={currentView}
                            onViewChange={handeleViewChange}
                            showDaysOutsideCurrentMonth={false}
                            monthsPerRow={4}
                            yearsPerRow={4}
                            disableFuture
                            onMonthChange={handleMonthChange}
                            onYearChange={handleYearChange}
                            minDate={dayjs("2013-01-01")}
                            maxDate={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                </Popover>
                <button
                  className="nextPrevBtn"
                  onClick={handleIncreaseMonth}
                  disabled={isNextMonthInFuture()}
                >
                  <span className="date_nav_next">
                    <img
                      src={require("../../assets/img/chevron-right.png")}
                      alt=""
                    />
                  </span>
                </button>
              </div>

              <div className="category_holder">
                <div className="dropdown category_dropdown">
                  <a
                    className="btn btn-secondary dropdown-toggle"
                    href="/"
                    role="button"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <span id="show">Nail</span>
                    <span> statistic</span> */}
                    {/* Nail statistic */}
                    {category.name}
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dashboardReportMenu">
                    {categories.map((item, index) => {
                      return (
                        <div
                          className="dropdown-item"
                          key={index}
                          onClick={() => handleCategorClick(item)}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                    {/* 
                                        <div className="dropdown-item" href="#">Nail</div>
                                        <a className="dropdown-item" href="#">Hair</a>
                                        <a className="dropdown-item" href="#">Eye</a>
                                        <a className="dropdown-item" href="#">Make-up</a>
                                        <a className="dropdown-item" href="#">Osteopathy</a>
                                        <a className="dropdown-item" href="#">Massage</a>
                                        <a className="dropdown-item" href="#">Waxing</a>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="statistic_wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="statistic_item">
                <div className="chart_head">
                  <div className="chart_head_title">
                    <h3>Total Customers</h3>
                  </div>
                  <div className="chart_head_type">
                    <form>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={handleCustomerTotalSettings}
                        >
                          <option>Month</option>
                          <option>Year</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="chart_graps">
                  {/* <img
                    src={require("../../assets/img/chart_total_customer.png")}
                    alt=""
                  /> */}
                  {Object?.keys(dashboardReportData)?.length > 0 && (
                    <DashboardChart
                      type={chartSettings.totalCustomer}
                      data={chartSwitcher(
                        dashboardReportData?.totalCustomerData,
                        chartSettings.totalCustomer
                      )}
                      mainColor={"#717BBC"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="statistic_item">
                <div className="chart_head">
                  <div className="chart_head_title">
                    <h3>Total Freelancers</h3>
                  </div>
                  <div className="chart_head_type">
                    <form>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={handleTotalFreelancersSettings}
                        >
                          <option>Month</option>
                          <option>Year</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="chart_graps">
                  {/* <img
                    src={require("../../assets/img/chart_total_freelancer.png")}
                    alt=""
                  /> */}
                  {/* <DashboardChart
                    type={chartSettings.totalFreelancer}
                    data={chartSwitcher(
                        dashboardReportData?.totalFreelancerData,
                      chartSettings.totalFreelancer
                    )}
                    mainColor={"#7CD4FD"}
                  /> */}
                  {Object?.keys(dashboardReportData)?.length > 0 && (
                    <DashboardChart
                      type={chartSettings.totalFreelancer}
                      data={chartSwitcher(
                        dashboardReportData?.totalFreelancerData,
                        chartSettings.totalFreelancer
                      )}
                      mainColor={"#717BBC"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="statistic_item">
                <div className="chart_head">
                  <div className="chart_head_title">
                    <h3>Total Revenue</h3>
                  </div>
                  <div className="chart_head_type">
                    <form>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={handleTotalRevenueSettings}
                        >
                          <option>Month</option>
                          <option>Year</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="chart_graps">
                  {/* <img
                    src={require("../../assets/img/chart_total_revenue.png")}
                    alt=""
                  /> */}
                  {/* <DashboardChart
                    type={chartSettings.totalRevenue}
                    data={chartSwitcher(
                        totalCustomerData,
                      chartSettings.totalRevenue
                    )}
                    mainColor={"#FDB022"}
                  /> */}
                  {Object?.keys(dashboardReportData)?.length > 0 && (
                    <DashboardChart
                      type={chartSettings.totalRevenue}
                      data={chartSwitcher(
                        dashboardReportData?.totalRevenueData,
                        chartSettings.totalRevenue
                      )}
                      mainColor={"#717BBC"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="statistic_item">
                <div className="chart_head chart_head_cancellations">
                  <div className="chart_head_title">
                    <h3>Total Cancellations</h3>
                  </div>
                  <div className="chart_head_date">
                    {/* <div className="date_holder">
                      <span className="date_nav_prev">
                        <a href="/">
                          <img
                            src={require("../../assets/img/chevron-left.png")}
                            alt=""
                          />
                        </a>
                      </span>
                      <span className="date_value">
                        <span className="date_value_month">Feb</span>
                        <span className="date_value_year">2023</span>
                      </span>
                      <span className="date_nav_next">
                        <a href="/">
                          <img
                            src={require("../../assets/img/chevron-right.png")}
                            alt=""
                          />
                        </a>
                      </span>
                    </div> */}
                  </div>
                  <div className="chart_head_type">
                    <form>
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={handleTotalCancellationsSettings}
                        >
                          <option>Month</option>
                          <option>Year</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="chart_head_status">
                  <span className="chart_head_status_allOrder">All orders</span>
                  <span className="chart_head_status_cancellations">
                    Cancellations
                  </span>
                </div>
                <div className="chart_graps">
                  {/* <img
                    src={require("../../assets/img/chart_total_cancellation.png")}
                    alt=""
                  /> */}
                  {/* <DashboardChart
                    type={chartSettings.totalCancellation}
                    data={chartSwitcher(
                         totalCustomerData,
                      chartSettings.totalCancellation
                    )}
                    mainColor={"#FEC84B"}
                    secondaryColor={"#027A48"}
                  /> */}

                  {Object?.keys(dashboardReportData)?.length > 0 && (
                    <DashboardChart
                      type={chartSettings.totalCancellation}
                      data={chartSwitcher(
                        dashboardReportData?.totalCancellationData,
                        chartSettings.totalCancellation
                      )}
                      mainColor={"#FEC84B"}
                      secondaryColor={"#027A48"}
                      isCancellation={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdminDashboard;
