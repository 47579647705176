import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./Login.css";
import "../App.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_USER } from "../types";
import { makeRequest, updateCartUser } from "../services/requests";
import { getErrorMessage } from "../services/utils";
import WebsiteHeader from "../components/Website/Header";
import { useGoogleLogin } from "@react-oauth/google";
import { ReactComponent as GoogleLogo } from "../assets/icon/GoogleLogo.svg";
import { ReactComponent as FacebookLogo } from "../assets/icon/FacebookLogo.svg";
import { FreelancerForgotPassword } from "./Freelancer/FreelancerForgotPassword/FreelancerForgotPassword";
import { getUserId, setUserId } from "../services/token";
import axios from "axios";
import { LoginSocialFacebook } from "reactjs-social-login";

const Login = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [inputs, setInput] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showModal, setShowModel] = useState(false);
  const [googleLoginUrl ] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
  const REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  const FB_APPID = process.env.REACT_APP_FACEBOOK_APP_ID;
  const [searchParams] = useSearchParams();

  const inputChange = (e) => {
    setInput({ ...inputs, [e?.target?.name]: e?.target?.value });
  };

  const [user, setUser] = useState([]);

  useEffect(() => {
    if (user) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://oauth2.googleapis.com/token?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&code=${user?.code}&grant_type=authorization_code&redirect_uri=${REDIRECT_URI}`,
        headers: {},
      };
      console.log('lorem', REDIRECT_URI);

      axios
        .request(config)
        .then((response) => {
          const qs = require("qs");
          let data = qs.stringify({
            token: response.data.id_token,
            type: "google",
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_URL}auth/social/login`,
            headers: {
              accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: data,
          };

          axios
            .request(config)
            .then(async (response) => {
              redirectFun(response);
            })
            .catch((error) => {
              console.log("SOCIAL LOGIN ERROR", error);
            });
        })
        .catch((error) => {
          console.log("GOOGLE LOGIN ERROR", error);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const redirectFun = async (response) => {
    let resp = response?.data;
    if (resp?.token) {
      var userId = 0;
      var firstName = "";
      var lastName = "";

      if (resp?.permissions[0] === "freelancer") {
        userId = resp?.user?.freelancer?.id;
        firstName = resp?.user.freelancer.first_name;
        lastName = resp?.user.freelancer.last_name;
      } else if (resp?.permissions[0] === "customer") {
        userId = resp?.user?.customer?.id;
        firstName = resp?.user.customer.first_name;
        lastName = resp?.user.customer.last_name;
      }

      dispatch({
        type: LOGIN_USER,
        payload: {
          token: resp?.token,
          role: resp?.permissions[0],
          email: resp?.user?.email,
          first_name: firstName,
          last_name: lastName,
          status: resp?.user?.freelancer?.status,
          id: userId,
        },
      });
      if (resp?.permissions[0] === "freelancer") {
        if (resp?.user?.freelancer?.status === true) {
          navigation("/prouser/calendar");
        } else {
          setErrorMessage("The pro user cannot access their account.");
        }
      } else if (resp?.permissions[0] === "customer") {
        await updateCartUser(getUserId(), userId).then(() => {
          setUserId(userId);

          if (searchParams.get("bookingLogin")) {
            navigation("/book-appointment/cart?paynow=true");
          } else {
            navigation("/customer");
          }
        });
      }
    }
    if (resp?.result === "error") {
      setErrorMessage(getErrorMessage(resp));
    }
  };

  const customLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
    },
    onError: () => {
      console.log("Login Failed");
    },
    flow: "auth-code",
  });

  const login = async (event) => {
    window.infoMessage = "";
    event.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const resp = await makeRequest("auth/sign-in", inputs, "POST", false);
      if (resp?.token) {
        var userId = 0;
        var firstName = "";
        var lastName = "";

        if (resp?.permissions[0] === "freelancer") {
          userId = resp?.user?.freelancer?.id;
          firstName = resp?.user.freelancer.first_name;
          lastName = resp?.user.freelancer.last_name;
        } else if (resp?.permissions[0] === "customer") {
          userId = resp?.user?.customer?.id;
          firstName = resp?.user.customer.first_name;
          lastName = resp?.user.customer.last_name;
        }

        dispatch({
          type: LOGIN_USER,
          payload: {
            token: resp?.token,
            role: resp?.permissions[0],
            email: resp?.user?.email,
            first_name: firstName,
            last_name: lastName,
            status: resp?.user?.freelancer?.status,
            id: userId,
          },
        });
        if (resp?.permissions[0] === "freelancer") {
          if (resp?.user?.freelancer?.status === true) {
            navigation("/prouser/calendar");
          } else {
            setErrorMessage("The pro user cannot access their account.");
          }
        } else if (resp?.permissions[0] === "customer") {
          await updateCartUser(getUserId(), userId).then(() => {
            setUserId(userId);

            if (searchParams.get("bookingLogin")) {
              navigation("/book-appointment/cart?paynow=true");
            } else {
              navigation("/customer");
            }
          });
        }
      }
      if (resp?.result === "error") {
        setErrorMessage(getErrorMessage(resp));
      }
      setIsLoading(false);
    }
  };

  const [setProvider] = useState("");
  const [setProfile] = useState(null);

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, []);

  return (
    <>
      <WebsiteHeader />
      <section id="login-section" className="vh-100">
        <div className="line line-1"></div>
        <div className="line line-2"></div>
        <div className="line line-3"></div>
        <div className="line line-4"></div>
        <div className="custom-container">
          <div className="row h-100">
            <div className="col-lg-5 col-12 offset-lg-1 d-flex align-items-center main-login-form">
              <div className="login-form d-flex flex-column m-lg-0 m-auto">
                {searchParams.get("bookingLogin") ? (
                  <div className={"alert alert-success"}>
                    Please sign up or login in below before checking out.
                  </div>
                ) : (
                  ""
                )}

                {errorMessage ? (
                  <div
                    className={"alert alert-danger"}
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  />
                ) : null}
                {window.infoMessage ? (
                  <div className={"alert alert-success"}>
                    {window.infoMessage}
                  </div>
                ) : null}

                <span className="login-title">Sign in to your account.</span>
                <span className="login-subtitle">
                  Welcome back. Please enter your customer or professional user
                  details below.
                </span>
                <form onSubmit={login}>
                  <div className="form-group ">
                    <label htmlFor="inputEmail1">Email</label>
                    <input
                      onChange={inputChange}
                      name="email"
                      type="email"
                      className="form-control"
                      id="inputEmail1"
                      aria-describedby="emailHelp"
                      placeholder=""
                    />
                  </div>
                  <div className="form-group field_password">
                    <label htmlFor="inputPassword">Password</label>
                    <input
                      onChange={inputChange}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                    />
                    <button
                      disabled={isLoading}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      type="button"
                      className={`btn btnShowHide ${showPassword ? "show" : ""
                        }`}
                    >
                      Show/Hide
                    </button>
                  </div>
                  <div className="forgot-main-link">
                    <Link
                      onClick={() => setShowModel(true)}
                      className="forgot-link"
                    >
                      Forgotten Password?
                    </Link>
                  </div>
                  <div className="btn-main">
                    <button type="submit" className="btn login-btn">
                      Sign In
                    </button>
                  </div>
                  {/*
                  <div className='text'>or</div>
                    */}
                </form>

                {googleLoginUrl != null && (
                  <div className="btn-main">
                    <div
                      href={googleLoginUrl}
                      className="google-login"
                      onClick={() => customLogin()}
                    >
                      <GoogleLogo className="google-logo" />
                      Log in with Google
                    </div>
                  </div>
                )}

                <LoginSocialFacebook
                  isOnlyGetToken
                  appId={FB_APPID}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data);

                    const qs = require("qs");
                    let request = qs.stringify({
                      token: data?.accessToken,
                      type: "facebook",
                    });

                    let config = {
                      method: "post",
                      maxBodyLength: Infinity,
                      url: `${API_URL}auth/social/login`,
                      headers: {
                        accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-CSRF-TOKEN": "",
                      },
                      data: request,
                    };

                    axios
                      .request(config)
                      .then((response) => {
                        redirectFun(response);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                  onReject={(err) => {
                    console.log("FB EEE", err);
                  }}
                >
                  <div className="btn-main">
                    <button className="facebook-login" type="submit">
                      <FacebookLogo className="facebook-logo" />
                      Log in with Facebook
                    </button>
                  </div>
                </LoginSocialFacebook>

                <div className="account-text">
                  <span>Don’t have an account?</span>
                  {searchParams.get("bookingLogin") === "true" ? (
                    <Link
                      className="signup-link"
                      to={"/register?bookingLogin=true"}
                    >
                      Sign Up
                    </Link>
                  ) : (
                    <Link className="signup-link" to="/register">
                      Sign Up
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-5 offset-1 d-lg-block d-none">
              <div id="freelancer-login-bg"></div>
            </div>
          </div>
        </div>
        <FreelancerForgotPassword
          setShowModel={setShowModel}
          showModal={showModal}
        />
      </section>
    </>
  );
};

export default Login;
