
/*

// get stripe cus id for this customer
// call this method below
// list the payment methods
// add an option to link to stripe to add a new one

const stripe = require('stripe')('sk_test_4eC39HqLyjWDarjtT1zdp7dc');
const paymentMethods = await stripe.customers.listPaymentMethods(
  'cus_9s6XKzkNRiz8i3',
  {type: 'card'}
);
*/

const Payment = () => {


    return "payment"
}

export default Payment;