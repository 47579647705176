import Hair from '../assets/img/Hair.jpg';
import MakeUp from '../assets/img/make-up.jpg';
import Nail from '../assets/img/nail.jpg';
export const cards = [
  { id: 1, image: Hair, title: 'Hair Styling', price: '€50.00' },
  { id: 2, image: MakeUp, title: 'Make-up', price: '€100.00' },
  { id: 3, image: Nail, title: 'Nails', price: '€45.00' },
  { id: 4, image: Hair, title: 'Hair Styling', price: '€10.00' },
  { id: 5, image: MakeUp, title: 'Hair Styling', price: '€90.00' },
  { id: 6, image: Nail, title: 'Hair Styling', price: '€250.00' },
];
export const GroomData = [
  {
    id: 1,
    image: Hair,
    title: 'Powered by Connecting',
    description:
      'Find excellent professionals you might have never discovered on the high street Mix and match different treatments from our diverse roster of professionals, who will bring your beauty regimen straight to you.',
  },
  {
    id: 2,
    image: MakeUp,
    title: 'Better Than The Rest ',
    description: "From your first tap on the app to the glow up itself you'll be taken care of",
  },
  {
    id: 3,
    image: Nail,
    title: 'Made For You',
    description:
      'Mix and match different treatments from our diverse roster of professionals, who will bring your beauty regimen straight to you.',
  },
  {
    id: 4,
    image: Hair,
    title: 'Hair Styling',
    description:
      'Mix and match different treatments from our diverse roster of professionals, who will bring your beauty regimen straight to you.',
  },
  {
    id: 5,
    image: MakeUp,
    title: 'Hair Styling',
    description:
      'Mix and match different treatments from our diverse roster of professionals, who will bring your beauty regimen straight to you.',
  },
  {
    id: 6,
    image: Nail,
    title: 'Hair Styling',
    description:
      'Mix and match different treatments from our diverse roster of professionals, who will bring your beauty regimen straight to you.',
  },
];
export const discoverData = ['Discover', 'Hair', 'Nails', 'Make-up', 'Weddings'];
export const partnersData = ['Partners', 'Become a Pro', 'Customer Care'];
export const companyData = [
  'Company',
  'About Us',
  'Contact Us',
  'Work with Us',
  'Legal & GDPR',
  'Cookie Settings',
];

export const treatmentList = ['Basic Manicure','Relaxer treatment','Curly perms','Pedicure','Toning treatment','Gloss treatment','Keratin treatment','Dermaplaning','Leg treatment','Body Treatments']
export const country_list = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua &amp; Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia &amp; Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre &amp; Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts &amp; Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad &amp; Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks &amp; Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];
export const weekdays = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
];

export const dropdownValues = [
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
  '12 AM',
];

export const CustomEditorListSettings = [
  'heading',
  'bold',
  'italic',
  'link',
  'bulletedList',
  'numberedList',
  'undo',
  'redo',
];

export function convertTime(time) {
  if (!time) {
    return ''; // Return an empty string or any default value you prefer for null/undefined inputs
  }
  const [hour, period] = time?.split(' ');
  let hour24 = parseInt(hour, 10);
  if (period === 'PM' && hour24 !== 12) {
    hour24 += 12;
  }
  if (period === 'AM' && hour24 === 12) {
    hour24 = 0;
  }
  const formattedHour = hour24?.toString()?.padStart(2, '0');
  return `${formattedHour}:00`;
}
export function convertTime24to12(time) {
  const [hour] = time?.split(':');
  let hour12 = parseInt(hour, 10);
  let period = 'AM';
  if (hour12 >= 12) {
    period = 'PM';
    if (hour12 > 12) {
      hour12 -= 12;
    }
  }
  if (hour12 === 0) {
    hour12 = 12;
  }
  return `${hour12} ${period}`;
}