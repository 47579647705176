import axios from 'axios';
import { getToken, getUserId } from './token';

const API_URL = process.env.REACT_APP_API_URL;

export const makeRequest = async (url, data = null, method = 'GET', auth = true, asForm = false) => {
    let headers;
    const debug = false;

    if (asForm) {
        headers = {};
    } else {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }
    if (auth) {
        headers.Authorization = `Bearer ${getToken()}`;
    }
    
    console.log(headers.Authorization)

    const params = {
        method: method,
        headers,
    };
    if (method === 'POST' || method === 'PUT') {
        if (asForm) {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            params.data = formData;
        } else {
            params.data = JSON.stringify(data);
        }
    }

    params.url = API_URL + url;

    if (debug) {
        console.log('params.data: ');
        console.log(params.data);
    }

    try {
        const resp = await axios.request(params);
        return resp.data;
    } catch (err) {
        return err.response.data;
    }
};

export const logoutAdmin = async () => {
    return await makeRequest('admin/auth/logout', null, 'POST');
}
export const logout = async () => {
    return await makeRequest('auth/logout', null, 'POST');
}
// FREELANCER REQUESTS
export const getFreelancersList = async ({page, sort}) => {
    return await makeRequest(`admin/freelancers?page=${page ? page : 1}`, null, 'GET');
}
export const getFreelancer = async (id) => {
    return await makeRequest(`admin/freelancers/${id}`, null, 'GET');
}
export const createFreelancer = async (data) => {
    return await makeRequest(`admin/freelancers`, data, 'POST', true, true);
};
export const updateFreelancer = async (id, data) => {
    return await makeRequest(`admin/freelancers/${id}`, data, 'POST',true, true);
};

export const deleteFreelancer = async (id) => {
    return await makeRequest(`admin/freelancers/${id}`, null, 'DELETE');
}

// TREATMENT CATEGORY REQUESTS
export const getTreatmentCategories = async () => {
    return await makeRequest(`admin/treatment-category`, null, 'GET');
}
export const createTreatmentCategory = async (data) => {
    return await makeRequest(`admin/treatment-category`, data, 'POST', true, true);
}
export const getTreatmentCategory = async (id, full) => {
    return await makeRequest(`admin/treatment-category/${id}${full ? '?withGroups=true' : ''}`, null, 'GET');
}
export const deleteTreatmentCategory = async (id) => {
    return await makeRequest(`admin/treatment-category/${id}`, null, 'DELETE');
}
export const updateTreatmentCategory = async (id, data) => {
    return await makeRequest(`admin/treatment-category/${id}`, data, 'POST', true, true);
}

// TREATMENT GROUP REQUESTS
export const getTreatmentGroups = async () => {
    return await makeRequest(`admin/treatment-group`, null, 'GET');
}
export const updateTreatmentGroup = async (id, data) => {
    return await makeRequest(`admin/treatment-group/${id}`, data, 'PUT');
}
export const deleteTreatmentGroup = async (id) => {
    return await makeRequest(`admin/treatment-group/${id}`, null, 'DELETE');
}
export const createTreatmentGroup = async (data) => {
    return await makeRequest(`admin/treatment-group`, data, 'POST');
}
export const getTreatmentGroup = async (id) => {
    return await makeRequest(`admin/treatment-group/${id}`, null, 'GET');
};

// TREATMENT REQUESTS
export const getTreatments = async () => {
    return await makeRequest(`admin/treatment`, null, 'GET');
}
export const getTreatment = async (id) => {
    return await makeRequest(`admin/treatment/${id}`, null, 'GET');
};
export const createTreatment = async (data) => {
    return await makeRequest(`admin/treatment`, data, 'POST');
}
export const updateTreatment = async (id, data) => {
    return await makeRequest(`admin/treatment/${id}`, data, 'PUT');
}
export const deleteTreatment = async (id) => {
    return await makeRequest(`admin/treatment/${id}`, null, 'DELETE');
}

// TREATMENT ADD-ON REQUESTS
export const getTreatmentAddons = async () => {
    return await makeRequest(`admin/treatment-addon`, null, 'GET');
}
export const getTreatmentAddon = async (id) => {
    return await makeRequest(`admin/treatment-addon/${id}`, null, 'GET');
};
export const createTreatmentAddon = async (data) => {
    return await makeRequest(`admin/treatment-addon`, data, 'POST');
}
export const updateTreatmentAddon = async (id, data) => {
    return await makeRequest(`admin/treatment-addon/${id}`, data, 'PUT');
}
export const deleteTreatmentAddon = async (id) => {
    return await makeRequest(`admin/treatment-addon/${id}`, null, 'DELETE');
}

// LOCATION REQUESTS
export const getLocation = async (id) => {
    return await makeRequest(`admin/location/${id}`, 'GET');
}
export const getLocations = async () => {
    return await makeRequest(`admin/location`, 'GET');
}
export const createLocation = async (data) => {
    return await makeRequest(`admin/location`, data, 'POST');
}
export const updateLocation = async (id, data) => {
    return await makeRequest(`admin/location/${id}`, data, 'PUT');
}
export const deleteLocation = async (id) => {
    return await makeRequest(`admin/location/${id}`, null, 'DELETE');
}

// LOCATION AREA REQUESTS
export const getLocationArea = async (id) => {
    return await makeRequest(`admin/location-area/${id}`, 'GET');
}
export const getLocationAreas = async () => {
    return await makeRequest(`admin/location-area`, 'GET');
}
export const getLocationAreasAsFreelancer = async () => {
    return await makeRequest(`freelancer/location-area`, 'GET');
}
export const createLocationArea = async (data) => {
    return await makeRequest(`admin/location-area`, data, 'POST');
}
export const updateLocationArea = async (id, data) => {
    return await makeRequest(`admin/location-area/${id}`, data, 'PUT');
}
export const deleteLocationArea = async (id) => {
    return await makeRequest(`admin/location-area/${id}`, null, 'DELETE');
}
 //NEWS FREELANCER REQUESTS
 export const getFreelancerNews = async (page) => {
    return await makeRequest(`news?page=${page ? page : 1}`, 'GET');
}
 //ADMIN NEWS REQUESTS
 export const getAdminNew = async (id) => {
    return await makeRequest(`admin/news/${id}`, 'GET');
}
 export const getAdminNews = async (page) => {    
    return await makeRequest(`admin/news?page=${page ? page : 1}`, 'GET');
}
 export const createAdminNews = async (data) => {
    return await makeRequest(`admin/news`, data,'POST');
}
 export const updateAdminNews = async (id,data) => {
    return await makeRequest(`admin/news/${id}`, data,'PUT');
}
export const deleteAdminNews = async (id) => {
    return await makeRequest(`admin/news/${id}`, null, 'DELETE');
}
// FREELANCER TREATMENT REQUEST
export const getfreelancreTreatmentList = async () => {
    return await makeRequest(`treatment-category `, 'GET');
}
export const createFreelancerTreatment = async (data) => {
    return await makeRequest(`freelancer-treatment`, data, 'POST');
}
export const updateFreelancerTreatment = async (id,data) => {
    return await makeRequest(`freelancer-treatment/${id}`, data, 'PUT');
}
export const deleteFreelancerTreatment = async (id) => {
    return await makeRequest(`freelancer-treatment/${id}`, null, 'DELETE');
}

// FREELANCER ADD-ON REQUEST
export const getfreelancreAddonList = async () => {
    return await makeRequest(`freelancer-addon`, 'GET');
}
export const createFreelancerAddon = async (data) => {
    return await makeRequest(`freelancer-treatment-addon `, data, 'POST');
}
export const updateFreelancerAddon = async (id,data) => {
    return await makeRequest(`freelancer-treatment-addon/${id}`, data, 'PUT');
}
export const deleteFreelancerAddon = async (id) => {
    return await makeRequest(`freelancer-treatment-addon/${id}`, null, 'DELETE');
}

//FREELANCER CATEGORY REQUEST
export const getfreelancerCategory = async () => {
    return await makeRequest(`freelancer-treatment-category`, 'GET');
}
export const createFreelancerGroup = async (data) => {
    return await makeRequest(`freelancer-treatment-group `, data, 'POST');
}
export const getFreelancerGroup = async () => {
    return await makeRequest(`freelancer-treatment-group `, 'GET');
}
export const deleteFreelancerGroup = async (id) => {
    return await makeRequest(`freelancer-treatment-group/${id} `, null, 'DELETE');
}
//FREELANCER ADD/EDIT
export const createFreelancerProfile = async (data) => {
    return await makeRequest(`freelancer-setting`, data, 'POST',true,true);
}
export const updateFreelancerProfile = async (id,data) => {
    return await makeRequest(`freelancer-setting/${id}`, data, 'POST',true,true);
}
export const uploadFreelancerPortfolioImage = async (data) => {
    return await makeRequest(`freelancer-setting/portfolio`, data, 'POST', true, true);
}
export const getFreelancerPortfolio = async (data) => {
    return await makeRequest(`freelancer-setting/portfolio`, null, 'GET', true, true);
}
export const deleteFreelancerPortfolioImage = async (id) => {
    return await makeRequest(`freelancer-setting/portfolio/${id}`, null, 'DELETE', true, true);
}

// FREELANCER SETTINGS REQUESTS
export const getFreelancerSettings = async () => {
    return await makeRequest(`freelancer-setting/fetch-profile`, 'GET');
}
export const updateFreelancerSettings = async (data) => {
    return await makeRequest(`freelancer-setting/update-profile`, data, 'POST', true, true);
}
export const updateFreelancerHours = async (data) => {
    return await makeRequest(`freelancer-setting/update-hours`, data, 'POST', true, false);
}
export const getFreelancerBookings = async (data)=> {
    return await makeRequest(`bookings/calendar?start_date=${data?.start_date}&end_date=${data?.end_date}`, data, 'GET', true)
}

// FREELANCER BOOKINGS
export const cancelBooking = async (id) => {
    var data = {
        status: 'canceled'
    }
    return await makeRequest(`bookings/${id}`, data, 'PUT');
}
export const updateBooking = async (id, datetime = null, safetyStatus = null) => {

    var data = {}
    if (datetime) {
        data.datetime = datetime.format('YYYY-MM-DD H:mm:00')
    }
    if (safetyStatus) {
        data.safety_status = safetyStatus
    }
    return await makeRequest(`bookings/${id}`, data, 'PUT');
}

//Freelancer Payment
export const getFreelancerPayment = async () => {
    return await makeRequest(`freelancer-setting/payments`, 'GET');
}
export const getFreelancerPaymentLink = async () => {
    return await makeRequest(`freelancer-setting/payments/get-link`, 'GET');
}

export const userChangePassword = async (data)=>{
    return await makeRequest(`user/change-password`, data, 'POST')
}

// GUEST REQUESTS
export const getGuestLocations = async (data)=>{
    return await makeRequest(`guest/locations`, data, 'GET', false)
}
export const getGuestTreatmentCategories = async (data)=>{
    return await makeRequest(`guest/treatment-categories`, data, 'GET', false)
}
export const getGuestFreelancersAvailable = async (data) => {

    var url = `guest/freelancers?`
    if ('location_area_id' in data) {
        url = url + "location_area_id=" + data.location_area_id + "&"
    }
    if ('treatment_category_id' in data) {
        url = url + "treatment_categories[0]=" + data.treatment_category_id + "&"
    }
    if ('treatment_group_id' in data) {
        url = url + "treatment_groups[0]=" + data.treatment_group_id + "&"
    }    
    if ('datetime' in data) {
        url = url + "datetime=" + data.datetime + "&"
    }    

    console.log(url)
    return await makeRequest(url, null, 'GET', false)
}
export const getGuestFreelancer = async (id) => {
    return await makeRequest(`guest/freelancers/${id}`, null, 'GET', false)
}

// CART/BOOKING REQUESTS
export const createCart = async (data)=> {
    data.customer_id = getUserId()
    return await makeRequest(`cart`, data, 'POST', false)
}
export const getCart = async (data)=> {
    return await makeRequest(`cart?customer_id=` + getUserId(), data, 'GET', false)
}
export const getCartAddons = async (data)=> {
    return await makeRequest(`cart/addons?customer_id=` + getUserId(), data, 'GET', false)
}
export const updateCart = async (data)=> {
    data.customer_id = getUserId()
    return await makeRequest(`cart/update`, data, 'POST', false)
}
export const updateCartUser = async (customerIdOld, customerIdNew) => {
    let url = `cart/updateuser?customer_id_old=${customerIdOld}&customer_id_new=${customerIdNew}`
    return await makeRequest(url, null, 'POST', false)
}
export const getCartCheckoutSessionURL = async (cartId, customerNote)=> {
    let url = `cart/checkout-session?cart_id=${cartId}`
    if (customerNote) {
        url = url + `&customer_note=${customerNote}`
    }
    return await makeRequest(url, null, 'GET', true)
}
export const getCustomerBookings = async (data)=> {
    return await makeRequest(`bookings`, data, 'GET', true)
}

// CUSTOMER SETTINGS
export const getCustomerSetting = async (data)=> {
    return await makeRequest(`customer-setting`, data, 'GET', true)
}
export const updateCustomerSetting = async (data)=> {
    return await makeRequest(`customer-setting`, data, 'POST', true)
}

// WEBSITE
export const submitContactUs = async (data)=> {
    return await makeRequest(`website/contact-us`, data, 'POST', true)
}
