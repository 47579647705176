import React, {useState} from "react";
import "./Header.css"
import {ReactComponent as IconCustomer} from '../../assets/icon/IconCustomer.svg'
import TanglLogo from '../../assets/img/logo-tangl.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_USER } from '../../types';
import { logout } from '../../services/requests';
import ModalWindow from '../ModalWindow';

const WebsiteHeader = () => {
    const dispatch= useDispatch()
    const navigate= useNavigate()
    const [logoutModal, setLogoutModal] = useState(false);
    const auth = useSelector((state) => state);
    const customerLogout = async () => {
        await logout();
        dispatch({ type: LOGOUT_USER });
        navigate('/login')
      };

    return <section id="admin-header" className="w-100">
        <div className="custom-container">
            <div className="row">
                <div className="col-2">
                    <div className="logo">
                        <a href="/">
                            <img src={TanglLogo} alt='Tangl Beauty Logo' />
                        </a>
                    </div>
                </div>
                <div className="col-10">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light ml-auto h-100 d-flex">
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <div className="navbar-nav h-100 d-flex flex-column flex-lg-row align-items-center ml-auto">
                                {/*
                                <a href="src/components/WebsiteHeader#" className="nav-link p-0">Hair</a>
                                <a href="src/components/WebsiteHeader#" className="nav-link p-0">Nails</a>
                                <a href="src/components/WebsiteHeader#" className="nav-link p-0">Make-up</a>
                                <a href="src/components/WebsiteHeader#" className="nav-link p-0">Weddings</a>
                                */}
                                <a href="/prouser/info" className="nav-link p-0 pro-link">Become a Pro</a>
                                {
                                    auth?.authenticated?
                                    <>
                                    <Link className="nav-link p-0 login-link" onClick={() =>setLogoutModal(true)}>Log out</Link>                                    
                                    <Link to='/customer' className="header__cart-button"><IconCustomer /></Link>
                                    </>
                                    :<Link to='/login' className="nav-link p-0 login-link">Log In</Link>
                                }
                                
                                {/*
                                &nbsp;id: ({auth?.id})
                                &nbsp;customer.id: ({getUserId()})
                                */}

                            </div>
                        </div>
                        <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>

        <ModalWindow show={logoutModal}
                                         title={`Logout`}
                                         body={'Are you sure you want to logout now?'}
                                         hideAction={async () => {
                                          customerLogout()
                                         }}
                                         confirmClassName={'btn-dark'}
                                         handleClose={() => {
                                             setLogoutModal(false)
                                         }}
                            />

    </section>
}

export default WebsiteHeader;
