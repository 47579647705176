import React, {useState} from "react";
import './AdminLogin.css';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {LOGIN_USER} from "../../../types";
import {makeRequest} from "../../../services/requests";
import {getErrorMessage} from "../../../services/utils";
import WebsiteHeader from "../../../components/Website/Header";

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [inputs, setInput] = useState({email: "", password: ""});
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const inputChange = (e) => {
        setInput({...inputs, [e.target.name]: e.target.value});
    }

    const login = async (event) => {
        event.preventDefault();
        if (!isLoading) {
            setIsLoading(true);
            const resp = await makeRequest('admin/auth/sign-in', inputs, 'POST', false)
            if (resp.token) {
                dispatch({
                    type: LOGIN_USER, payload: {
                        token: resp.token,
                        role: resp.user.roles[0]?.name,
                        email: resp.user.email,
                        name: resp.user.name,
                    }
                });
                navigation('/admin');
            }

            if (resp.result === 'error') {
                setErrorMessage(getErrorMessage(resp));
            }
            setIsLoading(false);
        }
    }

    return <>
        <WebsiteHeader />

        <section id="login-section" className="vh-100">
            <div className="line line-1"></div>
            <div className="line line-2"></div>
            <div className="line line-3">

            </div>
            <div className="line line-4"></div>
            <div className="custom-container">
                <div className="row h-100">
                    <div className="col-lg-5 col-12 offset-lg-1 d-flex align-items-center">
                        <div className="login-form d-flex flex-column m-lg-0 m-auto">
                            <span className="login-title">Sign in to Tangl admin.</span>
                            <span className="login-subtitle">Welcome. Please enter the admin login details below.</span>

                            {
                                errorMessage ?
                                    <div className={"alert alert-danger"}
                                        dangerouslySetInnerHTML={{__html: errorMessage}}/> : null
                            }

                            <form onSubmit={login}>
                                <div className="form-group">
                                    <label htmlFor="inputEmail1">Email</label>
                                    <input onChange={inputChange} name="email" type="email" className="form-control"
                                           id="inputEmail1" aria-describedby="emailHelp"
                                           placeholder=""/>
                                </div>
                                <div className="form-group field_password">
                                    <label htmlFor="inputPassword">Password</label>
                                    <input onChange={inputChange} name="password"
                                           type={showPassword ? 'text' : 'password'}
                                           className="form-control"
                                           id="inputPassword" placeholder=""/>
                                    <button disabled={isLoading} onClick={() => {
                                                setShowPassword(!showPassword)
                                            }} type="button"
                                            className={`btn btnShowHide ${showPassword ? 'show' : ''}`}>
                                        Show/Hide
                                    </button>
                                </div>
                                <button type="submit" className="btn login-btn">Sign In</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-5 offset-1 d-lg-block d-none">
                        <div id="admin-login-bg"></div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default AdminLogin;
