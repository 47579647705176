export const getErrorMessage = (error) => {
    if (error.result !== 'error') {
        return false;
    }
    if (typeof error.message === 'string') {
        return error.message;
    }
    if (typeof error.messages === 'object') {
        return error.messages.map(v => {
            return Object.values(v).join('<br />')
        })
    }
}

export const capitalize = (word) => {
    return word?.charAt(0).toUpperCase()
        + word?.slice(1);
}


export function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const dateObj = new Date(dateString);
    const formattedDate = dateObj?.toLocaleDateString('en-US', options);
    return formattedDate?.replace(',', '');
  }