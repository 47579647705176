import React from "react";
import {Route, Routes} from "react-router-dom";
import AdminDashboard from "./AdminDashboard";
import AdminFreelancersList from "./AdminFreelancersList";
import AdminTreatmentCategories from "./AdminTreatmentCategories/AdminTreatmentCategories";
import AdminTreatmentCategoryDetailed from "./AdminTreatmentCategoryDetailed/AdminTreatmentCategoryDetailed";
import Sidebar from "../../components/Sidebar/Sidebar";
import AdminLocations from "./AdminLocations/AdminLocations";
import AdminNews from './AdminNews/AdminNews';
import AdminReports from './AdminReports/AdminReports';

const AdminMain = () => {
    return <div className="wrapper">
        <Sidebar role="admin"/>
        <Routes>
            <Route path={'/'} element={
                <AdminDashboard/>
            }/>
            <Route path={'/reports'} element={
                <AdminReports/>
            }/>
            <Route path={'/prousers'} element={
                <AdminFreelancersList/>
            }/>
            <Route path={'/treatments'} element={
                <AdminTreatmentCategories/>
            }/>
            <Route path={'/treatments/:categoryId'} element={
                <AdminTreatmentCategoryDetailed/>
            }/>
            <Route path={'/locations'} element={
                <AdminLocations/>
            }/>
            <Route path={'/news'} element={
                <AdminNews/>
            }/>
        </Routes>
    </div>
}

export default AdminMain;
