import {createStore} from "redux";
import {LOGIN_USER, LOGOUT_USER} from "./types";
import {setToken, setUserId} from "./services/token";

const intitialState = {
    authenticated: false,
    role: null,
    email: null,
    name: null,
    status:false,
    id: null,
};

const reducer = (state = intitialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            setToken(action.payload.token);
            return {
                ...state,
                authenticated: true,
                role: action.payload.role,
                first_name: action.payload.name,
                last_name: action.payload.name,
                email: action.payload.email,
                status: action.payload.status,
                id: action.payload.id,

                address1: action.payload.address1,
                address2: action.payload.address2,
                city: action.payload.city,
                county: action.payload.county,
                postcode: action.payload.postcode,
            };

        case LOGOUT_USER:
            setUserId(null)
            return {...state, authenticated: false, role: null, email: null, name: null, id: null};

        default:
            return state;
    }
};

const store = createStore(reducer);

export default store;
