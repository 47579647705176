import './CustomEditor.css'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CustomEditor = ({ data, onChange ,toolbarConfig,disabled}) => {
  const editorConfiguration = {
    toolbar:toolbarConfig || { items: ['undo', 'redo', 'heading','bold', 'italic', 'bulletedList', 'numberedList'] },
    placeholder: '',
    typing: {
      // Let CKEditor handle Enter key like a regular newline
      enterMode: 'CKEDITOR.ENTER_BR',
    },
  };
  return (
    <CKEditor name="body" editor={ClassicEditor} data={data || ""} onChange={onChange} config={editorConfiguration} disabled={disabled}/>
  );
};

//  default CustomEditor;
export const getTextLength = (editorData) => {
  // Use CKEditor API to get the plain text content
  const tempDiv = document?.createElement('div');
  tempDiv.innerHTML = editorData;
  const plainText = tempDiv?.textContent || tempDiv?.innerText;
  return plainText?.length;
};