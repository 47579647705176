import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {LOGOUT_USER} from '../../types';
import {logoutAdmin, logout } from '../../services/requests';
import {NavLink} from 'react-router-dom';
import './Sidebar.css';
import ModalWindow from '../ModalWindow';
import {ReactComponent as IconLogout} from '../../assets/icon/IconLogout.svg'
import Logo from '../../assets/img/logo.png';

const Sidebar = ({ role }) => {
  const isAdmin = role === 'admin';
  const isFreelancer = role === 'freelancer';
  // const isCustomer = role === 'customer';
  const dispatch = useDispatch();
  const [logoutModal, setLogoutModal] = useState(false);
  const adminLogOut = async () => {
    await logoutAdmin();
    dispatch({ type: LOGOUT_USER });
  };

  const freelancerLogout = async () => {
    window.dispatchEvent(new CustomEvent('logout'));
    await logout();
    dispatch({ type: LOGOUT_USER });
  };

  return (
    <header id='sidebar-wrapper'>
      <div id='sidebar-section' className='h-100'>
        {isAdmin && (
          <div className='container-fluid d-flex flex-column'>
            <div className='sidebar-logo-wrap'><img src={Logo} alt='logo'/></div>
            <nav className='navbar navbar-light bg-light'>
              <ul className='navbar-nav'>
                <li className='nav-item' id='itemDashboard'>
                  <NavLink
                    to={'/admin'}
                    end
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li className='nav-item' id='itemReport'>
                  <NavLink
                    to={'/admin/reports'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    List
                  </NavLink>
                </li>
                <li className='nav-item' id='itemList'>
                  <NavLink
                    to={'/admin/prousers'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    List
                  </NavLink>
                </li>
                <li className='nav-item' id='itemTreatment'>
                  <NavLink
                    to={'/admin/treatments'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    Treatments
                  </NavLink>
                </li>
                <li className='nav-item' id='itemLocation'>
                  <NavLink
                    to={'/admin/locations'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    Locations
                  </NavLink>
                </li>
                <li className='nav-item' id='itemBlog'>
                  <NavLink
                    to={'/admin/news'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    Blog
                  </NavLink>
                </li>
              </ul>
            </nav>
            <NavLink className='btnLogOut mt-auto' onClick={() => setLogoutModal(true)}>
             <IconLogout/>
            </NavLink>

            <ModalWindow show={logoutModal}
                                         title={`Logout`}
                                         body={'Are you sure you want to logout now?'}
                                         hideAction={async () => {
                                          adminLogOut()
                                         }}
                                         confirmClassName={'btn-dark'}
                                         handleClose={() => {
                                             setLogoutModal(false)
                                         }}
                            />

          </div>
        )}
        {isFreelancer && (
          
          <div className='container-fluid d-flex flex-column h-100'>
            <div className='sidebar-logo-wrap'><img src={Logo} alt='logo'/></div>
            <nav className='navbar navbar-light bg-light h-100 align-items-start'>
              <ul className='navbar-nav h-100 d-flex'>
                <li className='nav-item' id='itemCalender'>
                  <NavLink
                    to={'/prouser/calendar'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    Calendar
                  </NavLink>
                </li>
                {/* <li className='nav-item' id='itemReport'>
                  <NavLink
                    to={'/prouser/reports'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    List
                  </NavLink>
                </li> */}
                <li className='nav-item' id='itemTreatment'>
                  <NavLink
                    to={'/prouser/treatments'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    Categories
                  </NavLink>
                </li>
                <li className='nav-item' id='itemBlog'>
                  <NavLink
                    to={'/prouser/news'}
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    News
                  </NavLink>
                </li>
                <li className='nav-item mt-auto' id='itemUser'>
                  <NavLink
                    to={'/prouser/settings'}
                    end
                    className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                  >
                    User
                  </NavLink>
                </li>
                <li className='nav-item' id='itemLocation'>
                  <NavLink className='btnLogOutFreelancer mt-auto' onClick={() => setLogoutModal(true)}>
                   <IconLogout/>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>

      <ModalWindow show={logoutModal}
                                         title={`Logout`}
                                         body={'Are you sure you want to logout now?'}
                                         hideAction={async () => {
                                          freelancerLogout()
                                         }}
                                         confirmClassName={'btn-dark'}
                                         handleClose={() => {
                                             setLogoutModal(false)
                                         }}
                            />
    </header>
  );
};
export default Sidebar;
