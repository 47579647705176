import React, { Fragment, useEffect, useState } from 'react';
import {
  deleteFreelancer,
  getFreelancersList,
  updateFreelancer,
  createFreelancer,
} from '../../services/requests';
import { capitalize } from '../../services/utils';
import ModalWindow from '../../components/ModalWindow';
import Table from '../../components/Table';
import ModalForm from '../../components/ModalForm';
import FreelancerForm from '../../components/FreelancerForm';
import { Link } from 'react-router-dom';
import ProfileImg from '../../assets/img/Profile.png';
import { ReactComponent as IconDelete } from '../../assets/icon/IconDelete.svg';
import { ReactComponent as IconEdit } from '../../assets/icon/IconEdit.svg';
const AdminFreelancersList = () => {
  const API_URL = process.env.REACT_APP_IMAGE;
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: 'id', sort: 'asc' });
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModel] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [addFreelancer, setAddFreelancer] = useState(false);
  const [editFreelancer, setEditFreelancer] = useState(false);
  const [editFreelancerData, setEditFreelancerData] = useState('');
  const [dataToDelete, setDataToDelete] = useState();
  const [dataActiveToDisabled, setDataActiveToDisabled] = useState();

  const activateFreelancer = async (id) => {
    if (!inProcess) {
      setInProcess(true);
      const resp = await updateFreelancer(id, { status: 1, _method: 'PUT' });
      if (resp?.result === 'success') {
        setShowModel(false);
        await loadData();
      }
      setInProcess(false);
    }
  };

  const disableFreelancer = async (id) => {
    if (!inProcess) {
      setInProcess(true);
      const resp = await updateFreelancer(id, { status: 0, _method: 'PUT' });
      if (resp?.result === 'success') {
        setShowModel(false);
        await loadData();
      }
      setInProcess(false);
    }
  };

  const deleteFreelancerAction = async (id) => {
    if (!inProcess) {
      setInProcess(true);
      const resp = await deleteFreelancer(id);
      if (resp?.result === 'success') {
        setDeleteModal(false);
        await loadData();
      }
      setInProcess(false);
    }
  };

  const handleDelete = (data) => {
    if (data?.status) {
      setDeleteModal(true);
      setDataToDelete(data);
    }
  };
  const handleActiveDisabled = (data) => {
    setShowModel(true);
    setDataActiveToDisabled(data);
  };
  const fields = [
    {
      label: 'Name',
      th: false,
      sort: 'first_name,last_name',
      render: (row) => {
        return (
          <Fragment>
            <span className='img_thumb'>
              {row?.photo ? (
                <img
                src={(row?.photo && `${API_URL}freelancers/${row?.photo}`) }
                  alt={row?.user?.name}
                  className='img_circle'
                />
              ) : (
                <img src={ProfileImg} alt={row?.user?.name} className='img_circle' />
              )}
            </span>
            {row?.first_name} {row?.last_name}
          </Fragment>
        );
      },
    },
    {
      label: 'Email',
      th: false,
      sort: 'email',
      render: (row) => {
        return <Fragment>{row?.user?.email}</Fragment>;
      },
    },
    {
      label: 'Status',
      sort: 'status',
      render: (row) => {
        if (row?.status) {
          return (
            <Link
              className='status_active'
              onClick={() => {
                handleActiveDisabled(row);
              }}
            >
              Active
            </Link>
          );
        } else {
          return (
            <Link
              className='status_disable'
              onClick={() => {
                handleActiveDisabled(row);
              }}
            >
              Disabled
            </Link>
          );
        }
      },
    },
    {
      label: 'Location',
      sort: 'city',
      render: (row) => {
        return capitalize(row.city);
      },
    },
    {
      label: 'Service',
      render: (row) => {
        return `${row?.treatment_categories?.map((category) => {
          return category?.name;
        })}`;
      },
    },
    {
      label: '&nbsp;',
      render: (row) => {
        return (
          <Fragment>
            <div className='d-flex'>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                  setEditFreelancerData(row?.id);
                  setEditFreelancer(true);
                }}
                disabled={!row.status}
              >
                <IconEdit />
              </button>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => handleDelete(row)}
                disabled={!row.status}                
              >
                <IconDelete />
              </button>
            </div>
          </Fragment>
        );
      },
    },
  ];
  const loadData = async () => {
    if (!isLoading) {
      setLoading(true);
      const resp = await getFreelancersList({ page });
      setData(resp?.data);
      setMeta(resp);
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <main id='main-section'>
      <div className='container-fluid'>
        <div className='row header-section'>
          <div className='col-sm-8'>
            <h2 className='titleHeading2'>Pro Users</h2>
          </div>
          <div className='col-sm-4 add_freelancer_wrap'>
            <button
              type='button'
              className='btn btn-primary'
              data-toggle='modal'
              data-target='#addFreelancerModal'
              onClick={() => {
                setAddFreelancer(true);
              }}
            >
              Add Pro User
            </button>
            {addFreelancer && (
              <ModalForm
                show={addFreelancer}
                title={'Add Pro User'}
                body={<FreelancerForm setAddFreelancer={setAddFreelancer} loadDataList={loadData} />}
                hideAction={async (data) => {
                  await createFreelancer(data);
                }}
                handleClose={() => {
                  setAddFreelancer(false);
                }}
              />
            )}
          </div>
        </div>
        <div className='freelancers_info'>
          <Table
            fields={fields}
            data={data}
            isLoading={isLoading}
            setPage={setPage}
            meta={meta}
            sort={sort}
            setSort={setSort}
          />
        </div>
      </div>
      {editFreelancer && (
        <ModalForm
          show={editFreelancer}
          title={'Edit Pro User'}
          body={
            <FreelancerForm
              id={editFreelancerData}
              setEditFreelancer={setEditFreelancer}
              loadDataList={loadData}
            />
          }
          hideAction={async (data) => {
            await updateFreelancer(editFreelancerData, data);
          }}
          handleClose={() => {
            setEditFreelancerData(null);
            setEditFreelancer(false);
          }}
        />
      )}
      <ModalWindow
        show={deleteModal}
        confirmClassName={'btn-danger'}
        title={`Delete Pro User`}
        body={`Are you sure you want to delete '${dataToDelete?.first_name} ${dataToDelete?.last_name}'?`}
        handleClose={() => {
          setDeleteModal(false);
        }}
        hideAction={async () => {
          await deleteFreelancerAction(dataToDelete?.id);
          setDeleteModal(false);
        }}
      />
      {dataActiveToDisabled?.status ? (
        <ModalWindow
          title={`Disable Pro User`}
          body={
            <>Are you sure you want to disable '{dataActiveToDisabled?.user?.name}'?
            <br /><br />
            <ul>
              <li>They will no longer be able to login and access their account.</li>
              <li>They will be unable to receive new bookings via the website/app.</li>
              <li>Any of their existing bookings will become inactive and unavailable.</li>
            </ul></>
          }
          hideAction={async () => {
            await disableFreelancer(dataActiveToDisabled.id);
            setShowModel(false);
          }}
          handleClose={() => {
            setShowModel(false);
          }}
          show={showModal}
        />
      ) : (
        <ModalWindow
          title={`Enable Pro User`}
          body={
            <><p>Are you sure you want to enable '{dataActiveToDisabled?.user?.name}'?</p>
            <ul>
              <li>They will be able to login and access their account again.</li>
              <li>They will be able to receive bookings via the website/app.</li>
              <li>Any bookings under that user will become active and available again.</li>
            </ul>
            </>
          }
          hideAction={async () => {
            await activateFreelancer(dataActiveToDisabled.id);
            setShowModel(false);
          }}
          handleClose={() => setShowModel(false)}
          show={showModal}
        />
      )}
    </main>
  );
};

export default AdminFreelancersList;
