import React, { useState } from 'react';
import { userChangePassword } from '../../../../services/requests';

const Security = () => {
  const [passwordFields, setPasswordFields] = useState({
    showPassword: false,
    shownew_password: false,
    shownew_password_confirmation: false,
  });
  const [validationErrors, setValidationErrors] = useState({
    password: '',
    new_password: '',
    new_password_confirmation: '',
  });
  const [InputsData, setInputsData] = useState({ password: '', new_password: '', new_password_confirmation: '' });
  const [messageSuccess, setMessageSuccess] = useState(null)
  const [messageError, setMessageError] = useState(null)

  const InputChange = (e) => {
    const { name, value } = e?.target;
    setInputsData((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
    const errors = {};
    if (name === 'password' && !value) {
      errors.password = 'Password is required.';
    }
    if (name === 'new_password' && !value) {
      errors.new_password = 'New password is required.';
    } else if (name === 'new_password' && value.length < 8) {
      errors.new_password = 'Password must be at least 8 characters long.';
    } else if (name === 'new_password') {
      const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordPattern?.test(value)) {
        errors.new_password =
          'Password must contain at least 1 capital letter, 1 special symbol, and 1 number.';
      }
    }
    if (name === 'new_password_confirmation' && !value) {
      errors.new_password_confirmation = 'Confirm password is required.';
    } else if (name === 'new_password_confirmation' && value !== InputsData?.new_password) {
      errors.new_password_confirmation = 'Passwords do not match.';
    }
    setValidationErrors(errors);
  };

  const handleToggleShowPassword = (field) => {
    setPasswordFields((prevPasswordFields) => ({
      ...prevPasswordFields,
      [field]: !prevPasswordFields[field],
    }));
  };
  const resetFormState = () => {
    setInputsData({
      password: '',
      new_password: '',
      new_password_confirmation: '',
    });
    setValidationErrors({
      password: '',
      new_password: '',
      new_password_confirmation: '',
    });
  };

  const handleSave = async (e) => {
    e.preventDefault()
    const resp = await userChangePassword(InputsData);
    if (resp?.result === 'success') {
      setMessageError(null)
      setMessageSuccess("Your password has been updated successfully.")
      resetFormState();
    }
    else {
      setMessageSuccess(null)
      setMessageError('Please check your existing password was entered correctly. ', resp.result)
    }
  };
  return (

    <div>
       {messageSuccess ? (
        <div className={'alert alert-success'}>{messageSuccess}</div>
        ) : null}                

      {messageError ? (
        <div className={'alert alert-danger'}>{messageError}</div>
        ) : null}                

      <div className='user-name align-items-center mt-4 '>
        <div className='title'>Change Password</div>
        <div className='fname'>
          <div style={{ position: 'relative' }}>
            <input
              onChange={InputChange}
              name='password'
              type={passwordFields?.showPassword ? 'text' : 'password'}
              className={'form-control'}
              placeholder='Enter existing password.'
              value={InputsData?.password}
            />
            <button
              onClick={() => handleToggleShowPassword('showPassword')}
              type='button'
              className={`btn btnShowHide ${passwordFields?.showPassword ? 'show' : ''}`}
            >
              Show/Hide
            </button>
          </div>
          {validationErrors?.password && <div className='invalid-feedback'>{validationErrors?.password}</div>}
        </div>
      </div>
      <div className='user-name align-items-center mt-4'>
        <div className='title'></div>
        <div className='fname'>
          <div style={{ position: 'relative' }}>
            <label htmlFor='inputPassword' className='title'>
              New Password
            </label>
            <input
              onChange={InputChange}
              name='new_password'
              type={passwordFields?.shownew_password ? 'text' : 'password'}
              className={'form-control'}
              placeholder='Enter a new password.'
              value={InputsData?.new_password}
            />
            <button
              onClick={() => handleToggleShowPassword('shownew_password')}
              type='button'
              className={`btn btnShowHide ${passwordFields?.shownew_password ? 'show' : ''}`}
            >
              Show/Hide
            </button>
          </div>
          {validationErrors?.new_password && <div className='invalid-feedback'>{validationErrors?.new_password}</div>}
        </div>
      </div>
      <div className='user-name align-items-center mt-4'>
        <div className='title'></div>
        <div className='fname'>
          <div style={{ position: 'relative' }}>
            <label htmlFor='inputPassword' className='title'>
              Confirm New Password
            </label>
            <input
              onChange={InputChange}
              name='new_password_confirmation'
              type={passwordFields?.shownew_password_confirmation ? 'text' : 'password'}
              className={'form-control'}
              placeholder='Enter your new password again.'
              value={InputsData?.new_password_confirmation}
            />
            <button
              onClick={() => handleToggleShowPassword('shownew_password_confirmation')}
              type='button'
              className={`btn btnShowHide ${passwordFields?.shownew_password_confirmation ? 'show' : ''}`}
            >
              Show/Hide
            </button>
          </div>
          {validationErrors.new_password_confirmation && <div className='invalid-feedback'>{validationErrors?.new_password_confirmation}</div>}
        </div>
      </div>
      <div className='user-email mt-4'>
        <div className='profile-title'>
          <div className='title'></div>
        </div>
        <button className='btn btn-dark' onClick={handleSave} style={{ maxWidth: '170px', width: '100%' }} disabled={
          !InputsData.password ||
          !InputsData.new_password ||
          !InputsData.new_password_confirmation ||
          Object?.values(validationErrors)?.some((error) => error !== '')
        }>
          Save
        </button>
      </div>
    </div>
  );
};

export default Security;
