import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import WebsiteHeader from '../../../components/Website/Header';
import {useParams} from "react-router-dom";
import {getGuestLocations, getGuestTreatmentCategories, getGuestFreelancersAvailable} from "../../../services/requests"
import './BookAppointment.css';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import moment from 'moment';
import Loading from '../../../components/Loading';
import WebsiteFooter from '../../../components/Website/Footer';
import ProfilePhoto from '../../../components/Freelancer/ProfilePhoto';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import dayjs from "dayjs";

export const BookAppointment = () => {
  
    const {locationId, areaId, treatmentCategoryId, date, time} = useParams()

    const [isLoading, setLoading] = useState(false);
    const [data] = useState(null);
    
    const [locationData, setLocationData] = useState(null);
    const [treatmentCategoryData, setTreatmentCategoryData] = useState(null);
    
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedTreatmentCategory, setSelectedTreatmentCategory] = useState(null);
    const [selectedTreatmentCategoryGroup, setSelectedTreatmentCategoryGroup] = useState(null);    
    const [freelancers, setFreelancers] = useState(null)
    const [areaData, setAreaData] = useState(null);

    const [searchFormDates, setSearchFormDates] = useState([]);
    const [searchFormTimes, setSearchFormTimes] = useState([]);    
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const startDate = moment(); startDate.set({m:0})

    const loadData = async () => {
      if (!isLoading) {
        setLoading(true);
        await getGuestLocations().then(results => {
          setLocationData(results?.data);
          if (locationId)  {
            // eslint-disable-next-line eqeqeq
            setSelectedLocation(results?.data.filter((location) => location.id == locationId)[0])
            // eslint-disable-next-line eqeqeq
            setAreaData(results?.data.filter((location) => location.id == locationId)[0]?.areas)
            // eslint-disable-next-line eqeqeq
            setSelectedArea(results?.data.filter((location) => location.id == locationId)[0]?.areas
            // eslint-disable-next-line eqeqeq
                                              .filter((area) => area.id == areaId)[0])  
          }
        });

        await getGuestTreatmentCategories().then(results => {
          setTreatmentCategoryData(results?.data);
          if (treatmentCategoryId) {
            // eslint-disable-next-line eqeqeq
            setSelectedTreatmentCategory(results?.data.filter((category) => category.id == treatmentCategoryId)[0])
          }
        });

        setLoading(false);        
      }
    };
    useEffect(() => {
      loadData();

      if (!selectedDate) {
        setSelectedDate(startDate)
        setSelectedTime(startDate)
        setSelectedTreatmentCategory(null)
      }

      if (searchFormDates.length===0) {
        for (let i = 0; i < 10; i++) {
          var newDate = moment(startDate, "DD-MM-YYYY").add(i, 'days');
          newDate.set('second', 0)
          searchFormDates.push(newDate)
        }         
      }
  
      if (searchFormTimes.length===0) {

        for (let i = 0; i < 18; i++) {
          var newTime = moment(startDate, "DD-MM-YYYY").add(i*30, 'minutes');
          if (newTime > moment()) {
            searchFormTimes.push(newTime)
          }
        }
      }

      if (date !== "0") {
        setSelectedDate(moment.unix(date))
        setSelectedTime(moment.unix(time))
        loadFreelancers(moment.unix(date), moment.unix(time))
      }
      else {
        setSelectedDate(startDate)
        setSelectedTime(searchFormTimes[0])
        loadFreelancers(startDate, startDate)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, locationId, areaId, treatmentCategoryId]);  

    const navigate = useNavigate()
    // const handleSearchClick = () => {
    //   if (!selectedLocation || !selectedArea || !selectedTreatmentCategory) {
    //     alert('Please select a county, area and category.')
    //     return
    //   }

    //   loadFreelancers()
    // }

    const handleChangeSelection = (optionType, value) => {
      if (optionType === "location") {
        setSelectedLocation(value)
        setSelectedArea(null)
        setAreaData(value.areas)
      }
      else if (optionType === "area") {
        setSelectedArea(value)
      }
      else if (optionType === "treatmentCategory") {
        setSelectedTreatmentCategory(value)
        setSelectedTreatmentCategoryGroup(null)
      }      
    };

    const handleClickDateButton = (date) => {
      setSelectedDate(date)
      loadFreelancers(date)
    }
    const handleClickTimeButton = (time) => {
      setSelectedTime(time)
      loadFreelancers(null, time)
    }
    const handleClickTreatmentGroupButton = (group) => {
      setSelectedTreatmentCategoryGroup(group)
      loadFreelancers(null, null, group)
    }
    const handleChooseFreelancerClick = (freelancer) => {
      navigate(`/book-appointment/${selectedLocation.id}/${selectedArea.id}/${selectedTreatmentCategory.id}/${freelancer.id}/${selectedDate.unix()}/${selectedTime.unix()}`)
    }

    const handleDateTimePickerChange = (datetime) => {

      var selectedDateTime = moment(datetime["$d"]);
      var dateFoundInList = false
      for (let i = 0; i < (searchFormDates.length); i++) {
        var dateToCheck = searchFormDates[i]
        if (dateToCheck?.format("YYYY-MM-DD") === selectedDateTime.format("YYYY-MM-DD")) {          
          dateFoundInList = true          
        }
      }         

      // if date is visible, select it
      if (dateFoundInList) {
        setSelectedDate(selectedDateTime)
      }

      // if date is NOT visible, reset FIRST date to this date
      else {        
        var newSearchDates = []
        for (let i = 0; i < 10; i++) {
          var newDate = moment(selectedDateTime, "DD-MM-YYYY").add(i, 'days');
          newSearchDates.push(newDate)
        }         
        setSearchFormDates(newSearchDates)
        setSelectedDate(selectedDateTime)
      }
      
      var timeFoundInList = false
      for (let i = 0; i < (searchFormTimes.length); i++) {
        var timeToCheck = searchFormTimes[i]
        if (timeToCheck?.format("HH:mm") === selectedDateTime.format("HH:mm")) {
          timeFoundInList = true
        }
      }

      // if time is visible, select it
      if (timeFoundInList) {
        setSelectedTime(selectedDateTime)
      }

      // if time is NOT visible, reset FIRST time to this time
      else {
        var newSearchTimes = []

        if (selectedDateTime.minutes() !== 30 && selectedDateTime.minutes() !== 0) {
          selectedDateTime.set('minute', 0)
        }

        for (let i = 0; i < 18; i++) {
          var newTime = moment(selectedDateTime, "DD-MM-YYYY").add(i*30, 'minutes');
          if (newTime > moment()) {
            newSearchTimes.push(newTime)
          }          
        }         

        setSearchFormTimes(newSearchTimes)
        setSelectedTime(newSearchTimes[0])
      }      
    }
    
    const loadFreelancers = async (date = null, time = null, group = null) => {

      setLoading(true);

      var datetime = selectedDate
      if (selectedTime) {
        datetime.set({h:selectedTime.format("H"), m:selectedTime.format("m")})
      }
      if (date) {
        datetime = date
        if (selectedTime) {
          datetime.set({h:selectedTime.format("H"), m:selectedTime.format("m")})
        }
      }
      if (time) {
        datetime.set({h:time.format("H"), m:time.format("m")})
      }

      var freelancersAvailableData = {
        treatment_category_id: [treatmentCategoryId],        
        datetime: datetime.format('Y-MM-D HH:mm:00'),
        location_area_id: areaId,
      }

      if (group) {
        freelancersAvailableData.treatment_group_id = group.id
      }

      await getGuestFreelancersAvailable(freelancersAvailableData).then(results => {
        setFreelancers(results?.data)
        setLoading(false);
      })
    }

  return (
    <>
      <WebsiteHeader />
      
      <section id='login-section' className=''>
        <div className='book-appointment-header custom-container'>
        
          <div className='book-appointment-header__title'>
            <h2>Book an Appointment</h2>
          </div>

          <div className='book-appointment-header__search-form row'>

            <div className='col-sm'></div>

            <div className='book-appointment-header__search-form__dropdown-box col-sm'>
              <label>County</label>
              <DropdownButton title={selectedLocation ? selectedLocation?.name : 'Select County'}>
                    {locationData?.map((location, index) => (
                      <Dropdown.Item key={index} onClick={() => handleChangeSelection('location', location)}>
                        {location?.name}
                      </Dropdown.Item>
                    ))}
              </DropdownButton>
            </div>            
            <div className='book-appointment-header__search-form__dropdown-box col-sm'>
              <label>Areas</label>
              <DropdownButton title={selectedArea ? selectedArea?.name : 'Select Area'}>
                    {areaData?.map((area, index) => (
                      <Dropdown.Item key={index} onClick={() => handleChangeSelection('area', area)}>
                        {area?.name}
                      </Dropdown.Item>
                    ))}
              </DropdownButton>
            </div>
            <div className='book-appointment-header__search-form__dropdown-box col-sm'>
              <label>Treatment</label>
              <DropdownButton title={selectedTreatmentCategory ? selectedTreatmentCategory?.name : 'Select Category'}>
                    {treatmentCategoryData?.map((treatmentCategory, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleChangeSelection('treatmentCategory', treatmentCategory)}
                      >
                        {treatmentCategory?.name}
                      </Dropdown.Item>
                    ))}
              </DropdownButton>
            </div>
          
            <div className='book-appointment-header__search-form__dropdown-box book-appointment-header__search-form__dropdown-box--search-button col-sm'>
              {/*
              <button onClick={() => handleSearchClick()} className='btn-search'>Search</button>
              */}
            </div>          
            <div className='col-sm'></div>
          </div>

          <div className='book-appointment-header__date row'>
            <div className='col-sm'></div>

            <div className='col-sm-8'>
              <label>Select Date</label>
              <div>
                {searchFormDates?.map((date) => 
                <>                
                  {selectedDate?.format("D MMM") === date.format("D MMM") ?                   
                  <button className="book-appointment-header__button book-appointment-header__button--active" >{date.format("D MMM")}</button>
                  : 
                  <button className="book-appointment-header__button" onClick={() => handleClickDateButton(date)}>{date.format("D MMM")}</button>                  
                  }
                </>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker minutesStep={30} disablePast={true} ampm={false} 
                                defaultValue={dayjs(selectedDate?.format("YYYY-DD-MM"))}
                                onChange={(newDateTimeValue) => handleDateTimePickerChange(newDateTimeValue)}/>
                </LocalizationProvider>
              </div>
            </div>
            <div className='col-sm'></div>
          </div>

          <div className='book-appointment-header__time row'>
            <div className='col-sm'></div>

            <div className='col-sm-8'>
              <label>Select Time</label>
              <div>
                {searchFormTimes?.map((time) => 
                <>
                  {selectedTime?.format("H:mm A") === time.format("H:mm A") ?
                  <button className="book-appointment-header__button book-appointment-header__button--active" >{time.format("h:mm A")}</button>
                  : 
                  <button className="book-appointment-header__button" onClick={() => handleClickTimeButton(time)}>{time.format("h:mm A")}</button>                  
                  }
                </>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker minutesStep={30} disablePast={true} ampm={false} 
                                defaultValue={dayjs(selectedDate?.format("YYYY-DD-MM"))}
                                onChange={(newDateTimeValue) => handleDateTimePickerChange(newDateTimeValue)}/>
                </LocalizationProvider>
              </div>
            </div>
            
            <div className='col-sm'></div>
          </div>

          <div className='book-appointment-header__treatment row'>
          <div className='col-sm'></div>
            <div className='col-sm-8'>
              <label>Select Treatment</label>
              <div>
                {selectedTreatmentCategoryGroup ?
                <button className="book-appointment-header__button" onClick={() => handleClickTreatmentGroupButton(null)}>All</button>
                :
                <button className="book-appointment-header__button book-appointment-header__button--active">All</button>}                

                {selectedTreatmentCategory?.treatment_groups?.length > 0 ?
                  selectedTreatmentCategory?.treatment_groups?.map((group) => 
                  <> 
                   {selectedTreatmentCategoryGroup === group ?                   
                      <button className="book-appointment-header__button book-appointment-header__button--active">{group?.name}</button>
                      :
                    <button className="book-appointment-header__button" onClick={() => handleClickTreatmentGroupButton(group)}
                    >{group?.name}</button>                  }
                  </>
                  )
                  : ''}
              </div>
            </div>

            <div className='col-sm'></div>
          </div>

        </div>
      </section>

      {isLoading ? <Loading marginTop={'100px'}/> : 

      <section className='book-appointment-freelancers'>
        <div className='book-appointment-freelancers custom-container'>
          <div className='book-appointment-freelancers__row row'>
          {
          // eslint-disable-next-line eqeqeq
          freelancers?.length == 0 ?
          <>
          <div className="row">
          <div className="col align-center" style={{textAlign: 'center'}}>
            There are no professionals that match your search criteria. Please try again.
          </div>
          </div>
          </> 
          : ''           
          }

            {freelancers?.map((freelancer) => (            
              <div className="col-4">
                
                <div className='book-appointment-freelancers__freelancer-box row'>
                  <div className='col-sm-4'>
                    <ProfilePhoto freelancer={freelancer}/>
                  </div>
                  <div className='col-sm-8'>
                  <h5>
                    {freelancer?.first_name} {freelancer?.last_name}
                  </h5>
                  <p>
                  {truncate(freelancer?.description)}
                  </p>
                  <div>
                    <button onClick={() => handleChooseFreelancerClick(freelancer)} className='book-appointment-freelancers__choose-button'>
                      Select
                    </button>
                  </div>
                </div>
              </div>
            </div>
            ))}

          </div>
        </div>
      </section>      
    }

    <WebsiteFooter />
    </>

  );

  function truncate(str) {
    var maxLength = 125
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
}
};
