import React, { useEffect, useState } from 'react';
import { CustomInput } from '../../../../components/CustomInput';
import './Profile.css';
import { getCustomerSetting, updateCustomerSetting } from '../../../../services/requests'

const Profile = () => {

    const [data, setData] = useState(null);
    const [messageSuccess, setMessageSuccess] = useState(null)
    const [messageError, setMessageError] = useState(null)

    const loadData = async () => {
      await getCustomerSetting().then((customerSettings) => {        
        setData(customerSettings?.data)
      })  
    }

    const handleSaveClick = async () => {
      
      setMessageError(null)
      try {
        const body = {
          first_name: data?.first_name,
          last_name: data?.last_name,
          address1: data?.address1,
          address2: data?.address2,
          city: data?.city,
          county: data?.county,
          postcode: data?.postcode,
          email: data?.email,
          phone: data?.phone,
        };  
  
        const resp = await updateCustomerSetting(body);
        if (resp?.result === 'success') {
          setMessageError(null)
          setMessageSuccess("Your profile settings have now been saved.")
        }
        else {
          setMessageSuccess(null)
          setMessageError('Error updating profile settings. ', resp.result)
        }
      } catch (error) {
        console.error('Error updating profile settings. ', error);
      }
    }

    useEffect(() => {
      loadData()
    }, [])

    return (
<>
  <div className="customer-profile__profile-box">
       {messageSuccess ? (
        <div className={'alert alert-success'}>{messageSuccess}</div>
        ) : null}                

      {messageError ? (
        <div className={'alert alert-danger'}>{messageError}</div>
        ) : null}                

    <div className='user-name align-items-center '>
        <div className='title'>Name</div>
        <div className='fname'>
          <CustomInput
            className='custom-input form-control'
            placeholder=''
            type='text'
            value={data?.first_name}
            onChange={(e) => setData({ ...data, first_name: e?.target?.value })}
          />
        </div>
        <div className='lname'>
          <CustomInput
            className='custom-input form-control'
            type='text'
            placeholder=''
            value={data?.last_name}
            onChange={(e) => setData({ ...data, last_name: e?.target?.value })}
          />
        </div>
      </div>

      <div className='user-name align-items-center '>
        <div className='title'>Email</div>
        <div className='fname'>
          <CustomInput
            className='custom-input form-control'
            placeholder=''
            type='text'
            value={data?.email}
            onChange={(e) => setData({ ...data, email: e?.target?.value })}
          />
        </div>
      </div>

      <div className='user-name align-items-center '>
        <div className='title'>Phone Number</div>
        <div className='fname'>
          <CustomInput
            className='custom-input form-control'
            placeholder=''
            type='text'
            value={data?.phone}
            onChange={(e) => setData({ ...data, phone: e?.target?.value })}
          />
        </div>
      </div>

      <div className='user-email d-block '>
        <div className='d-flex '>
          <p className='title mt-2'>Address</p>
          <div className='w-100 address-row row g-0 mx-0 justify-content-end'>
            <div className='email address-col mr-0 w-100'>
              <CustomInput
                className='custom-input form-control'
                placeholder='Address 1 '
                value={data?.address1}
                onChange={(e) => setData({ ...data, address1: e?.target?.value })}
              />
            </div>
            <div className='email address-col mr-0 w-100'>
              <CustomInput
                className='custom-input form-control'
                placeholder='Address 2 '
                value={data?.address2}
                onChange={(e) => setData({ ...data, address2: e?.target?.value })}
              />
            </div>
            <div className='d-block address-col'>
              <CustomInput
                className='custom-input form-control'
                placeholder='City'
                value={data?.city}
                onChange={(e) => setData({ ...data, city: e?.target?.value })}
              />
            </div>
            <div className='d-block address-col'>
              <CustomInput
                className='custom-input form-control'
                placeholder='County'
                value={data?.county}
                onChange={(e) => setData({ ...data, county: e?.target?.value })}
              />
            </div>
            <div className='d-block address-col mr-0'>
              <CustomInput
                className='custom-input form-control'
                placeholder='Postcode'
                value={data?.postcode}
                onChange={(e) => setData({ ...data, postcode: e?.target?.value })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='user-email mt-4'>
        <div className='profile-title'>
          <div className='title'></div>
        </div>
        <button className='btn btn-dark' style={{ maxWidth: '170px', width: '100%' }}
          onClick={() => handleSaveClick()}>
          Save
        </button>
      </div>

    </div>
</>
    )
}

export default Profile;