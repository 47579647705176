import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import "../../components/style.css";

const DashboardChart = ({ type, data , mainColor, secondaryColor, isCancellation=false }) => {

  return (
    <div>
      <BarChart
        width={600}
        height={300}
        data={data}
        barGap={0}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={type === "Year" ? "month" : "day"} />
        <YAxis />
        <Tooltip />
        {secondaryColor ? (
          <Bar dataKey={isCancellation ? "cancellation":"secondaryData"} stackId="a" fill={secondaryColor} />
        ) : null}
        <Bar
          dataKey={isCancellation ? "order":"data"}
          fill={mainColor}
          radius={[8, 8, 0, 0]}
          stackId="a"
          className="chart-piles"
        />
      </BarChart>
    </div>
  );
};

export default DashboardChart;
