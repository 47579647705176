
import { Col, Container, Row } from 'react-bootstrap';
import LogoLight from '../../assets/img/logo-tangl-light.png';
import { ReactComponent as LinkedInIcon } from '../../assets/img/linkedIn.svg';
import { ReactComponent as FacebookIcon } from '../../assets/img/facebookIcon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/img/instagramIcon.svg';
import googleStore from '../../assets/img/mobile-google-play-badge.png';
import appStore from '../../assets/img/mobile-app-store-badge.png';
import { discoverData, partnersData } from '../../constants/menuItems';

const WebsiteFooter = () => {

    return <div className='footer-section'>
          <Container className=' '>
            <Row>
              <Col xs={6}>
                <div>
                  <img src={LogoLight} style={{width:'115px'}} alt='logo' />
                </div>
                <div>
                  <button className='mt-4 app-store-btn d-block'>
                    <img src={googleStore} style={{height:'45px'}} alt='google-store' className='btn-image' />
                  </button>
                  <button className='mt-4 app-store-btn d-block'>
                    <img src={appStore} style={{height:'45px'}} alt='play-store' className='btn-image' />
                  </button>
                </div>
                <div className='mt-4 '>
                  <LinkedInIcon className='mr-2' />
                  <FacebookIcon className='mr-2' />
                  <InstagramIcon />
                </div>
              </Col>
              <Col className='text-white footer-list' xs={2}>
                {discoverData?.map((item, index) => (
                  <a key={index} className='mb-1 d-block text-white fs-7' href='/'>
                    {item}
                  </a>
                ))}
              </Col>
              <Col className='text-white footer-list' xs={2}>
                {partnersData?.map((item, index) => (
                  <a key={index} className='mb-1 d-block text-white fs-7' href='/'>
                    {item}
                  </a>
                ))}
              </Col>
              <Col className='text-white footer-list' xs={2}>
                <a href="/company" className="mb-1 d-block text-white fs-7">Company</a>
                <a href="/about-us" className="mb-1 d-block text-white fs-7">About Us</a>
                <a href="/contact-us" className="mb-1 d-block text-white fs-7">Contact Us</a>
                <a href="/work-with-us" className="mb-1 d-block text-white fs-7">Work with Us</a>
                <a href="/privacy" className="mb-1 d-block text-white fs-7">Privacy Policy</a>
                <a href="/cookie-settings" className="mb-1 d-block text-white fs-7">Cookie Settings</a>
              </Col>
            </Row>
          </Container>
        </div>

}

export default WebsiteFooter;