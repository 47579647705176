import React from "react";
import "./FreelancerReports.css";
import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { makeRequest } from "../../../services/requests";
import { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { ReactComponent as BackArrow } from "../../../assets/icon/BackArrow.svg";
import Loading from "../../../components/Loading";
import ReactLoading from "react-loading";

const styles = {
  container: {
    borderRadius: "12px",
    border: "1px solid #322830",
    px: 3,
    py: 4,
    width: 244,
    "& h1": {
      color: "#212727",
      textAlign: "center",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "30px",
      mb: 0.5,
      fontFamily: "Inter",
    },
    "& p": {
      color: "#8F6Fc4",
      textAlign: "center",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "20px",
      fontFamily: "Inter",
    },
  },
  chartContainer: {
    borderRadius: "12px",
    border: "1px solid #322830",
    px: 2,
    py: 3,
    minHeight: 343,
    height: "100%",
    position: "relative",
  },
  title: {
    color: "#212727",
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "30px",
    flex: 1,
  },
  select: {
    borderRadius: "10px",
    height: 50,
    "&.Mui-selected": {
      bgcolor: "#322830 !important",
      backgroundColor: "#322830 !important",
    },
    "& .MuiSelect-select": {
      backgroundColor: "transparent",
      fontWeight: 400,
      opacity: 1,
      fontSize: 14,
      color: "#202020",
      borderRadius: 0,
      fontFamily: "Inter",
      borderBottom: "1px solid #000",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        // border: "1px solid #656DFC !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {},
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#656DFC",
      borderWidth: "1px !important",
    },
    "& span": {
      color: "#9c9c9c",
    },
    "& svg": {
      color: "unset !important",
    },
  },
  menuItem: {
    "&.Mui-selected": {
      bgcolor: "#322830 !important",
    },
    // py:0
  },
  forwardArrow: {
    transform: "scaleX(-1)",
    cursor: "pointer",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
};

const chart2Options = {
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        color: "#fcf7f2",
      },
      ticks: {
        // autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        color: "#fff",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          console.log(tooltipItem);
          return `€${tooltipItem.value}`;
        },
      },
    },
  },
};

const FreelancerReports = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [chart1Group, setChart1Group] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().add(1, "week").format("YYYY-MM-DD"),
    filter_by: null,
    group_by: "week",
  });
  const [chart1Select, setChart1Select] = useState("week");
  const [chart1Loading, setChart1Loading] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState();

  const [chart2Group, setChart2Group] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().add(1, "week").format("YYYY-MM-DD"),
    filter_by: null,
    group_by: "week",
  });
  const [chart2Select, setChart2Select] = useState("week");
  const [chart2Loading, setChart2Loading] = useState(false);
  const [totalCancellations, setTotalCancellations] = useState();

  const fetchData = async () => {
    setLoading(true);
    const resp = await makeRequest(
      "freelancer-report/summary",
      null,
      "GET",
      true
    );
    if (resp.result === "success") {
      setData(resp.data);
    }
    setLoading(false);
  };

  const monthFetcher = (month) => {
    switch (month) {
      case "January":
        month = "Jan";
        break;
      case "Feburary":
        month = "Feb";
        break;
      case "March":
        month = "Mar";
        break;
      case "April":
        month = "Apr";
        break;
      case "May":
        month = "May";
        break;
      case "June":
        month = "June";
        break;
      case "July":
        month = "July";
        break;
      case "August":
        month = "Aug";
        break;
      case "September":
        month = "Sep";
        break;
      case "October":
        month = "Oct";
        break;
      case "November":
        month = "Nov";
        break;
      case "December":
        month = "Dec";
        break;
      default:
        month = "";
        break;

    }
    return month;
  };

  const dayFetcher = (day) => {
    switch (day) {
      case "Monday":
        day = "Mon";
        break;
      case "Tuesday":
        day = "Tue";
        break;
      case "Wednesday":
        day = "Wed";
        break;
      case "Thursday":
        day = "Thur";
        break;
      case "Friday":
        day = "Fri";
        break;
      case "Saturday":
        day = "Sat";
        break;
      case "Sunday":
        day = "Sun";
        break;
      default:
        day = "";
        break;  
    }
    return day;
  };

  const fetchTotalEarnings = async (value) => {
    setChart1Loading(true);
    const mainUrl = "freelancer/total-earning";
    let url;

    if (chart1Group.group_by === "week") {
      url =
        mainUrl +
        `?group_by=${chart1Group.group_by}&start_date=${
          value ? value : chart1Group.start_date
        }`;
    } else {
      url =
        mainUrl +
        `?group_by=${chart1Group.group_by}&filter_by=${
          value ? value : chart1Group.filter_by
        }`;
    }

    const resp = await makeRequest(url, null, "GET", true);

    if (resp.result === "success") {
      if (chart1Group.group_by === "week") {
        const days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

        let labels = [];
        let earnings = [];

        days.forEach((day) => {
          const dayFound = resp.data.find((single) => {
            let newDay = single.day;
            return dayFetcher(newDay) === day;
          });

          if (dayFound) {
            labels.push(dayFetcher(dayFound.day));
            earnings.push(dayFound.total_earnings);
          } else {
            labels.push(day);
            earnings.push(0);
          }
        });

        setTotalEarnings({
          labels,
          earnings,
        });

        setChart1Group({
          ...chart1Group,
          start_date: moment(resp.start_date).format("YYYY-MM-DD"),
          end_date: moment(resp.end_date).format("YYYY-MM-DD"),
        });
      } else if (chart1Group.group_by === "month") {
        const days = moment()
          .month(value ? value - 1 : chart1Group.filter_by - 1)
          .daysInMonth();

        let daysInMonth = [];

        for (let i = 0; i < days; i++) {
          daysInMonth.push(i + 1);
        }

        let labels = [];
        let earnings = [];

        daysInMonth.forEach((day) => {
          const dayFound = resp.data.find((single) => single.date === day);

          if (dayFound) {
            labels.push(dayFound.date);
            earnings.push(dayFound.total_earnings);
          } else {
            labels.push(day);
            earnings.push(0);
          }
        });

        setTotalEarnings({
          labels,
          earnings,
        });
      } else if (chart1Group.group_by === "year") {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        let labels = [];
        let earnings = [];

        months.forEach((day) => {
          const dayFound = resp.data.find((single) => {
            let month = single.month;
            return monthFetcher(month) === day;
          });

          if (dayFound) {
            labels.push(monthFetcher(dayFound.month));
            earnings.push(dayFound.total_earnings);
          } else {
            labels.push(day);
            earnings.push(0);
          }
        });

        setTotalEarnings({
          labels,
          earnings,
        });
      }
    }
    setChart1Loading(false);
  };

  const fetchTotalCancellation = async (value) => {
    setChart2Loading(true);

    const mainUrl = "freelancer/total-cancellation";
    let url;

    if (chart2Group.group_by === "week") {
      url = `${mainUrl}?group_by=${chart2Group.group_by}&start_date=${
        value ? value : chart2Group.start_date
      }`;
    } else {
      url = `${mainUrl}?group_by=${chart2Group.group_by}&filter_by=${
        value ? value : chart2Group.filter_by
      }`;
    }

    const resp = await makeRequest(url, null, "GET", true);

    if (resp.result === "success") {
      if (chart2Group.group_by === "week") {
        const days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

        let labels = [];
        let cancellations = [];

        days.forEach((day) => {
          const dayFound = resp.data.find((single) => {
            let newDay = single.day;
            return dayFetcher(newDay) === day;
          });

          if (dayFound) {
            labels.push(dayFetcher(dayFound.day));
            cancellations.push(dayFound.total_cancellations);
          } else {
            labels.push(day);
            cancellations.push(0);
          }
        });

        setTotalCancellations({
          labels,
          cancellations,
        });

        setChart2Group({
          ...chart2Group,
          start_date: moment(resp.start_date).format("YYYY-MM-DD"),
          end_date: moment(resp.end_date).format("YYYY-MM-DD"),
        });
      } else if (chart2Group.group_by === "month") {
        const days = moment()
          .month(value ? value - 1 : chart2Group.filter_by - 1)
          .daysInMonth();
        let daysInMonth = [];

        for (let i = 0; i < days; i++) {
          daysInMonth.push(i + 1);
        }

        let labels = [];
        let cancellations = [];

        daysInMonth.forEach((day) => {
          const dayFound = resp.data.find((single) => single.date === day);

          if (dayFound) {
            labels.push(dayFound.date);
            cancellations.push(dayFound.total_cancellations);
          } else {
            labels.push(day);
            cancellations.push(0);
          }
        });

        setTotalCancellations({
          labels,
          cancellations,
        });
      } else if (chart2Group.group_by === "year") {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let labels = [];
        let cancellations = [];

        months.forEach((day) => {
          const dayFound = resp.data.find((single) => {
            let month = single.month;
            return monthFetcher(month) === day;
          });

          if (dayFound) {
            labels.push(monthFetcher(dayFound.month));
            cancellations.push(dayFound.total_cancellations);
          } else {
            labels.push(day);
            cancellations.push(0);
          }
        });

        setTotalCancellations({
          labels,
          cancellations,
        });
      }
    }

    setChart2Loading(false);
  };

  useEffect(() => {
    fetchData();
    fetchTotalEarnings();
    fetchTotalCancellation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTotalEarnings();
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [chart1Group.group_by]);

  useEffect(() => {
    fetchTotalCancellation();
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [chart2Group.group_by]);

  const chart1 = {
    labels: totalEarnings?.labels,
    tooltips: {
      enabled: false,
    },
    datasets: [
      {
        data: totalEarnings?.earnings,
        backgroundColor: "#fdb022",
        borderColor: "#fdb022",
        borderWidth: 1,
        borderRadius: 10,
        label: " Total Earnings",
        tooltip: {
          callbacks: {
            label: function (context) {
              return ` € ${context.formattedValue}`;
            },
          },
        },
      },
    ],
  };

  const chart2 = {
    labels: totalCancellations?.labels,
    tooltips: {
      enabled: false,
    },
    datasets: [
      {
        data: totalCancellations?.cancellations,
        backgroundColor: "#027a48",
        borderColor: "#027a48",
        borderWidth: 1,
        borderRadius: 10,
        label: "",
      },
    ],
  };

  const chart1Options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: "#fcf7f2",
        },
        ticks: {
          // autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#fff",
        },
        ticks: {
          callback: (value, index, values) => {
            // Add Euro sign to the tick labels
            return `€ ${value}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handlePrevWeek = () => {
    const start_date = moment(chart1Group.start_date)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD");
    fetchTotalEarnings(start_date);
  };

  const handleNextWeek = () => {
    const start_date = moment(chart1Group.start_date)
      .add(1, "weeks")
      .format("YYYY-MM-DD");
    fetchTotalEarnings(start_date);
  };

  const handlePrevMonth = () => {
    const month = moment()
      .month(chart1Group.filter_by - 2)
      .format("MM");
    setChart1Group({
      ...chart1Group,
      filter_by: month,
    });
    fetchTotalEarnings(month);
  };

  const handleNextMonth = () => {
    const month = moment().month(chart1Group.filter_by).format("MM");
    setChart1Group({
      ...chart1Group,
      filter_by: month,
    });
    fetchTotalEarnings(month);
  };

  const handlePrevYear = () => {
    const year = moment()
      .year(chart1Group.filter_by)
      .subtract(1, "year")
      .format("YYYY");
    setChart1Group({
      ...chart1Group,
      filter_by: year,
    });
    fetchTotalEarnings(year);
  };

  const handleNextYear = () => {
    const year = moment()
      .year(chart1Group.filter_by)
      .add(1, "year")
      .format("YYYY");
    setChart1Group({
      ...chart1Group,
      filter_by: year,
    });
    fetchTotalEarnings(year);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setChart1Select(value);
    setChart1Group({
      ...chart1Group,
      group_by: value,
      filter_by:
        value === "month"
          ? moment().format("MM")
          : value === "year"
          ? moment().format("YYYY")
          : null,
    });
  };

  const handlePrevWeek2 = () => {
    const start_date = moment(chart2Group.start_date)
      .subtract(1, "weeks")
      .format("YYYY-MM-DD");
    fetchTotalCancellation(start_date);
  };

  const handleNextWeek2 = () => {
    const start_date = moment(chart2Group.start_date)
      .add(1, "weeks")
      .format("YYYY-MM-DD");

    fetchTotalCancellation(start_date);
  };

  const handlePrevMonth2 = () => {
    const month = moment()
      .month(chart2Group.filter_by - 2)
      .format("MM");
    setChart2Group({
      ...chart2Group,
      filter_by: month,
    });
    fetchTotalCancellation(month);
  };

  const handleNextMonth2 = () => {
    const month = moment().month(chart2Group.filter_by).format("MM");
    setChart2Group({
      ...chart2Group,
      filter_by: month,
    });
    fetchTotalCancellation(month);
  };

  const handlePrevYear2 = () => {
    const year = moment()
      .year(chart2Group.filter_by)
      .subtract(1, "year")
      .format("YYYY");
    setChart2Group({
      ...chart2Group,
      filter_by: year,
    });
    fetchTotalCancellation(year);
  };

  const handleNextYear2 = () => {
    const year = moment()
      .year(chart2Group.filter_by)
      .add(1, "year")
      .format("YYYY");
    setChart2Group({
      ...chart2Group,
      filter_by: year,
    });
    fetchTotalCancellation(year);
  };

  const handleSelectChange2 = (e) => {
    const value = e.target.value;
    setChart2Select(value);
    setChart2Group({
      ...chart2Group,
      group_by: value,
      filter_by:
        value === "month"
          ? moment().format("MM")
          : value === "year"
          ? moment().format("YYYY")
          : null,
    });
  };

  if (loading && chart1Loading && chart2Loading) {
    return <Loading />;
  }

  return (
    <main id="main-section">
      <div className="container-fluid">
        <div id="blog-page">
          <div>
            <h2 className="titleHeading2">Reports</h2>
            <br />

            <Stack direction="row" gap={3} flexWrap="wrap">
              {Object.keys(data)?.map((item) => (
                <Box sx={styles.container} key={item}>
                  <Typography component="h1">
                    {item === "totalRevenue" ? "€ " + data[item] : data[item]}
                  </Typography>
                  <Typography component="p">
                    {item === "totalCustomers"
                      ? "Total Customers"
                      : item === "totalRevenue"
                      ? "Total Revenue"
                      : item === "totalOrders"
                      ? "Total Orders"
                      : item === "totalCanceledOrders"
                      ? "Total Cancellations"
                      : item === "totalServices" && "Total Services"}
                  </Typography>
                </Box>
              ))}
            </Stack>
            <br />

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Box sx={styles.chartContainer}>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    flexWrap="wrap"
                    mb={1}
                  >
                    <Typography component="h1" sx={styles.title}>
                      My Total Earnings
                    </Typography>

                    {chart1Group.group_by === "week" ? (
                      <Stack direction="row" gap={2} alignItems="center">
                        <BackArrow
                          style={{ cursor: "pointer" }}
                          onClick={handlePrevWeek}
                        />
                        <Typography sx={styles.dates}>
                          {moment(chart1Group.start_date).format("DD MMM")} -{" "}
                          {moment(chart1Group.end_date).format("DD MMM")}
                        </Typography>
                        <BackArrow
                          style={styles.forwardArrow}
                          onClick={handleNextWeek}
                        />
                      </Stack>
                    ) : chart1Group.group_by === "month" ? (
                      <Stack direction="row" gap={2} alignItems="center">
                        <BackArrow
                          style={{ cursor: "pointer" }}
                          onClick={handlePrevMonth}
                        />
                        <Typography sx={styles.dates}>
                          {moment()
                            .month(chart1Group.filter_by - 1)
                            .format("MMMM")}
                        </Typography>
                        <BackArrow
                          style={styles.forwardArrow}
                          onClick={handleNextMonth}
                        />
                      </Stack>
                    ) : (
                      chart1Group.group_by === "year" && (
                        <Stack direction="row" gap={2} alignItems="center">
                          <BackArrow
                            style={{ cursor: "pointer" }}
                            onClick={handlePrevYear}
                          />
                          <Typography sx={styles.dates}>
                            {moment()
                              .year(chart1Group.filter_by)
                              .format("YYYY")}
                          </Typography>
                          <BackArrow
                            style={styles.forwardArrow}
                            onClick={handleNextYear}
                          />
                        </Stack>
                      )
                    )}
                    <Select
                      sx={styles.select}
                      variant="outlined"
                      value={chart1Select}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="week" sx={styles.menuItem}>
                        Week
                      </MenuItem>
                      <MenuItem value="month" sx={styles.menuItem}>
                        Month
                      </MenuItem>
                      <MenuItem value="year" sx={styles.menuItem}>
                        Year
                      </MenuItem>
                    </Select>
                  </Stack>
                  {chart1Loading ? (
                    <Box sx={styles.loader}>
                      <ReactLoading type={"spin"} color={"#293056"} />
                    </Box>
                  ) : (
                    <Bar data={chart1} options={chart1Options} />
                  )}
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box sx={styles.chartContainer}>
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    flexWrap="wrap"
                    mb={1}
                  >
                    <Typography component="h1" sx={styles.title}>
                      Cancellations
                    </Typography>

                    {chart2Group.group_by === "week" ? (
                      <Stack direction="row" gap={2} alignItems="center">
                        <BackArrow
                          style={{ cursor: "pointer" }}
                          onClick={handlePrevWeek2}
                        />
                        <Typography sx={styles.dates}>
                          {moment(chart2Group.start_date).format("DD MMM")} -{" "}
                          {moment(chart2Group.end_date).format("DD MMM")}
                        </Typography>
                        <BackArrow
                          style={styles.forwardArrow}
                          onClick={handleNextWeek2}
                        />
                      </Stack>
                    ) : chart2Group.group_by === "month" ? (
                      <Stack direction="row" gap={2} alignItems="center">
                        <BackArrow
                          style={{ cursor: "pointer" }}
                          onClick={handlePrevMonth2}
                        />
                        <Typography sx={styles.dates}>
                          {moment()
                            .month(chart2Group.filter_by - 1)
                            .format("MMMM")}
                        </Typography>
                        <BackArrow
                          style={styles.forwardArrow}
                          onClick={handleNextMonth2}
                        />
                      </Stack>
                    ) : (
                      chart2Group.group_by === "year" && (
                        <Stack direction="row" gap={2} alignItems="center">
                          <BackArrow
                            style={{ cursor: "pointer" }}
                            onClick={handlePrevYear2}
                          />
                          <Typography sx={styles.dates}>
                            {moment()
                              .year(chart2Group.filter_by)
                              .format("YYYY")}
                          </Typography>
                          <BackArrow
                            style={styles.forwardArrow}
                            onClick={handleNextYear2}
                          />
                        </Stack>
                      )
                    )}

                    <Select
                      sx={styles.select}
                      variant="outlined"
                      value={chart2Select}
                      onChange={handleSelectChange2}
                    >
                      <MenuItem value="week" sx={styles.menuItem}>
                        Week
                      </MenuItem>
                      <MenuItem value="month" sx={styles.menuItem}>
                        Month
                      </MenuItem>
                      <MenuItem value="year" sx={styles.menuItem}>
                        Year
                      </MenuItem>
                    </Select>
                  </Stack>
                  {chart2Loading ? (
                    <Box sx={styles.loader}>
                      <ReactLoading type={"spin"} color={"#293056"} />
                    </Box>
                  ) : (
                    <Bar data={chart2} options={chart2Options} />
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FreelancerReports;
