
import Logo from '../../assets/img/logo.png'

const ProfilePhotoSmall = (properties) => {

    const API_URL = process.env.REACT_APP_IMAGE;

    return properties?.freelancer?.photo ?
        <img
          alt='profile-img'
          className='profile-img-small'
          src={`${API_URL}freelancers/${properties?.freelancer?.photo}`}
        /> : 
        <img
          alt='profile-img'
          className='profile-img-small'
          src={Logo}
        />
}

export default ProfilePhotoSmall;