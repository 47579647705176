import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import WebsiteHeader from '../../components/Website/Header';
import './LandingPage.css';
import bannerImg from '../../assets/img/bg-banner.png';
import bannerFrame from '../../assets/img/bg-frame.svg';
import CalenderImg from '../../assets/img/calender-img.png';
import Phone from '../../assets/img/phone.png';
import googleStore from '../../assets/img/mobile-google-play-badge.png';
import appStore from '../../assets/img/mobile-app-store-badge.png';
import { cards, GroomData } from '../../constants/menuItems';
import WebsiteFooter from '../../components/Website/Footer';

export const LandingPage = () => {
    
  const [chooseTreatment, setChooseTreatment] = useState(false);
  const [services, setServices] = useState(false);
  const handleServices = () => {
    setServices(true);
  };
  const handleChooseTreatment = () => {
    setChooseTreatment(true);
  };

  return (
    <>
      <WebsiteHeader />
      <div className='landing-main'>
        <Container fluid className='text-center px-0'>
          <Row className='gx-0 no-gutters position-relative'>
            <img src={bannerFrame} className='banner-frame d-lg-block d-none' alt='banner-fram' />
            <Col className='d-lg-block d-none'>
              <div className='bg-white'>
                <img src={bannerImg} className='banner-bg' alt='banner-img' />
              </div>
            </Col>
            <Col className='right-section '>
              <div className='main-right'>
                <div className='heading'>Tangl Beauty</div>
                <div style={{fontSize: 16, color: '#fff', marginTop: 30}}>
                  Your go-to beauty app for finding top-notch professionals to cater to all your beauty needs. 
                  <br /><br />
                  Whether you're looking for a skilled hairdresser, an expert nail technician, or any other beauty service,
                  Tangl connects you with experienced and trusted professionals in your area.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className='business-container ' id='Discover'>
          <div>
            <h1 className='text-center mb-5 fw-bolder'>Services</h1>
          </div>
          <div className='card-main'>
            <Row className=''>
              {cards?.slice(0, services ? cards?.length : 3).map((card) => (
                <div key={card?.id} className='col-lg-4 col-sm-6 col-12 mb-3'>
                  <div className='card'>
                    <div>
                      <img src={card?.image} alt='card-img' className='card-img' />
                    </div>
                    <div className='text-center'>
                      <div className='mt-3 card-title'>{card?.title}</div>
                      <div className='mt-2 mb-4 card-price'>From {card?.price}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </div>
          {!services && (
            <div className='d-flex justify-content-center'>
              <button className='show-all-btn  ' onClick={handleServices}>
                Show All
              </button>
            </div>
          )}
          <Row className='section-two ' id='partner'>
            <Col>
              <div className='section-text-content'>
                <div className='title-text'>Own a hair & beauty business?</div>
                <div className='title-text'>Bring it online</div>
                <div className='title-paragraph'>
                  We’ll help you build your business (and client base) with Groom
                </div>
                <button className='btn-landing'>Become a Partner</button>
              </div>
            </Col>
            <Col className='d-lg-block d-none'>
              <div className='text-right'>
                <img src={CalenderImg} alt='calender-img' width='668px'  />
              </div>
            </Col>
          </Row>
          <div>
            <h1 className='text-center mt-4 mb-5 fw-bolder'>Why choose Groom?</h1>
          </div>
          <div className='card-main'>
            <Row className=''>
              {GroomData?.slice(0, chooseTreatment ? GroomData?.length : 3)?.map((item) => (
                <div key={item?.id} className='col-lg-4 col-sm-6 col-12 mb-3'>
                  <div className='card'>
                    <div>
                      <img src={item?.image} alt='card-img' className='card-img' />
                    </div>
                    <div className='text-center'>
                      <div className='mt-3 card-title'>{item?.title}</div>
                      <div className='mt-2 mb-4 card-price'>{item?.description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Row>
          </div>
          {!chooseTreatment && (
            <div className='d-flex justify-content-center'>
              <button className='show-all-btn' onClick={handleChooseTreatment}>
                Show All
              </button>
            </div>
          )}          
          <Row className='section-two'>
            <Col>
              <div className='section-text-content'>
                <div className='title-text'>Download the app</div>
                <div className='title-paragraph'>
                  Book treatments and find the best salons near you with a quick swipe or two
                </div>
                <button className='show-all-btn mb-2'>Get it now</button>
                <div className='d-flex mt-5'>
                  <div className='mr-3'>
                    <button className='app-store-btn'>
                      <img src={googleStore} style={{height:'45px'}} alt='google-store' />
                    </button>
                  </div>
                  <div>
                    <button className='app-store-btn'>
                      <img src={appStore} style={{height:'45px'}} alt='play-store' />
                    </button>
                  </div>
                </div>
              </div>
              <div className='d-lg-block d-none mt-4'>
                <img src={Phone} alt='section-img mt-4' className='phone-img' />
              </div>
            </Col>
          </Row>
        </Container>

        <WebsiteFooter />
        
      </div>
    </>
  );
};
