import React from "react";
import Pagination from "./Pagination";
import Loading from "./Loading";

const Table = ({fields, data, meta, isLoading, setPage, sort, setSort}) => {
    if (isLoading) {
        return <Loading/>;
    }

    return <div className="table-responsive">
            <table className="table tbl_list_freelancers">
                <thead>
                <tr className="table-warning">
                    {fields.map((field, index) => {
                        if (field.label === '&nbsp;') {
                            return <th scope="col" key={index}
                                       className="thb_col tbl_col_sm tbl_last_item">&nbsp;</th>;
                        }
                        return <th key={index} scope="col" className="thb_col">{field.label}</th>
                    })}
                </tr>
                </thead>
                <tbody>
                {data?.map(row => {
                    return <tr key={row.id}>
                        {fields.map((field, index) => {
                            return field.th ?
                                <th key={`${row.id}${index}`} scope="row">{field.render(row)}</th> :
                                <td key={`${row.id}${index}`}
                                    className={index === fields.length - 1 ? 'tbl_last_item' : null}>{field.render(row)}</td>
                        })}
                    </tr>

                })}
                </tbody>
                <Pagination meta={meta} setPage={setPage}/>
            </table>
        </div>
}

export default Table;
