import {
    Navigate,
} from 'react-router-dom';
import {useSelector} from "react-redux";

const ProtectedFreelancerRoute = ({children}) => {
    const auth = useSelector((store) => store.authenticated);
    const role = useSelector((store) => store.role);
    if (!auth || role !== 'freelancer') {
        return <Navigate to="/login" replace/>;
    }
    return children;
};
export default ProtectedFreelancerRoute;
