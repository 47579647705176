import React, {useEffect, useState} from 'react';
import ModalForm from "../../../components/ModalForm";
import {
    createTreatmentCategory,
    deleteTreatmentCategory,
    getTreatmentCategories,
    updateTreatmentCategory
} from "../../../services/requests";
import Loading from "../../../components/Loading";
import CategoryForm from "../../../components/CategoryForm";
import ModalWindow from "../../../components/ModalWindow";
import {Link} from "react-router-dom";
import { ReactComponent as IconDelete } from '../../../assets/icon/IconDelete.svg';
import { ReactComponent as IconEdit } from '../../../assets/icon/IconEdit.svg';
import { ReactComponent as IconView } from '../../../assets/icon/IconView.svg';

const AdminTreatmentCategories = () => {
    const [addCategory, setAddCategory] = useState(false);
    const [editCategory, setEditCategory] = useState(false);
    const [treatmentCategoryData, setTreatmentCategoryData] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [fileMain, setFileMain] = useState(); 
    const loadData = async () => {
        if (!isLoading) {
            setLoading(true);
            const resp = await getTreatmentCategories();
            setData(resp?.data);
            setLoading(false);
        }
    }
    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleImageChange = (event) => {
        setFileMain(event?.target?.files[0])
      };

    return <main id="main-section">
        <div className="container-fluid">
            <div className="row header-section">
                <div className="col-sm-8">
                    <h2 className="titleHeading2">Treatment Categories</h2>
                </div>
                <div className="col-sm-4 add_freelancer_wrap">
                    
                    <button type="button" className="btn btn-primary" data-toggle="modal"
                            data-target="#addCategoryModal" onClick={() => {
                        setAddCategory(true)
                    }}>Add Category
                    </button>
                    <ModalForm show={addCategory} title={'Add Category'}
                               body={
                                   <CategoryForm 
                                        showTreatmentGroups={'false'}
                                        hideAction={
                                       async (data) => {
                                        var body = { name: data?.name };
                                        if (fileMain) {
                                            body = {...body, image: fileMain}
                                        }
                                        try {
                                            await createTreatmentCategory(
                                                {...body }                                            
                                            );
                                            await loadData();
                                            setAddCategory(false);
                                        } catch (error) { }                                           
                                       }
                                   }/>
                               }
                               saveText={'Add'}
                               handleClose={() => {
                                   setAddCategory(false)
                               }}
                    />
                </div>
            </div>
            {isLoading ? <Loading/> : <div className="freelancers_info">

                <div className="treatment_categories">
                    <div className="table-responsive">
                        <table className="table tbl_treatment_categories">
                            <thead>
                                <tr className="table-warning">
                                    <th key="0" scope="col" className="thb_col">Category Name</th>
                                    <th key="1" scope="col" className="thb_col">&nbsp;</th>
                                </tr>                                
                            </thead>
                            <tbody>
                            {data?.map((treatmentCategory, index) => {
                                return <tr tabIndex="0" key={treatmentCategory?.id}>
                                    <th scope="row" className="tableRowText">
                                        {treatmentCategory?.name}
                                    </th>
                                    <td className="tbl_last_item">
                                    
                                    <Link to={'/admin/treatments/' + treatmentCategory?.id}>
              <button
                type='button'
                className='btn btn-link'
              >
                <IconView />
              </button>
              </Link>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setTreatmentCategoryData(treatmentCategory);
                    setEditCategory(true);
                }}
              >
                <IconEdit />
              </button>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setTreatmentCategoryData(treatmentCategory);
                    setDeleteModal(true);
                }}
              >
                <IconDelete />
              </button>            
                                    </td>
                                </tr>
                            })
                            }
                            <ModalForm  show={editCategory} 
                                        title={'Edit Category'}                                        
                                        body={
                                                                                                                  
                                       <CategoryForm    saveText={'Save'}
                                                        handleFileChange={handleImageChange}
                                                        showTreatmentGroups={'false'}
                                                        id={treatmentCategoryData?.id} hideAction={
                                                        async (data) => {

                                            var body = {name: data?.name };
                                            if (fileMain) {
                                                body = {...body, image: fileMain}
                                            }
                                            try {
                                                await updateTreatmentCategory(
                                                    treatmentCategoryData?.id, {...body, _method: 'PUT' }
                                                );
                                                await loadData();
                                                setEditCategory(false);                                                
                                            } catch (error) { }
                                           }

                                       }/>}
                                       handleClose={() => {
                                           setEditCategory(false)
                                       }}
                            />
                            <ModalWindow show={deleteModal}
                                         title={`Delete Category`}
                                         body={'Are you sure you want to delete \'' + treatmentCategoryData?.name + '\'?'}
                                         hideAction={async () => {
                                             await deleteTreatmentCategory(treatmentCategoryData?.id);
                                             await loadData();
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteModal(false)
                                         }}
                            />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}
        </div>
    </main>
}

export default AdminTreatmentCategories;
