import React, { useEffect, useState } from 'react';
import WebsiteHeader from '../../../components/Website/Header';
import WebsiteFooter from '../../../components/Website/Footer';
import { Link, useParams } from 'react-router-dom';
import Bookings from './Bookings/Bookings';
import Profile from './Profile/Profile';
import Password from './Password/Password';
import Payment from './Payment/Payment';

export const CustomerDashboard = () => {

  const [activeTab, setActiveTab] = useState('profile');
  const { categories } = useParams();

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    localStorage?.setItem('selectedTab', tab);
  };
  useEffect(() => {
    if (categories === 'profile') {
      setActiveTab('profile');
    } else if (categories === 'bookings') {
      setActiveTab('bookings');
    } else if (categories === 'password') {
      setActiveTab('password');
    } else if (categories === 'payment') {
      setActiveTab('payment');
    }
  }, [categories]);

  return (
    <>
      <WebsiteHeader />
    
    
      <main id='main-section'>

      <div className='container-fluid'>

        <div id='profile-page'>
          <div>
            <h2 className='titleHeading2'>My Account</h2>
          </div>
          <div className='subtitle' id='justify-tab-example'>
            <div className='nav nav-tabs'>

              <div className='nav-item'>
                <Link
                  className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('profile')}
                >
                  Profile
                </Link>
              </div>

              <div className='nav-item'>
                <Link
                  className={`nav-link ${activeTab === 'bookings' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('bookings')}
                >
                  Bookings
                </Link>
              </div>

{/*
              <div className='nav-item'>
                <Link
                  className={`nav-link ${activeTab === 'payment' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('payment')}
                >
                  Payment Methods
                </Link>
              </div>
  */}

              <div className='nav-item'>
                <Link
                  className={`nav-link ${activeTab === 'password' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('password')}
                >
                  Password
                </Link>
              </div>


            </div>
          </div>

          {activeTab === 'profile' && <Profile />}
          {activeTab === 'bookings' && <Bookings />}
          {activeTab === 'password' && <Password />}
          {activeTab === 'payment' && <Payment />}
          
        </div>
      </div>
    </main>
  
    <WebsiteFooter />

    </>
  );
};
