import React, { useState } from 'react';
import './AdminCreatePassword.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as EyeSlash } from '../../../assets/icon/EyeSlash.svg';
import { makeRequest } from '../../../services/requests';
import WebsiteHeader from '../../../components/Website/Header';
import { LOGIN_USER } from '../../../types';
import { useDispatch } from 'react-redux';

const AdminCreatePassword = () => {
  const { id } = useParams();
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputsData, setInput] = useState({ password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const inputChange = (e) => {
    const { name, value } = e.target;
    setInput((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    if (name === 'password') {
      const hasUppercase = /[A-Z]/.test(value);
      const isLengthValid = value.length >= 8;
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          value.trim() === ''
            ? 'Password is required'
            : !hasUppercase
            ? 'Password must contain at least 1 uppercase letter'
            : !isLengthValid
            ? 'Password must be at least 8 characters long'
            : '',
      }));
    } else if (name === 'confirmPassword') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          value.trim() === ''
            ? 'Confirm password is required'
            : value !== inputsData.password
            ? 'Passwords do not match.'
            : '',
      }));
    }
  };

  const login = async (event) => {
    event.preventDefault();
    if (!isLoading) {
      if (inputsData.password.trim() === '' || inputsData.confirmPassword.trim() === '') {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: inputsData.password.trim() === '' ? 'Password is required' : prevErrors.password,
          confirmPassword:
            inputsData.confirmPassword.trim() === ''
              ? 'Confirm password is required'
              : prevErrors.confirmPassword,
        }));
        return;
      }
      setIsLoading(true);
      const body = {
        secret: id,
        password: inputsData.password,
        password_confirmation: inputsData.confirmPassword,
      };
      const resp = await makeRequest('auth/set-password ', body, 'POST', false);
      if (resp.token) {
        dispatch({
          type: LOGIN_USER,
          payload: {
            token: resp.token,
            role: resp?.permissions[0],
            email: resp.user.email,
            name: resp.user.name,
          },
        });
        navigation('/prouser/settings');
      }
      if (resp.result === 'error') {
        setErrorMessage(resp)
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <WebsiteHeader />
      <section id='login-section' className='vh-100'>
        <div className='line line-1'></div>
        <div className='line line-2'></div>
        <div className='line line-3'></div>
        <div className='line line-4'></div>
        <div className='custom-container'>
          <div className='row h-100'>
            <div className='col-lg-5 col-12 offset-lg-1 d-flex align-items-center'>
              <div className='password-form d-flex flex-column m-lg-0 m-auto'>
                <span className='password-title'>Welcome to Tangl.</span>
                <span className='password-subtitle'>You've just been added to Tangl as a Pro User. Please enter a new password below to setup your account.</span>
                {
                 errorMessage ? (
                  <div className="alert alert-danger">{errorMessage?.messages}</div>
                ) : null
                }
                <form onSubmit={login}>
                  <div className='form-group field_password'>
                    <label htmlFor='inputPassword'>Password</label>
                    <input
                      onChange={inputChange}
                      name='password'
                      type={'password'}
                      className='form-control'
                      id='inputPassword'
                      aria-describedby='passwordHelp'
                      placeholder=''
                    />
                    <div>{errors.password && <span className='error'>{errors.password}</span>} </div>
                  </div>
                  <div className='form-group field_password'>
                    <label htmlFor='inputPassword'>Confirm Password</label>
                    <div style={{ position: 'relative' }}>
                      <input
                        onChange={inputChange}
                        name='confirmPassword'
                        type={showPassword ? 'text' : 'password'}
                        className='form-control'
                        id='inputConfirmPassword'
                        placeholder=''
                      />
                      <button
                        disabled={isLoading}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        type='button'
                        className={`btn btnShowHide ${showPassword ? 'show' : ''}`}
                      >
                        <EyeSlash />
                      </button>
                    </div>
                    <div>
                      {errors.confirmPassword && <span className='error'>{errors.confirmPassword}</span>}{' '}
                    </div>
                  </div>
                  <button type='submit' className='btn password-btn'>
                    Continue
                  </button>
                </form>
              </div>
            </div>
            <div className='col-5 offset-1 d-lg-block d-none'>
              <div id='admin-password-bg'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminCreatePassword;
