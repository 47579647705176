import React, {useEffect, useState} from 'react';
import "./AdminTreatmentCategoryDetailed.css";
import {Link, useParams} from "react-router-dom";
import {
    createTreatment,
    createTreatmentGroup, deleteTreatment, deleteTreatmentAddon, deleteTreatmentGroup,
    getTreatmentCategory, updateTreatment, updateTreatmentAddon, createTreatmentAddon,
    updateTreatmentCategory, updateTreatmentGroup
} from "../../../services/requests";
import Loading from "../../../components/Loading";
import CategoryForm from "../../../components/CategoryForm";
import ModalForm from "../../../components/ModalForm";
import GroupForm from "../../../components/GroupForm";
import TreatmentForm from "../../../components/TreatmentForm";
import AddonForm from "../../../components/AddonForm";
import {ReactComponent as BackArrow} from '../../../assets/icon/BackArrow.svg'
import { capitalize } from '../../../services/utils';
import {ReactComponent as IconDelete} from '../../../assets/icon/IconDelete.svg'
import {ReactComponent as IconEdit} from '../../../assets/icon/IconEdit.svg'
import ModalWindow from "../../../components/ModalWindow";

const AdminTreatmentCategoryDetailed = () => {
    const [changeCategoryName, setChangeCategoryName] = useState(false);
    const [addGroup, setAddGroup] = useState(false);
    const [changeGroup, setChangeGroup] = useState(null);
    const [newTreatment, setNewTreatment] = useState(null);
    const [changeTreatment, setChangeTreatment] = useState(null);
    const [changeTreatmentAddon, setChangeTreatmentAddon] = useState(null);
    const [newAddon, setNewAddon] = useState(null);
    const [treatmentCategory, setTreatmentCategory] = useState({treatment_groups: []});
    const [isLoading] = useState(false)
    const [deleteGroupModal, setDeleteGroupModal] = useState(false);
    const [deleteTreatmentModal, setDeleteTreatmentModal] = useState(false);
    const [deleteAddonModal, setDeleteAddonModal] = useState(false);
    const [groupData, setGroupData] = useState(null);
    const [treatmentData, setTreatmentData] = useState(null);
    const [addonData, setAddonData] = useState(null);
    let {categoryId} = useParams();

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadData = async () => {
        getTreatmentCategory(categoryId, true).then(res => {
            setTreatmentCategory(res);
        })
    }

    const editCategoryName = async (data) => {
        await updateTreatmentCategory(data.id, {name: data.name}).then(() => {
            loadData();
        })
    }

    const addCategoryGroup = async (data) => {
        await createTreatmentGroup({...data, treatment_category_id: treatmentCategory.id}).then(() => {
            loadData();
        })
    }

    const editCategoryGroup = async (data) => {
        await updateTreatmentGroup(data.id, {
            name: data.name,
            description: data.description,
            treatment_category_id: treatmentCategory.id
        }).then(() => {
            loadData();
        })
    }

    const removeCategoryGroup = async (id) => {
        await deleteTreatmentGroup(id).then(() => {
            loadData();
        })
    }

    const addTreatment = async (data) => {
        await createTreatment({...data, treatment_group_id: newTreatment.id}).then(() => {
            loadData();
        })
    }

    const editTreatment = async (data) => {
        await updateTreatment(data.id, {
            name: data.name,
            description: data.description,
            preparation: data.preparation,
            addons: data.treatment_addons,
            treatment_id: changeTreatment.id
        }).then(() => {
            loadData();
        })
    }

    const removeTreatment = async (id) => {
        await deleteTreatment(id).then(() => {
            loadData();
        })
    }

    const editAddon = async (data) => {
        await updateTreatmentAddon(data.id, {
            name: data.name
        }).then(() => {
            loadData();
        })
    }

    const addAddon = async (data) => {
        await createTreatmentAddon({
            treatment_id: data.treatment_id,
            name: data.name
        }).then(() => {
            loadData();
        })
    }

    const removeAddon = async (id) => {
        await deleteTreatmentAddon(id).then(() => {
            loadData();
        })
    }

    if (isLoading) {
        return <Loading/>;
    }

    return <main id="main-section">
        <div className="container-fluid">
            <div className="row header-section">
                <div className="col-sm-8">
                    <h2 className="titleHeading2">
                        <Link className="backArrow" to={'/admin/treatments'}><BackArrow/></Link>
                        {capitalize(treatmentCategory.name)}
                        <ModalForm show={changeCategoryName} title={'Edit Treatment Category Name'}
                                   body={
                                       <CategoryForm hideAction={
                                           async (data) => {
                                               await editCategoryName(data);
                                               setChangeCategoryName(false);
                                           }
                                       } saveText={'Edit'} id={treatmentCategory.id}/>
                                   }
                                   handleClose={() => {
                                       setChangeCategoryName(false)
                                   }}
                        />
                    </h2>
                </div>
                <div className="col-sm-4 add_freelancer_wrap">
                    <button type="button" className="btn btnPrimaryIcon" data-toggle="modal"
                            data-target="#addGroup"
                            onClick={() => {
                                setAddGroup(true)
                            }}>
                        Add Group
                    </button>
                    <ModalForm show={addGroup} title={'Add Treatment Group'}
                               body={
                                   <GroupForm hideAction={
                                       async (data) => {
                                           await addCategoryGroup(data);
                                           setAddGroup(false);
                                       }
                                   } saveText={'Add'}/>
                               }
                               handleClose={() => {
                                   setAddGroup(false)
                               }}
                    ></ModalForm>
                </div>
            </div>

            <div className="treatment_group">

                {
                    treatmentCategory?.treatment_groups?.length ? treatmentCategory?.treatment_groups?.map(group => {
                        return (
                            <div key={group.id} className="row groupElement">
                                <div className="col-lg-5 groupInfo">
                                    <h3> {capitalize(group.name)}
                                        <div className="groupInfoCTA" >
                                            <IconEdit className='mr-2' onClick={() => {
                                                        setChangeGroup(group)
                                                    }}/>                                            
                                            <IconDelete onClick={() => {
                                                setGroupData(group);
                                                setDeleteGroupModal(true);
                                                }}/>
                                        </div>

                                        <ModalWindow show={deleteGroupModal}
                                         title={`Delete Treatment Group`}
                                         body={'Are you sure you want to delete \'' + groupData?.name + '\'?'}
                                         hideAction={async () => {
                                             await removeCategoryGroup(groupData?.id)
                                             await loadData();
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteGroupModal(false)
                                         }}
                                        />

                                    </h3>
                                    <p>{capitalize(group.description)}</p>
                                </div>
                                <div className="col-lg-7 p-3">
                                    <div className="add_treatment_wrap w-100  justify-content-end d-flex align-items-center">
                                        {group?.treatments?.length ? <></> : <span className="mr-auto"><i>No treatments in this group yet.</i></span>}
                                        <button type="button" data-toggle="modal"
                                                data-target="#newTreatment"
                                                className="btnAddTreatment"
                                                onClick={() => {
                                                    setNewTreatment(group)
                                                }}>
                                            Add Treatment
                                        </button>
                                    </div>
                                    <div className="accordion treatment-accordion" id="accordionClassic">
                                        {
                                            group?.treatments?.map((treatment, idx) => {
                                                return (
                                                    
                                                    <div key={treatment?.id} className="card">
                                                        <div className="card-header">
                                                            <div className="card-header-text">
                                                                <h5 className="treatmentName">{treatment?.name}</h5>
                                                                <p style={{paddingBottom: 10}}>{treatment?.description}</p>
                                                            
                                                                
                                                                {treatment?.preparation ?
                                                                <>
                                                                    <p><b>Preparation:</b></p>
                                                                    <p><i>{treatment?.preparation}</i></p>
                                                                </>
                                                                :''}
                                                            </div>
                                                            <div className="card-header-cta">                                                                
                                                                <div className="ch_cta">
                                                                            <IconEdit className='mr-2' onClick={() => {
                                                                                setChangeTreatment(treatment)
                                                                            }}/>
                                                                            <IconDelete onClick={() => {
                                                                                setTreatmentData(treatment);
                                                                                setDeleteTreatmentModal(true);
                                                                            }}/>

                                        <ModalWindow show={deleteTreatmentModal}
                                         title={`Delete Treatment`}
                                         body={'Are you sure you want to delete \'' + treatmentData?.name + '\'?'}
                                         hideAction={async () => {
                                             await removeTreatment(treatmentData?.id)
                                             await loadData();
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteTreatmentModal(false)
                                         }}
                                        />                                                                            
                                                                            
                                                                    {
                                                                            <button className={`btn btn-link btn-switch ${idx && 'collapsed'}`}
                                                                                    type="button"
                                                                                    data-toggle="collapse"
                                                                                    data-target={'#collapse-' + treatment?.id}
                                                                                    aria-expanded="true"
                                                                                    aria-controls={'collapse-' + treatment?.id}>
                                                                                &nbsp;
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                <div id={'collapse-' + treatment?.id}
                                                     className={
                                                         !idx ? "collapse show" : "collapse"
                                                     }
                                                     aria-labelledby="headingOne"
                                                     data-parent="#accordionClassic">
                                                    <div className="card-body">
                                                   
                                                    <button type="button" data-toggle="modal"
                                                        data-target="#newAddon"
                                                        className="btnAddAddon"
                                                        onClick={() => {
                                                            setTreatmentData(treatment)
                                                            setNewAddon(true)
                                                        }}>
                                                        Add Add-On
                                                    </button>

                                                        {treatment?.treatment_addons.length > 0 ?
                                                        <h4 className="addOnsTitle">Add-Ons</h4>
                                                        : null}

                                                        <ul>                                                            
                                                            {treatment?.treatment_addons?.map(addon => {
                                                                return (<li key={addon?.id}>
                                                                    {addon.name}
                                                                    <div className="ch_cta addons">
                                                                        <IconEdit className='mr-2' onClick={() => {
                                                                                    setChangeTreatmentAddon(addon)
                                                                                }}/>                                                                        
                                                                        <IconDelete onClick={() => {
                                                                                    setAddonData(addon);
                                                                                    setDeleteAddonModal(true);
                                                                                }}/>                                                                    
                                                                    </div>
                                                                </li>)
                                                            })}
                                                        </ul>
                                                        <ModalWindow show={deleteAddonModal}
                                         title={`Delete Treatment Add-On`}
                                         body={'Are you sure you want to delete \'' + addonData?.name + '\'?'}
                                         hideAction={async () => {
                                             await removeAddon(addonData?.id)
                                             await loadData();
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteAddonModal(false)
                                         }}
                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                }) : <div className="d-flex w-100">
                    <span className="mr-auto"><i>No groups in this treatment category yet.</i></span>
                </div>}
            </div>
        </div>

        <ModalForm show={changeGroup} title={'Edit Treatment Group'}
                   body={
                       <GroupForm hideAction={
                           async (data) => {
                               await editCategoryGroup(data);
                               setChangeGroup(null);
                           }
                       } saveText={'Save'} id={changeGroup?.id}/>
                   }
                   handleClose={() => {
                       setChangeGroup(null)
                   }}
        ></ModalForm>
        <ModalForm show={newTreatment} title={'Add Treatment'}
                   body={
                       <TreatmentForm hideAction={
                           async (data) => {
                               await addTreatment(data);
                               setNewTreatment(null);
                           }
                       } saveText={'Add'}/>
                   }
                   handleClose={() => { 
                       setNewTreatment(null)
                   }}
        ></ModalForm>
        <ModalForm show={changeTreatment} title={'Edit Treatment'}
                   body={
                       <TreatmentForm hideAction={
                           async (data) => {
                               await editTreatment(data);
                               setChangeTreatment(null);
                           }
                       } saveText={'Save'} id={changeTreatment?.id}/>
                   }
                   handleClose={() => {
                       setChangeTreatment(null)
                   }}
        ></ModalForm>
        <ModalForm show={changeTreatmentAddon} title={'Edit Add-On'}
                   body={
                       <AddonForm hideAction={
                           async (data) => {
                               await editAddon(data);
                               setChangeTreatmentAddon(null);
                           }
                       }    saveText={'Save'} 
                            id={changeTreatmentAddon?.id}
                            />
                   }
                   handleClose={() => {
                       setChangeTreatmentAddon(null)
                   }}
        ></ModalForm>

        <ModalForm show={newAddon} title={'Add Treatment Add-On'}
                   body={

                       <AddonForm hideAction={
                           async (data) => {
                               await addAddon(data);
                               setNewAddon(null);
                           }
                       }    saveText={'Save'} 
                            treatmentId={treatmentData?.id}/>
                   }
                   handleClose={() => {
                       setNewAddon(null)
                   }}
        ></ModalForm>        
    </main>
}
export default AdminTreatmentCategoryDetailed;
