import React, { useState } from "react";
import styles from "./ReportsTable.module.css";

const ReportsTable = ({ type, data }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const revenueTableHeader = (
    <tr>
      <th className={styles.thLeft}>Freelancer name</th>
      <th className={styles.th}>Category</th>
      <th className={styles.th}>Treatment</th>
      <th className={styles.th}>All orders</th>
      <th className={styles.thRight}>Total revenue</th>
    </tr>
  );

  const cancellationsTableHeader = (
    <tr>
      <th className={styles.thLeft}>Freelancer name</th>
      <th className={styles.th}>Service</th>
      <th className={styles.th}>All orders</th>
      <th className={styles.th}>Treatment</th>
      <th className={styles.thRight}>Cancellations</th>
    </tr>
  );

  const locationsTableHeader = (
    <tr>
      <th className={styles.thLeft}>Locations</th>
      <th className={styles.th}>Popular Services</th>
      <th className={styles.th}>All orders</th>
      <th className={styles.th}>Freelancers</th>
    </tr>
  );

  const customersTableHeader = (
    <tr>
      <th className={styles.thLeft}>Name</th>
    </tr>
  );

  const imagesList = (data) => {
    if (data && data.length > 0)
      return (
        <div className={styles.profile_scroller}>
          {data.slice(0, 7).map((profile) => (
            <div key={profile.user_id} className={styles.profile}>
              <div className={styles.profile_image}></div>
              {/* <img
                src={profile.photo}
                alt=""
                className={styles.profile_image}
              /> */}
            </div>
          ))}
          {data.length - 7 > 0 && (
            <div className={`${styles.profile} ${styles.profile_more_marker}`}>
              <div className={styles.profile_more}>+{data.length - 7}</div>
            </div>
          )}
        </div>
      );
  };

  const locations = (location, area) => (
    <div className={styles.locationBlock}>
      <p className={styles.locationText}>{location}</p>
      <p className={styles.areaText}>{area}</p>
    </div>
  );

  const category = (data) => {
    const categoryArray = data?.split(", ");
    if (categoryArray) return categoryArray.slice(0, 2).join(", ");
  };

  const services = (data) => {
    if (data)
      if (data?.length > 0) return data.slice(0, 2).join(", ");
      else return "-";
  };

  const treatment = (data) => {
    const treatmentsArray = data?.split(", ");

    if (treatmentsArray)
      return (
        <div className={styles.treatmentBlock}>
          {treatmentsArray.slice(0, 2).map((e, index) => (
            <p key={index} className={styles.pickedTreatment}>
              {e}
            </p>
          ))}
          {treatmentsArray.length - 2 > 0 && (
            <div className={styles.pickedTreatment}>
              + {treatmentsArray.length - 2}
            </div>
          )}
        </div>
      );
  };

  const revenueTable = currentItems.map((item,index) => (
    <tr key={index}>
      {/* {freelancerWithPhoto(item.freelancer)} */}
      <td className={styles.tdFirst}>{item.freelancer}</td>
      <td className={styles.td}>{category(item.category)}</td>
      <td className={styles.td}>{treatment(item.treatmentName)}</td>
      <td className={styles.td}>{item.totalOrders}</td>
      <td className={styles.tdTotal}> € {item.totalRevenue}</td>
    </tr>
  ));

  const locationsTable = currentItems.map((item,index) => (
    <tr key={index}>
      <td className={styles.tdFirst}>
        {locations(item.locationName, item.locationName)}
      </td>
      <td className={styles.td}>{services(item.CategoryName)}</td>
      <td className={styles.td}>{item.TreatmentCount}</td>
      <td className={styles.td}>{imagesList(item.freelancers)}</td>
    </tr>
  ));

  const cancellationsTable = currentItems.map((item,index) => (
    <tr key={index}>
      {/* {freelancerWithPhoto(item.freelancer)} */}
      <td className={styles.tdFirst}>{item.freelancer}</td>
      <td className={styles.td}>{category(item.service)}</td>
      <td className={styles.td}>{item.totalOrders}</td>
      <td className={styles.td}>{treatment(item.treatmentName)}</td>
      <td className={styles.tdTotal}>{item.cancellations}</td>
    </tr>
  ));

  const customersTable = currentItems.map((item) => (
    <tr key={item.id}>
      <td style={{ padding: "24px 28px" }} className={styles.tdFirst}>
        {item.customer_name}
      </td>
    </tr>
  ));

  let header = revenueTableHeader;
  let tableBody = revenueTable;

  if (type === "revenue") {
    header = revenueTableHeader;
    tableBody = revenueTable;
  } else if (type === "cancellation") {
    header = cancellationsTableHeader;
    tableBody = cancellationsTable;
  } else if (type === "customer") {
    header = customersTableHeader;
    tableBody = customersTable;
  } else if (type === "location") {
    header = locationsTableHeader;
    tableBody = locationsTable;
  } else {
    header = revenueTableHeader;
    tableBody = revenueTable;
  }

  return (
    <div>
      <table className={styles.table}>
        <thead>{header}</thead>
        <tbody>{tableBody}</tbody>
      </table>
      <div className={styles.paginationBlock}>
        <button className={styles.btnControl}>
          <i className={styles.purpleText}>{`<-`}</i>
          {` Previous`}
        </button>
        <ul className={styles.pagination}>
          {Array.from({ length: totalPages }, (_, index) => (
            <li
              key={index}
              className={
                index + 1 === currentPage
                  ? `${styles.pageItem} ${styles.selectedPage}`
                  : styles.pageItem
              }
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </li>
          ))}
        </ul>
        <button className={styles.btnControl}>{`Show all ->`}</button>
      </div>
    </div>
  );
};

export default ReportsTable;
