import React, { useEffect, useState, Fragment } from 'react';
import './AdminNews.css';
import { createAdminNews, deleteAdminNews, getAdminNews, updateAdminNews } from '../../../services/requests';
import Table from '../../../components/Table';
import { Link } from 'react-router-dom';
import { ReactComponent as IconDelete } from '../../../assets/icon/IconDelete.svg';
import { ReactComponent as IconEdit } from '../../../assets/icon/IconEdit.svg';
import ModalForm from "../../../components/ModalForm";
import NewsForm from "../../../components/NewsForm";
import ModalWindow from "../../../components/ModalWindow";

import { formatDate } from '../../../services/utils';

const AdminNews = () => {
  const [editNews, setEditNews] = useState(false);
  const [addNews, setAddNews] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: 'id', sort: 'asc' });
  const [meta, setMeta] = useState(null);
  const [newsData, setNewsData] = useState(null);
  const [deleteNewsModal, setDeleteNewsModal] = useState(false);

  const fields = [
    {
      label: 'Title',
      th: false,
      sort: 'title',
      render: (row) => {
        return (
          <Fragment>
            {row?.title}
          </Fragment>
        );
      },
    },
    {
      label: 'Date',
      th: false,
      sort: 'email',
      render: (row) => {
        return <Fragment>{formatDate(row?.updated_at)}</Fragment>;
      },
    },
    {
      label: 'Status',
      sort: 'published',
      render: (row) => {
        if (row?.is_published) {
          return (
            <Link
              className='status_active'
            >
              Published
            </Link>
          );
        } else {
          return (
            <Link
              className='status_disable'
            >
              Hidden
            </Link>
          );
        }
      },
    },
    {
      label: '&nbsp;',
      render: (row) => {
        return (
          <Fragment>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                  setNewsData(row);
                  setEditNews(true);
                }}
              >
                <IconEdit />
              </button>
              <button
                type='button'
                className='btn btn-link'
                onClick={() => {
                    setNewsData(row);
                    setDeleteNewsModal(true);
                }}         
              >
                <IconDelete />
              </button>
          </Fragment>
        );
      },
    },
  ];  

  const loadData = async () => {
    if (!isLoading) {
      setLoading(true);
      const resp = await getAdminNews(page);
      setData(resp?.data);
      setMeta(resp.meta.pagination);
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (isLoading) {
    return (
      <div id='spinnerDiv'>
        <div className='spinner-border' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    );
  }
  return (
    
    <main id='main-section'>
      <div className='container-fluid'>
        <div className="row header-section">
          <div className="col-sm-8">
            <h2 className="titleHeading2">News</h2>
          </div>
          <div className="col-sm-4 add_freelancer_wrap">
            <button type="button" className="btn btn-primary" 
              onClick={() => { setAddNews(true) }}>Add News</button>
          </div>
        </div>

        <div className='freelancers_info'>
          <Table
            fields={fields}
            data={data}
            isLoading={isLoading}
            setPage={setPage}
            meta={meta}
            sort={sort}
            setSort={setSort}
          />
        </div>
      </div>        

      <ModalForm show={editNews} title={'Edit News Item'}
                                       body={<NewsForm saveText={'Save'}
                                                           id={newsData?.id} hideAction={
                                           async (data) => {
                                                if (data?.is_published === "true") { data.is_published = "1" }
                                                else { data.is_published = "0" }
                                                await updateAdminNews(newsData?.id, data);
                                                await loadData();
                                                setEditNews(false);
                                           }
                                       }/>}
                                       handleClose={() => {
                                        setEditNews(false)
                                       }}
                            />

      <ModalForm show={addNews} title={'Add News Item'}
                                       body={<NewsForm saveText={'Save'}
                                                           hideAction={
                                           async (data) => {
                                                await createAdminNews(data);
                                                await loadData();
                                                setAddNews(false);
                                           }
                                       }/>}
                                       handleClose={() => {
                                        setAddNews(false)
                                       }}
                            />
     
     <ModalWindow show={deleteNewsModal}
                                         title={`Delete News Item`}
                                         body={'Are you sure you want to delete \'' + newsData?.title + '\'?'}
                                         hideAction={async () => {
                                            await deleteAdminNews(newsData?.id);
                                            await loadData()
                                         }}
                                         confirmClassName={'btn-danger'}
                                         handleClose={() => {
                                             setDeleteNewsModal(false)
                                         }}
                            />
    </main>
  );
};

export default AdminNews;
