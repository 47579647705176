import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FreelancerModalWindow from '../../../components/FreelancerModalWindow';
import { makeRequest } from '../../../services/requests';
import { getErrorMessage } from '../../../services/utils';

export const FreelancerForgotPassword = ({ showModal, setShowModel }) => {
  const navigation = useNavigate();
  const [modalInputsData, setModalInputData] = useState({ password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordFields, setPasswordFields] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const handleToggleShowPassword = (field) => {
    setPasswordFields((prevPasswordFields) => ({
      ...prevPasswordFields,
      [field]: !prevPasswordFields[field],
    }));
  };
  const [secretVallid, setSecretValid] = useState('');
  const loadData = async () => {
    const resp = await makeRequest(`user/${secretParam}`);
    setSecretValid(resp);
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Get Params form URL
  let params = new URL(document?.location)?.searchParams;
  let resetpass = params?.get('resetpass');
  let secretParam = params?.get('secret');
  const [showModalConfirm, setShowModelConfirm] = useState(resetpass);
  const [showSecretModal, setShowSecretModal] = useState(resetpass);

  const ModalInputChange = (e) => {
    const { name, value } = e?.target;
    setModalInputData((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    if (name === 'password') {
      const isStrongPassword = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])/.test(value);
      const isLengthValid = value?.length >= 8;
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          value?.trim() === ''
            ? 'Password is required.'
            : !isStrongPassword
            ? 'Must contain at least 1 capital letter, special symbol, and a number.'
            : !isLengthValid
            ? 'Password must be at least 8 characters long.'
            : '',
      }));
    } else if (name === 'confirmPassword') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          value?.trim() === ''
            ? 'Confirm password is required.'
            : value !== modalInputsData?.password
            ? 'Passwords do not match.'
            : '',
      }));
    }
  };

  const handleEmailVerify = async (event) => {
    event.preventDefault();
    if (!isLoading) {
      const body = {
        email: email,
      };
      setIsLoading(true);
      const resp = await makeRequest('auth/forgot-password', body, 'POST', false);
      if (resp?.result === 'success') {
        setShowModel(false);
        window.infoMessage = "We've sent an email to you now with further instructions on how to reset your password.";
      }
      if (resp?.result === 'error') {
        setErrorMessagePassword(getErrorMessage(resp));
      }
      setIsLoading(false);
    }
  };
  const handlePasswordVerify = async (event) => {
    event?.preventDefault();
    const body = {
      secret: secretParam,
      password: modalInputsData?.password,
      password_confirmation: modalInputsData?.confirmPassword,
    };
    if (!isLoading) {
      setIsLoading(true);
      const resp = await makeRequest('auth/set-password', body, 'POST', false);
      if (resp?.result === 'success') {
        setShowModelConfirm(false);
        navigation('/login');
        window.infoMessage = "Your password has now been updated so you can login using the form below.";
      }
      if (resp?.result === 'error') {
        setErrorMessagePassword(getErrorMessage(resp));
      }
    }
    setIsLoading(false);
  };

  const passwordCancelVerify = () => {
    setShowModelConfirm(false);
    setErrorMessagePassword(false);
    navigation('/login');
  };

  const emailCancelVerify = () => {
    setShowModel(false);
    setEmail('');
    setErrorMessagePassword(false);
  };

  return (
    <div className='modal-main'>
      {secretVallid?.result === 'success' && showModalConfirm && (
        <FreelancerModalWindow
          className='forgot-modal'
          centered='center'
          body={
            <>
              <h6>Create New Password</h6>
              <p>Please enter a new password for your account below.</p>
              <div className='form-group login-form'>
                <label htmlFor='inputPassword'>Password</label>
                <div style={{ position: 'relative' }}>
                  <input
                    onChange={ModalInputChange}
                    name='password'
                    type={passwordFields.showPassword ? 'text' : 'password'}
                    className='form-control'
                    id='inputEmail'
                    aria-describedby='emailHelp'
                    placeholder=''
                    value={modalInputsData?.password}
                  />
                  <button
                    disabled={isLoading}
                    onClick={() => handleToggleShowPassword('showPassword')}
                    type='button'
                    className={`btn btnShowHide ${passwordFields.showPassword ? 'show' : ''}`}
                  >
                    Show/Hide
                  </button>
                </div>
                <div>{errors?.password && <span className='error-danger'>{errors?.password}</span>}</div>
              </div>
              <div className='form-group login-form'>
                <div style={{ position: 'relative' }}>
                  <label htmlFor='inputPassword' className='confirm-label'>
                    Confirm Password
                  </label>
                  <input
                    onChange={ModalInputChange}
                    name='confirmPassword'
                    type={passwordFields.showConfirmPassword ? 'text' : 'password'}
                    className={'form-control'}
                    id='inputConfirmPassword'
                    placeholder=''
                    value={modalInputsData?.confirmPassword}
                  />
                  <button
                    disabled={isLoading}
                    onClick={() => handleToggleShowPassword('showConfirmPassword')}
                    type='button'
                    className={`btn btnShowHide ${passwordFields.showConfirmPassword ? 'show' : ''}`}
                  >
                    Show/Hide
                  </button>
                </div>
                {errors?.confirmPassword && <span className='error-danger'>{errors?.confirmPassword}</span>}{' '}
              </div>
            </>
          }
          children={
            <>
              <Button variant={'btn btn-light'} onClick={passwordCancelVerify}>
                Cancel
              </Button>
              <Button
                variant={'btn btn-dark'}
                onClick={handlePasswordVerify}
                disabled={modalInputsData?.password !== modalInputsData?.confirmPassword || !modalInputsData?.password}
              >
                Save
              </Button>
            </>
          }
          show={showModalConfirm}
        />
      )}
      {secretVallid?.result === 'error' && showModalConfirm && (
        <FreelancerModalWindow
          className='forgot-modal'
          centered='center'
          body={
            <>
              <h5>Invalid URL</h5>
              <p>Please check the URL you followed and try again.</p>
              <Button
                className='w-100'
                variant={'btn btn-dark '}
                onClick={() => {
                  setShowSecretModal(false);
                  navigation('/login');
                }}
              >
                Close
              </Button>
            </>
          }
          show={showSecretModal}
        />
      )}
      <FreelancerModalWindow
        className='forgot-modal'
        centered='center'
        body={
          <>
            <h6>Forgotten Password</h6>
            <p>We'll send a link to your email so that you can create a new password.</p>
            {errorMessagePassword ? (
              <div
                className={'alert alert-danger'}
                dangerouslySetInnerHTML={{ __html: errorMessagePassword }}
              />
            ) : null}
            <div className='form-group login-form'>
              <label htmlFor='inputEmail'>Email</label>
              <input
                onChange={(event) => setEmail(event?.target?.value)}
                name='email'
                type='email'
                className='form-control'
                id='inputEmail'
                aria-describedby='emailHelp'
                placeholder=''
              />
            </div>
          </>
        }
        children={
          <>
            <Button variant={'btn btn-light'} onClick={emailCancelVerify}>
              Cancel
            </Button>
            <Button variant={'btn btn-dark'} onClick={handleEmailVerify}>
              Send Link
            </Button>
          </>
        }
        show={showModal}
      />
    </div>
  );
};
