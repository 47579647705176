import React, {useEffect, useState} from 'react';
import {getTreatmentGroup} from "../services/requests";
import Loading from "./Loading";

const GroupForm = ({id, hideAction, saveText}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({name: "", description: ""});
    const [errorMessage, setErrorMessage] = useState('');
    const validateForm = () => {
        const formErrors = [];
        if (!data?.name) {
            formErrors.push("Please enter a group name.");
        }
        if (!data?.description) {
            formErrors.push("Please enter a group description.");
        }
        setErrorMessage(formErrors?.join('<br/>'));
        return formErrors.length === 0;
    };
    const loadData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            if (id) {
                const resp = await getTreatmentGroup(id);
                setData(resp);
            }
        }
    }

    useEffect(() => {
        loadData().then(() => {
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = async () => {
        if (validateForm()) {
            await hideAction(data);
        }
    }

    const inputChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }
    useEffect(() => {
        if (id) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    if (isLoading) {
        return <Loading/>;
    }
    return <form>
        <div className="form-group">
            {errorMessage && <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMessage}}/>}
            <label htmlFor="groupName" className="input-label">Name</label>
            <input type="text" className="form-control" id="groupName" value={data.name} onChange={inputChange}
                   name={'name'} placeholder=""/>
        </div>
        <div className="form-group">
            <label htmlFor="groupDescription" className="input-label">Description</label>
            <textarea name="description" value={data.description} onChange={inputChange} rows="4" type="text"
                      className="form-control" id="groupDescription"
                      placeholder=""></textarea>
        </div>
        <div className="form-group">
        </div>
        <div className="form-group">
            <button onClick={submitForm} type="button"
                    className="btn btn-primary">Save</button>
        </div>
    </form>;
}
export default GroupForm;
