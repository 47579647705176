import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {ReactComponent as Close} from '../assets/icon/Close.svg';

const ModalWindow = ({show, handleClose, title, body }) => {
    return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    {title ? <Modal.Title as="h5">{title}</Modal.Title> : null}
                    <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span><Close/></span>
                    </button>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
            </Modal>
    );
}

export default ModalWindow;
