import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/responsive.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {Route, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AdminLogin from "./screens/Admin/AdminLogin/AdminLogin";
import ProtectedAdminRoute from "./components/PrivateAdminRoute";
import {makeRequest} from "./services/requests";
import {getToken, getUserId, setUserId} from "./services/token";
import { useDispatch, useSelector } from 'react-redux';
import {LOGIN_USER} from "./types";
import AdminMain from "./screens/Admin/AdminMain";
import AdminCreatePassword from './screens/Admin/AdminCreatePassword/AdminCreatePassword';
import Login from './screens/Login';
import ProtectedFreelancerRoute from './components/PrivateFreelancerRoute';
import FreelancerMain from './screens/Freelancer/FreelancerMain';
import FreelancerInfo from './screens/landingPage/FreelancerInfo';
import ProtectedCustomerRoute from './components/PrivateCustomerRoute';
import { CustomerRegister } from './screens/Customer/CustomerRegister/CustomerRegister';
import { CustomerDashboard } from './screens/Customer/CustomerDashboard/CustomerDashboard';

import { BookAppointment } from './screens/Customer/BookAppointment/BookAppointment';
import { BookAppointmentFreelancer } from './screens/Customer/BookAppointment/BookAppointmentFreelancer';
import { BookAppointmentCart } from './screens/Customer/BookAppointment/BookAppointmentCart';

// WEBSITE ROUTES
import { LandingPage } from './screens/landingPage/LandingPage';
import ContactUs from './screens/landingPage/ContactUs';
import Privacy from './screens/landingPage/Privacy';

import GoogleCallback from "./components/GoogleCallback";

function App() {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state);
    const [isLogged, setIsLoading] = useState(true);
    
    const getUser = async () => {
        if (getToken()) {
            const resp = await makeRequest('user', null);
            let role, id, firstName, lastName;
            let address1, address2, city, county, postcode;
            
            if (resp.id === 1) {
                role = 'admin';
                id = 1;
            } else if (resp.freelancer) {
                role = 'freelancer';
                firstName = resp.freelancer.first_name;
                lastName = resp.freelancer.last_name;
                id = resp?.freelancer?.id;
            } else if (resp.customer) {
                role = 'customer';
                firstName = resp.customer.first_name;
                lastName = resp.customer.last_name;
                
                address1 = resp.customer.address1;
                address2 = resp.customer.address2;
                city = resp.customer.city;
                county = resp.customer.county;
                postcode = resp.customer.postcode;
                
                id = resp?.customer?.id;
            }
            if (role) {
                dispatch({
                    type: LOGIN_USER, 
                    payload: {
                        token: getToken(),
                        role: role,
                        email: resp.email,
                        first_name: firstName,
                        last_name: lastName,
                        id: id,

                        address1: address1,
                        address2: address2,
                        city: city,
                        county: county,
                        postcode: postcode,        
                    }
                });
            }
        }        

        if (!auth?.authenticated && (getUserId() === "undefined" || !getUserId())) {
            var customerId = Math.round(Date.now() + Math.random());
            setUserId(customerId)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return !isLogged ?     
    (
    <>
    <Routes>
        <Route path={'/'} Component={LandingPage}/>

        <Route path={'/book-appointment/:locationId/:areaId/:treatmentCategoryId/:date/:time'} 
                Component={BookAppointment}/>
        <Route path={'/book-appointment/:locationId/:areaId/:treatmentCategoryId/:freelancerId/:date/:time'} 
                Component={BookAppointmentFreelancer}/>
        <Route path={'/book-appointment/cart'}
                Component={BookAppointmentCart}/>

        <Route path={'/admin/login'} Component={AdminLogin}/>
        <Route path={'/login?resetpass=true/:secret'} Component={Login}/>
        <Route path={'/login'} Component={Login}/>        
        <Route path={'/register/:id'} Component={AdminCreatePassword}/>
        <Route path={'/register'} Component={CustomerRegister}/>
        <Route path={'/prouser/info'} Component={FreelancerInfo}/>

        <Route path={'/auth/google'} element={<GoogleCallback />}></Route>

        <Route path={'/admin/*'} element={
            <ProtectedAdminRoute>
                <AdminMain/>
            </ProtectedAdminRoute>
        }/>
        <Route path={'/prouser/*'} element={
            <ProtectedFreelancerRoute>
                <FreelancerMain/>
            </ProtectedFreelancerRoute>
        }/>
        <Route path={'/customer/*'} element={
            <ProtectedCustomerRoute>
                 <Routes>
                     <Route path={'/'} element={<CustomerDashboard />} />
                </Routes>
            </ProtectedCustomerRoute>
        }/>

        {/* WEBSITE ROUTES */}
        <Route path={'/contact-us'} Component={ContactUs}/>
        <Route path={'/privacy'} Component={Privacy}/>

    </Routes>
    </>
    )
    : <div id="spinnerDiv">
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
}

export default App;
