import React, { useEffect, useState } from 'react';
import './FreelancerProfile.css';
import Payments from './payment/Payments';
import {  NavLink, useParams } from 'react-router-dom';
import General from './general/General';
import Location from './location/Location';
import Security from './security/Security';
import Schedule from './schedule/Schedule';
import Portfolio from './portfolio/Portfolio';

const FreelancerProfile = () => {
  const { categories } = useParams();
  const [activeTab, setActiveTab] = useState('profile');

  useEffect(() => {
    const storedTab = localStorage?.getItem('selectedFreelancerTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
    else {
      setActiveTab('profile');
    }
    const handleLogout = () => {
      localStorage.removeItem('selectedFreelancerTab');
    };
    window.addEventListener('logout', handleLogout);
    
    return () => {
      // Cleanup: remove the event listener when the component unmounts
      window.removeEventListener('logout', handleLogout);
    };
  }, []);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    localStorage?.setItem('selectedFreelancerTab', tab);
  };
  useEffect(() => {
    if (categories === 'profile') {
      setActiveTab('profile');
    } else if (categories === 'location') {
      setActiveTab('location');
    } else if (categories === 'security') {
      setActiveTab('security');
    } else if (categories === 'schedule') {
      setActiveTab('schedule');
    } else if (categories === 'payment') {
      setActiveTab('payment');
    } else if (categories === 'portfolio') {
      setActiveTab('portfolio');
    }
  }, [categories]);
  
  return (
    <main id='main-section'>
      <div className='container-fluid'>
        <div id='profile-page'>
          <div>
            <h2 className='titleHeading2'>Account Settings</h2>
          </div>
          <div className='subtitle' id='justify-tab-example'>
            <div className='nav nav-tabs'>
              <div className='nav-item'>
                <NavLink
                  to='/prouser/settings/profile'
                  className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('profile')}
                >
                  Profile
                </NavLink>
              </div>
              <div className='nav-item'>
                <NavLink
                  to='/prouser/settings/location'
                  className={`nav-link ${activeTab === 'location' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('location')}
                >
                  Location
                </NavLink>
              </div>

              <div className='nav-item'>
                <NavLink
                  to='/prouser/settings/security'
                  className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('security')}
                >
                  Security
                </NavLink>
              </div>
              <div className='nav-item'>
                <NavLink
                  to='/prouser/settings/schedule'
                  className={`nav-link ${activeTab === 'schedule' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('schedule')}
                >
                  Hours
                </NavLink>
              </div>
              <div className='nav-item'>
                <NavLink
                  to='/prouser/settings/payment'
                  className={`nav-link ${activeTab === 'payment' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('payment')}
                >
                  Payments
                </NavLink>
              </div>
              <div className='nav-item'>
                <NavLink
                  to='/prouser/settings/portfolio'
                  className={`nav-link ${activeTab === 'portfolio' ? 'active' : ''}`}
                  onClick={() => handleTabSelect('portfolio')}
                >
                  Portfolio
                </NavLink>
              </div>
            </div>
          </div>
          {activeTab === 'profile' && <General />}
          {activeTab === 'location' && <Location />}
          {activeTab === 'security' && <Security />}
          {activeTab === 'schedule' && <Schedule />}
          {activeTab === 'payment' && <Payments />}
          {activeTab === 'portfolio' && <Portfolio />}
        </div>
      </div>
    </main>
  );
};

export default FreelancerProfile;
