import React, { useState, useRef, useEffect } from 'react';
import { uploadFreelancerPortfolioImage, getFreelancerPortfolio, deleteFreelancerPortfolioImage } from '../../../../services/requests';
import { ReactComponent as Close } from '../../../../assets/icon/Close.svg';
import ModalWindow from '../../../../components/ModalWindow';

const Portfolio = () => {

    const [messageSuccess, setMessageSuccess] = useState(null)
    const [messageError, setMessageError] = useState(null)
    const [file, setFile] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const API_URL = process.env.REACT_APP_IMAGE;
    const [deleteModal, setDeleteModal] = useState(false);
    const [imageIdToDelete, setImageIdToDelete] = useState(null);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            const resp = await getFreelancerPortfolio();
            setData(resp?.data);
            setIsLoading(false);
        }
    }

    const handleSave = async (e) => {
        try {
            const body = {
              image: file,
            };  
      
            const resp = await uploadFreelancerPortfolioImage({ ...body });
            if (resp?.result === 'success') {
                setMessageError(null)
                // setMessageSuccess("Your portfolio image has now been saved.")
                inputFile.current.value = '';
                loadData(); 
            }
            else {
              setMessageSuccess(null)
              setMessageError('Error saving portfolio profile image. ', resp.result)
            }
          } catch (error) {
            console.error('Error saving portfolio profile image. ', error);
          }
    };

    const handleFileChange = (event) => {
        setFile(event?.target?.files[0]);
    };

    const handleDelete = (event) => {
        setDeleteModal(true);
        setImageIdToDelete(event);
    };

    const inputFile = useRef(null);

    return (
<>
        <ModalWindow
            show={deleteModal}
            confirmClassName={'btn-danger'}
            title={`Delete Portfolio Image`}
            body={`Are you sure you want to delete this image?`}
            handleClose={() => {
                setImageIdToDelete(null);
                setDeleteModal(false);
            }}
            hideAction={async () => {
                await deleteFreelancerPortfolioImage(imageIdToDelete);
                loadData();
                setDeleteModal(false);
            }}
        />

        <div>
            {messageSuccess ? (
                <div className={'alert alert-success'}>{messageSuccess}</div>
            ) : null}

            {messageError ? (
                <div className={'alert alert-danger'}>{messageError}</div>
            ) : null}

            <div className='mt-4' style={{marginBottom: 0}}>
                <p><b>Portfolio of Images</b></p>
                <p>These images will appear on your public profile.</p>
            </div>

            <div className='mt-4 gallery-box'>
                {data?.map((item, index) => (
                    <div key={index} className='mt-4 image-container'>
                        <img src={`${API_URL}freelancers/${item?.image}`} alt='portfolio' style={{ width: 200, height: 200 }} />
                        <button className='delete-icon' onClick={() => handleDelete(item?.id)}>
                            <Close style={{width:"75%"}}/>
                        </button>
                    </div>
                ))}
            </div>

            <input
                ref={inputFile}
                type='file'
                name='file'
                onChange={handleFileChange}
                accept='image/*'
                style={{ marginTop: 20 }}
            />

            <div>
                <button className='btn btn-dark' onClick={handleSave} style={{ marginTop: 20 }}>
                    Upload
                </button>
            </div>

        </div>
</>                
    );
};

export default Portfolio;
