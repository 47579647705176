import {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {makeRequest} from "../services/requests";
import {LOGIN_USER} from "../types";
import {useDispatch} from "react-redux";

function GoogleCallback() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    makeRequest(`auth/google/callback${location.search}`)
      .then(response => {
        return response;
      })
      .then(data => {
        if (data?.token) {
          dispatch({
            type: LOGIN_USER,
            payload: {
              token: data?.token,
              role: data?.permissions[0],
              email: data?.user?.email,
              name: data?.user?.name,
              status: data?.user?.freelancer?.status,
            },
          });
          if (data?.permissions[0] === 'freelancer') {
            if (data?.user?.freelancer?.status === true) {
              navigation('/freelancer');
            }
          } else if (data?.permissions[0] === 'customer') {
            navigation('/customer');
          }
        } else navigation('/login')

        setLoading(false);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default GoogleCallback;
