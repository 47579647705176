import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import './CustomerRegister.css';
import WebsiteHeader from '../../../components/Website/Header';
import { makeRequest, updateCartUser } from '../../../services/requests';
import { useDispatch } from 'react-redux';
import { LOGIN_USER } from '../../../types';
import { getErrorMessage } from '../../../services/utils';
import { getUserId, setUserId } from '../../../services/token';

export const CustomerRegister = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [passwordFields, setPasswordFields] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInput] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
    newsletter: false
  });
  const [searchParams] = useSearchParams();
  const inputChange = (e) => {
    setInput({ ...inputs, [e.target.name]: e.target.value });
  };

  const validateInputs = () => {
    const formErrors = [];
    if (!inputs.first_name) {
      formErrors.push('First name is required.');
    }
    if (!inputs.last_name) {
      formErrors.push('Last name is required.');
    }
    if (!inputs.email) {
      formErrors.push('Email is required.');
    }
    if (!inputs.phone) {
      formErrors.push('Phone number is required.');
    } else if (!/^\d+$/.test(inputs.phone)) {
      formErrors.push('Phone number must contain only numbers.');
    }
    if (!inputs.password) {
      formErrors.push('Password is required.')
    } else if (inputs.password.length < 8) {
      formErrors.push('Password must be at least 8 characters long');
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(inputs.password)) {
      formErrors.push('Password must contain at least 1 capital letter, 1 special symbol, and 1 number.');
    }
    if (!inputs.terms) {
      formErrors.push('Please accept the terms and conditions.');
    }
    setError(formErrors?.join('<br/>'));
    return formErrors?.length === 0;
  };

  const register = async (event) => {
    event?.preventDefault();
    if (validateInputs() && !isLoading) {
      setIsLoading(true);
      const resp = await makeRequest('auth/sign-up', inputs, 'POST', false);

      var userId = resp?.user?.customer?.id

      if (resp.token) {
        dispatch({
          type: LOGIN_USER,
          payload: {
            token: resp.token,
            role: resp?.permissions[0],
            email: resp?.user?.email,

            first_name: resp?.customer?.first_name,
            last_name: resp?.customer?.last_name,

            address1: resp?.customer?.address1,
            address2: resp?.customer?.address2,
            city: resp?.customer?.city,
            county: resp?.customer?.county,
            postcode: resp?.customer?.postcode,
          },
        });
        await updateCartUser(getUserId(), userId).then(() => {
          setUserId(userId)
          if (searchParams.get("bookingLogin")) {
            navigation('/book-appointment/cart?paynow=true');
          }
          else {
            navigation('/customer');
          }
        })
      }
      if (resp.result === 'error') {
        setError(getErrorMessage(resp));
      }

      setIsLoading(false);
    }
  };
  const handleToggleShowPassword = (field) => {
    setPasswordFields((prevPasswordFields) => ({
      ...prevPasswordFields,
      [field]: !prevPasswordFields[field],
    }));
  };
  return (
    <>
      <WebsiteHeader />
      <section id='login-section' className='vh-100'>
        <div className='line line-1'></div>
        <div className='line line-2'></div>
        <div className='line line-3'></div>
        <div className='line line-4'></div>
        <div className='custom-container'>
          <div className='row h-100'>
            <div className='col-lg-5 col-12 offset-lg-1 d-flex align-items-center register-form'>
              <div className='customer-form d-flex flex-column m-lg-0 m-auto'>
                <span className='register-title'>Sign up for a customer account.</span>
                <span className='login-subtitle'>
                  Please enter your details below so that you can register as a customer and book some time with one of our professionals.
                </span>
                <form onSubmit={register}>
                  <div className='form-group '>
                    {error ? (
                      <div className={'alert alert-danger'} dangerouslySetInnerHTML={{ __html: error }} />
                    ) : null}
                    <label>First Name</label>
                    <input
                      onChange={inputChange}
                      name='first_name'
                      type='text'
                      className='form-control'
                      placeholder=''
                    />
                  </div>
                  <div className='form-group '>
                    <label>Last Name</label>
                    <input
                      onChange={inputChange}
                      name='last_name'
                      type='text'
                      className='form-control'
                      placeholder=''
                    />
                  </div>
                  <div className='form-group '>
                    <label htmlFor='email'>Email</label>
                    <input
                      onChange={inputChange}
                      name='email'
                      type='email'
                      className='form-control'
                      placeholder=''
                    />
                  </div>
                  <div className='form-group '>
                    <label htmlFor='inputEmail1'>Phone number</label>
                    <input
                      onChange={inputChange}
                      name='phone'
                      type='text'
                      className='form-control'
                      id='inputEmail1'
                      placeholder=''
                    />
                  </div>
                  <div className='form-group field_password'>
                    <label>Password</label>
                    <input
                      onChange={inputChange}
                      name='password'
                      type={passwordFields.showPassword ? 'text' : 'password'}
                      className='form-control'
                      placeholder=''
                    />
                    <button
                      disabled={isLoading}
                      onClick={() => handleToggleShowPassword('showPassword')}

                      type='button'
                      className={`btn btnShowHide ${passwordFields.showPassword ? 'show' : ''}`}

                    >
                      Show/Hide
                    </button>
                  </div>
                  <div className='form-group field_password'>
                    <label>Confirm Password</label>
                    <div style={{ position: 'relative' }}>
                      <input
                        onChange={inputChange}
                        name='password_confirmation'
                        type={passwordFields.showConfirmPassword ? 'text' : 'password'}
                        className='form-control'
                        placeholder=''
                      />
                      <button
                        disabled={isLoading}
                        onClick={() => handleToggleShowPassword('showConfirmPassword')}
                        type='button'
                        className={`btn btnShowHide ${passwordFields.showConfirmPassword ? 'show' : ''}`}
                      >
                        Show/Hide
                      </button>
                    </div>
                  </div>
                  <div className='mt-0'>
                    <label className='checkbox account-text mt-1 mb-1'>
                      <input
                        className='check'
                        type='checkbox'
                        checked={inputs?.terms}
                        onChange={(e) => setInput({ ...inputs, terms: e?.target?.checked })}
                        style={{
                          accentColor: '#322830',
                          border: '1px solid #D0D5DD',
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer',
                        }}
                      />
                      <span className='checkbox-label text-start'>
                        By clicking sign up, I agree and consent to the
                        <Link className='terms-condition-link'>
                          Terms & Conditions
                        </Link>
                        .
                      </span>
                    </label>
                  </div>

                  <div className='mt-0'>
                    <label className='checkbox account-text mt-0'>
                      <input
                        className='check'
                        type='checkbox'
                        checked={inputs?.newsletter}
                        onChange={(e) => setInput({ ...inputs, newsletter: e?.target?.checked })}
                        style={{
                          accentColor: '#322830',
                          border: '1px solid #D0D5DD',
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer',
                        }}
                      />
                      <span className='checkbox-label text-start '>
                        I want to receive marketing emails with promotions.
                      </span>
                    </label>
                  </div>
                  <div className='btn-main'>
                    <button type='submit' className='btn register-btn'>
                      Sign Up
                    </button>
                  </div>
                </form>
                <div className='account-text'>
                  <span>Already have an account?</span>
                  <Link className='signup-link' to='/login'>
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-5 offset-1 d-lg-block d-none'>
              <div id='freelancer-login-bg'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
