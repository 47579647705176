import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { convertTime24to12, convertTime, dropdownValues, weekdays } from '../../../../constants/menuItems';
import { updateFreelancerHours, getFreelancerSettings } from '../../../../services/requests';
import './Schedule.css';

const Schedule = () => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [data, setData] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null)
  const [messageError, setMessageError] = useState(null)
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    if (!isLoading && !data) {
      setIsLoading(true);
      const resp = await getFreelancerSettings();
      setData(resp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    if (data) {
      let totalDays = data?.settings?.map((item) => {
        return { day: item?.day, hours_start: item?.hours_start, hours_end: item?.hours_end };
      });

      setSelectedValue(totalDays);
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSave = async () => {
    
    try {
      const body = {
        settings: selectedValue
      };  

      const resp = await updateFreelancerHours({ ...body, _method: 'PUT' });
      if (resp?.result === 'success') {
        setMessageError(null)
        setMessageSuccess("Your hours have now been saved.")
      }
      else {
        setMessageSuccess(null)
        setMessageError('Error updating hour settings. ', resp.result)
      }
    } catch (error) {
      console.error('Error updating hour settings. ', error);
    }
  };

  // eslint-disable-next-line array-callback-return
  const handleCheckboxChange = (e, day) => {
    if (!e?.target?.checked) {
      // eslint-disable-next-line array-callback-return
      var filtered = selectedValue?.map((item) => {
        if (day?.value !== item?.day) return item;
      });
      setSelectedValue(filtered);
      return;
    }
    setSelectedValue([...selectedValue, 
      { day: day?.value, 
        hours_start: '09:00',  // '9 AM'
        hours_end: '18:00'     // '6 PM'
      }
    ]);
  };
  const handleSelect = (dayValue, dropdownValue, dropdownName) => {
    let SelecteddropdownValue = [];
    if (dropdownName === 'end') {
      SelecteddropdownValue = selectedValue?.map((item) => {
        if (dayValue === item?.day) {
          return { ...item, hours_end: convertTime(dropdownValue) };
        } else {
          return item;
        }
      });
    } else {
      SelecteddropdownValue = selectedValue?.map((item) => {
        if (dayValue === item?.day) {
          return { ...item, hours_start: convertTime(dropdownValue) };
        } else {
          return item;
        }
      });
    }
    setSelectedValue(SelecteddropdownValue);
  };
  return (
    <div className="schedule-tab">
      
      {messageSuccess ? (
        <div className={'alert alert-success'}>{messageSuccess}</div>
        ) : null}                

      {messageError ? (
        <div className={'alert alert-danger'}>{messageError}</div>
        ) : null}                

      <div className='user-email mt-2'>
        <div className='profile-title align-items-center'></div>
        <div className='w-50 shedule-wrapper'>
          {weekdays?.map((day) => (
            <div className='align-items-center shedule-row d-flex email' key={day?.value}>
              <div className='schedule-subtitle d-flex align-items-center'>
                <div className='checkbox mb-0'>
                  <input
                    id={`checkboxId-${day?.value}`}
                    className='check checkbox-input d-none mb-0 '
                    type='checkbox'
                    checked={
                      selectedValue
                        ? selectedValue?.find((item) => day?.value?.includes(item?.day?.toLowerCase()))
                        : ''
                    }
                    onChange={(e) => handleCheckboxChange(e, day)}
                  />

                  <label className='chackbox-label freelancer-settings__checkbox' htmlFor={`checkboxId-${day.value}`}>
                    <span className='custom-checkbox'> </span>
                    <span className='checkhbox-text'>{day?.label}</span>
                  </label>
                </div>
              </div>
              <div className='schedule-subtitle' id='dropdown-basic-button'>
                <Dropdown id={`dropdown-basic-button-${day}`}>
                  <Dropdown.Toggle
                    variant='success'
                    id={`dropdown-basic-${day.value}`}
                    className='btn-primary'
                    defaultValue={1}
                  >
                    <div className='w-100 dropdown-value' 
                        style={selectedValue?.find((item) => day?.value?.includes(item?.day?.toLowerCase())) ? {color: '#000'}:{color: '#999'}}                    
                    >
                      {(() => {
                        let hoursStart = selectedValue?.find((item) => item?.day === day.value)?.hours_start;
                        return hoursStart ? convertTime24to12(hoursStart) : dropdownValues[8];
                      })()}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-menu'>
                    {dropdownValues?.map((value) => (
                      <Dropdown.Item key={value} onClick={() => handleSelect(day.value, value, 'start')}>
                        {value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='schedule-subtitle' id='dropdown-basic-button'>
                <Dropdown id={`dropdown-basic-button-${day}`}>
                  <Dropdown.Toggle
                    variant='success'
                    id={`dropdown-basic-${day.value}_end`}
                    className='btn-primary'
                  >
                    <div className='w-100 dropdown-value'
                      style={selectedValue?.find((item) => day?.value?.includes(item?.day?.toLowerCase())) ? {color: '#000'}:{color: '#999'}}                    
                    >
                      {(() => {
                        let hoursEnd = selectedValue?.find((item) => item?.day === day?.value)?.hours_end;
                        return hoursEnd ? convertTime24to12(hoursEnd) : dropdownValues[17];
                      })()}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-menu' >
                    {dropdownValues?.map((value) => (
                      <Dropdown.Item key={value} onClick={() => handleSelect(day?.value, value, 'end')}>
                        {value}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ))}

            <div className='user-email mt-4'>
                <div className='profile-title'>
                  <div className='title'></div>
                  <button className='btn btn-dark' onClick={handleSave} style={{ maxWidth: '170px', width: '100%' }}>
                    Save
                  </button>
                </div>
            </div>

        </div>        
      </div>
    </div>
    
  );
};


export default Schedule;
