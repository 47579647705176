import React, { useState, useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import './FreelancerCalendar.css'
import './ReactBigCalendar.css'
import { getFreelancerBookings } from '../../../services/requests'
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as IconLocation } from '../../../assets/icon/IconLocation.svg';
import { ReactComponent as IconCalendar } from '../../../assets/icon/IconCalendar.svg';
import { ReactComponent as IconClock } from '../../../assets/icon/IconClock.svg';
import IconDown from '../../../assets/icon/ChevronDown.svg'
import ModalWindow from '../../../components/ModalWindow'
import {
  cancelBooking, updateBooking
} from '../../../services/requests';

const localizer = momentLocalizer(moment)

const FreelancerCalendar = () => {

      const [setBookings] = useState(null)
      const [bookingsEvents, setBookingsEvents] = useState([])
      const [showPopup, setShowPopup] = useState(false)
      const [booking, setBooking] = useState(null)
      const [cancelModal, setCancelModal] = useState(false);
      const [bookingSafetyStatus, setBookingSafetyStatus] = useState('Pending')
  
      const loadData = async () => {
          var requestParameters = {
            start_date: '2023-01-01',
            end_date: '2023-12-01',
          }
          await getFreelancerBookings(requestParameters).then((bookings) => {

            var bookingsEventsLocal = []
            const bookingDates = Object.keys(bookings?.data);
            bookingDates.forEach((bookingDate, index) => {

                var bookingsForDate = bookings?.data[bookingDate]
                for (var i in bookingsForDate) {
                  
                  var booking = bookingsForDate[i]
                  var bookingDateTime = moment.utc(booking?.datetime, 'YYYY-MM-DD HH:mm:ss').local().toDate();
                  var bookingDateTimeEnd = moment.utc(booking?.datetime_end, 'YYYY-MM-DD HH:mm:ss').local().toDate();

                  bookingsEventsLocal.push({
                    start: bookingDateTime,
                    end: bookingDateTimeEnd,
                    title: booking?.treatment_name,
                    booking: booking
                  })

                }
                setBookingsEvents(bookingsEventsLocal)
            });
            
            setBookings(bookings?.data)
          })  
      }  
  
      useEffect(() => {
          loadData()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])  
  
        const MyCalendar = (props) => (
          <div>
            <Calendar
              localizer={localizer}
              events={props.events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              popup={true}
              selectable={true}
              onSelectEvent={(event) => handleSelectedEvent(event)}
            />
          </div>        
        )

  function handleSelectedEvent(event) {
    setBooking(event?.booking)
    setBookingSafetyStatus(event?.booking?.safety_status)
    setShowPopup(true)
  }

  const BookingModalWindow = ({show, handleClose,hideAction, title, body, confirmClassName, centered ,className}) => {

    var localDate = moment.utc(booking?.datetime, 'YYYY-MM-DD HH:mm:ss').local()
    const [newDatetime, setNewDateTime] = useState(moment(booking?.datetime))    

    // eslint-disable-next-line no-redeclare
    function handleClose() {
      setShowPopup(false)
      loadData(booking?.id);
    }
    const handleCancelClick = async() => {
      setShowPopup(false)
      setCancelModal(true)
    }
    const handleEditClick = async() => {
      setEditing(!editing)
    }
    const handleSaveClick = async() => {
      await updateBooking(booking?.id, newDatetime).then((results) => {            
        booking.datetime = newDatetime
        setEditing(false)
      });
    }

    const onBookingChangeDay = async(day) => {
      setNewDateTime(newDatetime.set('date', parseInt(day)))
      console.log(newDatetime.format('D MMM YYYY H:mm'))
    }
    const onBookingChangeMonth = async(month) => {
      setNewDateTime(newDatetime.set('month', parseInt(month)))
      console.log(newDatetime.format('D MMM YYYY H:mm'))
    }
    const onBookingChangeYear = async(month) => {
      setNewDateTime(newDatetime.set('year', parseInt(month)))
      console.log(newDatetime.format('D MMM YYYY H:mm'))
    }
    const onBookingChangeHour = async(hour) => {
      if (newDatetime.format("A") === "PM") {
        hour = parseInt(hour) + 12
      }
      setNewDateTime(newDatetime.set('hour', parseInt(hour)))
      console.log(newDatetime.format('D MMM YYYY H:mm'))
    }
    const onBookingChangeMinute = async(minute) => {
      // eslint-disable-next-line no-const-assign
      setNewDateTime(newDatetime = newDatetime.set('minute', parseInt(minute)))
      console.log(newDatetime.format('D MMM YYYY H:mm'))
    }
    const onBookingChangeAmPm = async(ampm) => {

      var currentDay = newDatetime.format('D')

      // if currently am => pm (add 12 hours)
      if (newDatetime.format("A") === "AM" && ampm === "AM") {
        console.log('setting AM -> PM')
        setNewDateTime(newDatetime.set('hour', parseInt(newDatetime.format('H')+12)))
        setNewDateTime(newDatetime.set('date', currentDay))
      }
      
      // if currently pm => am (take off 12 hours)
      else {
        console.log('setting PM -> AM')
        setNewDateTime(newDatetime.set('hour', parseInt(newDatetime.format('H')-12)))
        setNewDateTime(newDatetime.set('date', currentDay))
      }
      
      console.log(newDatetime.format('D MMM YYYY H:mm'))
    }
    const handleClickStatus = async() => {
      setDropDownVisible(!dropDownVisible)
    }
    const handleModalClick = async(e) => {
      if (e.target.id === "statusDropDown") {
        return
      }
      setDropDownVisible(false)
    }
    const handleClickStatusMenu = async(safetyStatus) => {
      await updateBooking(booking?.id, null, safetyStatus).then((results) => {            
        booking.safety_status = safetyStatus
        setBookingSafetyStatus(safetyStatus)
      });      
    }

    const [editing, setEditing] = useState(false)    
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    const years = []

    // eslint-disable-next-line no-lone-blocks
    {[...Array(5)].map((x, i) =>
      years.push(parseInt(moment().format("YYYY")) + i)
    )}                  

    const [dropDownVisible, setDropDownVisible] = useState(false)
    var menuClass = `dropdown-menu${dropDownVisible ? " show" : ""}`;

    return (      
      <>
      <ModalWindow
        show={cancelModal}
        confirmClassName={'btn-danger'}
        title={`Cancel Booking`}
        body={`Are you sure you want to cancel this booking?`}

        handleClose={() => {
          setCancelModal(false);
          setShowPopup(true)
        }}

        hideAction={async () => {
          setCancelModal(false);
          await cancelBooking(booking?.id).then((results) => {            
            booking.status = "canceled"
            setShowPopup(true)
          });          
        }}
      />

        <Modal show={showPopup} centered={true} onClick={(e) => handleModalClick(e)}>
    
        <div className="modal-header d-block">

            <div className="d-flex">
                <h3 className="modal-title calendar__booking-treatment-name">
                  {booking?.treatment_name}
                </h3>
                <button onClick={() => handleClose()}type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>                                
            </div>

            {booking?.addons?.length > 0 ? 

              booking?.addons.map((addon) => 
                <h5 class="modal-title calendar__booking-addon-name">
                  {addon?.freelancer_treatment_addon?.treatment_addon.name}
                </h5>
              )
            :''}

              {booking?.status === "canceled" ?               
              <div className="calendar__booking-status canceled">
                CANCELED
              </div>
              :
              <div className="calendar__booking-status" onClick={() => handleClickStatus()} id="statusDropDown">
                {bookingSafetyStatus} <img src={IconDown} alt="" style={{width: '12.5px'}}/>
              </div>}

                        <div className={menuClass} aria-labelledby="dropdownMenuButton" style={{width: '125px'}}>
                            <div onClick={()=>handleClickStatusMenu('Arrive')} className="calendar__booking-status-menuitem">Arrive</div>
                            <div onClick={()=>handleClickStatusMenu('Complete')} className="calendar__booking-status-menuitem">Complete</div>
                            <div onClick={()=>handleClickStatusMenu('Left')} className="calendar__booking-status-menuitem">Left</div>
                        </div>
        </div>                 
        
              <Modal.Body>
                  <div className="calendar__booking-address">
                    <IconLocation />                        
                      <span style={{marginLeft:'9px'}}>{booking?.customer?.first_name} {booking?.customer?.last_name}</span><br />
                      <span style={{marginLeft:'25px'}}>{booking?.customer?.address1}</span><br />
                      <span style={{marginLeft:'25px'}}>{booking?.customer?.address2}</span><br />
                      <span style={{marginLeft:'25px'}}>{booking?.customer?.city}</span><br />
                      <span style={{marginLeft:'25px'}}>{booking?.customer?.county}</span><br />
                      <span style={{marginLeft:'25px'}}>{booking?.customer?.postcode}</span><br />

                      <span style={{marginLeft:'25px'}}><b>Phone:</b> {booking?.customer?.phone}</span><br />
                  </div>

                  <div className="calendar__booking-date"><IconCalendar />
                  {!editing ?
                    <span style={{marginLeft:'10px'}}>{localDate.format("Do MMM Y")}</span>                    
                    :
                    <>
                    <select className="calendar_booking-edit-hour" onChange={event => onBookingChangeDay(event.target.value)}>
                    {[...Array(31)].map((x, i) =>
                      <option value={i+1}
                        // eslint-disable-next-line eqeqeq
                        selected={i+1 == localDate.format("D") ? 'selected' :''}>
                        {i+1}
                      </option>
                    )}                  
                    </select>

                    <select onChange={event => onBookingChangeMonth(event.target.value)}>
                    {[...Array(12)].map((x, i) =>
                      <option value={i}
                        // eslint-disable-next-line eqeqeq
                        selected={months[i] == localDate.format("MMM") ? 'selected' :''}>
                        {months[i]}
                      </option>
                    )}                  
                    </select>

                    <select onChange={event => onBookingChangeYear(event.target.value)}>
                    {[...Array(years.length)].map((x, i) =>
                      <option value={years[i]}
                        // eslint-disable-next-line eqeqeq
                        selected={years[i] == localDate.format("MMM") ? 'selected' :''}>
                        {years[i]}
                      </option>
                    )}                  
                    </select>                    
                    </>
                  }
                  </div>
                  <div className="calendar__booking-time"><IconClock />
                  {!editing ?
                    <span style={{marginLeft:'10px'}}>{localDate.format("h:mm A")}</span>
                    :
                    <>
                    <select className="calendar_booking-edit-hour" onChange={event => onBookingChangeHour(event.target.value)}>
                    {[...Array(12)].map((x, i) =>
                      <option value={i+1}
                        // eslint-disable-next-line eqeqeq
                        selected={i+1 == localDate.format("h") ? 'selected' :''}>
                        {i+1}
                      </option>
                    )}                  
                    </select>

                    <select onChange={event => onBookingChangeMinute(event.target.value)}>
                      <option value="0" selected={
                        // eslint-disable-next-line eqeqeq
                        "00" == localDate.format("mm") ? 'selected' :''
                        }>00</option>
                      <option value="15" selected={
                        // eslint-disable-next-line eqeqeq
                        "15" == localDate.format("mm") ? 'selected' :''
                        }>15</option>
                      <option value="30" selected={
                        // eslint-disable-next-line eqeqeq
                        "30" == localDate.format("mm") ? 'selected' :''
                        }>30</option>
                      <option value="45" selected={
                        // eslint-disable-next-line eqeqeq
                        "45" == localDate.format("mm") ? 'selected' :''
                        }>45</option>
                    </select>

                    <select onChange={event => onBookingChangeAmPm(event.target.value)}>
                      <option value="AM" selected={
                        // eslint-disable-next-line eqeqeq
                        "AM" == localDate.format("A") ? 'selected' :''}>AM</option>
                      <option value="PM" selected={
                        // eslint-disable-next-line eqeqeq
                        "PM" == localDate.format("A") ? 'selected' :''}>PM</option>
                    </select>
                    </>
                    }
                  </div>
                  <div className="calendar__booking-duration">Duration: {booking?.freelancer_treatment?.duration} mins</div>

                  {booking?.status !== "canceled" ? 
                  <>
                  <div className="calendar__booking-buttons">
                    <button type="button" onClick={() => handleCancelClick()} className="btn btn-danger">Cancel Booking</button>
                    &nbsp;
                    <button type="button" onClick={() => handleEditClick()} className="btn btn-primary">Change Date/Time</button>

                    {editing ?
                    <>
                    &nbsp;
                    <button type="button" onClick={() => handleSaveClick()} className="btn btn-primary btn-purple">Save</button>
                    </>
                    : ''
                    }
                  </div>
                  </>
                  : ''}

                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            </>
      );
  }

  return (
<>
    <BookingModalWindow />            
    
    <main id='main-section'>
    <div className='container-fluid'>
      <div id='blog-page'>
        <div>
          <h2 className='titleHeading2'>Calendar</h2>
          <br />
          <MyCalendar
            events={bookingsEvents}
          />
        </div>
      </div>
    </div>

  </main>

</>
  )

}

export default FreelancerCalendar