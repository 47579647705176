import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalWindow = ({show, handleClose,hideAction, title, body, confirmClassName, centered ,className}) => {
    const handleCloseWithAction = async () => {
        await hideAction();
        handleClose(false);
    }
    return (
            <Modal show={show} onHide={handleClose} centered={centered} className={className}>
                <Modal.Header>
                    {title ? <Modal.Title as="h6">{title}</Modal.Title> : null}
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="btn btn-light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant={`btn ${confirmClassName ? confirmClassName : 'btn-primary'}`} onClick={handleCloseWithAction}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}

export default ModalWindow;
