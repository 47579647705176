import React, { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ meta, setPage }) => {

  if (meta?.total_pages) {
    meta.last_page = meta?.total_pages
  }

  const pagination = {
    current_page: meta?.current_page,
    last_page: meta?.last_page,
  };
  const rangeList = useMemo(() => {
    const list = [];
    for (let i = 1; i <= pagination?.last_page; i++) {
      // if (Math?.abs(pagination?.current_page - i) <= 1 || i === 1 || i === pagination.last_page) {
        list?.push(i);
      // }
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination?.current_page, pagination?.last_page]);
  return (
    <tfoot>
      <tr>
        <td colSpan='6'>
          <div className='tbl_pagi'>
            <div className='tbl_pagi_prev'>
              {pagination?.current_page > 1 && (
                <button                  
                  style={{backgroundColor:"#fff"}}
                  onClick={() => setPage(pagination?.current_page - 1)}
                >
                  Previous
                </button>
              )} 
            </div>
            <div className='tbl_pagi_counter'>
              <ul>
                {rangeList?.map((page, index) => {
                  return (
                    <Fragment key={page}>
                      <li
                        className={pagination?.current_page === page ? 'active' : ''}
                        onClick={() => setPage(page)}
                      >
                        <Link>{page}</Link>
                      </li>
                    </Fragment>
                  );
                })}
              </ul>
            </div>
            <div className='tbl_pagi_next'>
              {pagination?.current_page <= pagination?.last_page && (
                <button
                style={{backgroundColor:"#fff"}}
                  disabled={pagination?.current_page === pagination?.last_page}
                  onClick={() => setPage(pagination?.current_page + 1)}
                >
                  Next
                  </button>
              )}
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  );
};
export default Pagination;
