import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getFreelancerNews } from '../../../services/requests';
import { formatDate } from '../../../services/utils';
import './FreelancerBlog.css';
import Loading from '../../../components/Loading';
import Pagination from "../../../components/Pagination";

const dropdownOptions = [
  { id: 1, label: 'All' },
  { id: 2, label: 'From newest' },
  { id: 3, label: 'From oldest' },
  { id: 4, label: 'From A-Z' },
  { id: 5, label: 'From Z-A' },
];
const FreelancerBlog = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOption] = useState(dropdownOptions[0]);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [meta, setMeta] = useState(null);
  const [page, setPage] = useState(1);

  const loadData = async () => {
    if (!isLoading) {
      setLoading(true);
      const resp = await getFreelancerNews(page);
      setData(resp?.data);
      setMeta(resp?.meta.pagination);
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const sortData = () => {
      const sortedData = data?.slice()?.sort((a, b) => {
        if (selectedOption?.id === 2) {
          return new Date(b?.updated_at) - new Date(a?.updated_at);
        } else if (selectedOption?.id === 3) {
          return new Date(a?.updated_at) - new Date(b?.updated_at);
        } else if (selectedOption?.id === 4) {
          const aFirstLetter = a?.title?.charAt(0).toUpperCase();
          const bFirstLetter = b?.title?.charAt(0).toUpperCase();
          if (aFirstLetter === bFirstLetter) {
            return a?.title?.localeCompare(b?.title);
          }
          return aFirstLetter?.localeCompare(bFirstLetter);
        } else if (selectedOption?.id === 5) {
          const aFirstLetter = a?.title?.charAt(0)?.toUpperCase();
          const bFirstLetter = b?.title?.charAt(0)?.toUpperCase();
          if (aFirstLetter === bFirstLetter) {
            return b?.title?.localeCompare(a?.title);
          }
          return bFirstLetter?.localeCompare(aFirstLetter);
        }
        return 0;
      });
      setFilteredData(sortedData);
    };
    sortData();
  }, [selectedOption, data]);

  return (
    <main id='main-section'>
      <div className='container-fluid'>
        <div id='blog-page'>
          <div>
            <h2 className='titleHeading2'>News</h2>
          </div>
          <br />
          
          {isLoading ? (
            <Loading />
          ) : (
            <div className='blog-main'>
              {filteredData?.map((item) => (
                <div className='card-item ' key={item?.id}>
                <Card className='mr-3 card-main w-100'>
                  <Card.Header>
                    <div className='header-text'>{item?.title}</div>
                  </Card.Header>
                  <Card.Body className='card-content'>
                    <Card.Text className='card-text' dangerouslySetInnerHTML={{ __html: item?.body }} />
                  </Card.Body>
                  <Card.Text className='card-date'>{formatDate(item?.updated_at)}</Card.Text>
                </Card>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      
      <div className="blog-paging">
        <table style={{width:"100%"}}>
          <Pagination meta={meta} setPage={setPage}/>
        </table>
      </div>
      
    </main>
  );
};

export default FreelancerBlog;
