export const setToken = (token) => {
    localStorage.setItem('token', token);
    localStorage.setItem('lastLoginTime', new Date(Date.now()).getTime());
};

export const getToken = () => {
    return localStorage.getItem('token')
}

export const getUserId = () => {
    return localStorage.getItem('userid')
}

export const setUserId = (userId) => {
    if (userId === null) {
        return localStorage.removeItem('userid')
    }
    else {
        return localStorage.setItem('userid', userId)
    }
}
