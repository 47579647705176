import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./ContactUs.css";
import "../../App.css";
import { submitContactUs } from "../../services/requests";
import { getErrorMessage } from "../../services/utils";
import WebsiteHeader from "../../components/Website/Header";
import WebsiteFooter from "../../components/Website/Footer";
import { FreelancerForgotPassword } from "../Freelancer/FreelancerForgotPassword/FreelancerForgotPassword";

const ContactUs = () => {
  const [inputs, setInput] = useState({ subject: "need_support", name: "", email: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showModal, setShowModel] = useState(false);
  const [searchParams] = useSearchParams();

  const inputChange = (e) => {
    setInput({ ...inputs, [e?.target?.name]: e?.target?.value });
  };

  const submitContactForm = async (event) => {
    window.infoMessage = "";
    setErrorMessage("");
    event.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const resp = await submitContactUs(inputs, "POST", false);
      if (resp?.result === "success") {        
        window.infoMessage = "Your message has been sent. We will get back to you as soon as possible.";
      }
      if (resp?.result === "error") {
        setErrorMessage(getErrorMessage(resp));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <WebsiteHeader />
      <section id="login-section" className="vh-100">
        <div className="line line-1"></div>
        <div className="line line-2"></div>
        <div className="line line-3"></div>
        <div className="line line-4"></div>
        <div className="custom-container">
          <div className="row h-100">
            <div className="col-lg-5 col-12 offset-lg-1 d-flex align-items-center main-login-form"
                style={{marginTop: 10}}>
              <div className="login-form d-flex flex-column m-lg-0 m-auto">
                {searchParams.get("bookingLogin") ? (
                  <div className={"alert alert-success"}>
                    Please sign up or login in below before checking out.
                  </div>
                ) : (
                  ""
                )}

                {errorMessage ? (
                  <div
                    className={"alert alert-danger"}
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                  />
                ) : null}
                {window.infoMessage ? (
                  <div className={"alert alert-success"}>
                    {window.infoMessage}
                  </div>
                ) : null}

                <span className="login-title">Contact us.</span>
                <form onSubmit={submitContactForm}>
                  <div className="form-group ">

                    <label htmlFor="inputName" style={{marginTop: 25, marginBottom: 5}}>Subject</label>
                    <select className="form-control" id="inputSubject" name="subject"
                        disabled={isLoading}
                        onChange={inputChange}>
                        <option value="need_support">Need Support</option>
	                    <option value="feature_request">Feature Request</option>
	                    <option value="account_deletion">Account Deletion</option>
                        <option value="other">Other</option>
                    </select>

                    <label htmlFor="inputName" style={{marginTop: 25, marginBottom: 5}}>Name</label>
                    <input
                      onChange={inputChange}
                      name="name"
                      className="form-control"
                      id="inputName"
                      placeholder=""
                      required
                      disabled={isLoading}
                    />

                    <label htmlFor="inputEmail" style={{marginTop: 25, marginBottom: 5}}>Email</label>
                    <input
                      onChange={inputChange}
                      name="email"
                      type="email"
                      className="form-control"
                      id="inputEmail"
                      placeholder=""
                      required
                      disabled={isLoading}
                    />

                    <label htmlFor="inputEmail" style={{marginTop: 25, marginBottom: 5}}>Message</label>
                    <textarea
                        onChange={inputChange}
                        name="message"
                        className="form-control"
                        id="inputMessage"
                        placeholder=""
                        lines="4"
                        style={{height: 100}}
                        required
                        disabled={isLoading}
                    />

                  </div>

                  <div className="btn-main">
                    <button type="submit" className="btn login-btn" style={{marginTop: 40}} disabled={isLoading}>
                      Send
                    </button>
                  </div>
                </form>

              </div>
            </div>
            <div className="col-5 offset-1 d-lg-block d-none">
              <div id="freelancer-login-bg"></div>
            </div>
          </div>
        </div>
        <FreelancerForgotPassword
          setShowModel={setShowModel}
          showModal={showModal}
        />
      </section>
      <WebsiteFooter />
    </>
  );
};

export default ContactUs;
