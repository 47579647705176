import React, { useState, useEffect } from 'react';
import { CustomInput } from '../../../../components/CustomInput';
import {
  getFreelancerSettings,
  updateFreelancerSettings,
  // getLocationAreasAsFreelancer
} from '../../../../services/requests';
import Loading from '../../../../components/Loading';
import './Location.css';

const Location = () => {
  const [areaList, setAreaList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null)
  const [messageError, setMessageError] = useState(null)
  // const [location, setLocation] = useState({});

  useEffect(() => {
    if (data) {
      let totalAreas = data?.areas?.map((item) => {
        return item;
      });
      setAreaList(totalAreas);
    }
  }, [data]);

  const handleSave = async () => {
    
    var areaIds = []
    areaList.map((item) => {
      areaIds.push(item.id)    
      return null
    })

    try {
      const body = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        address1: data?.address1,
        address2: data?.address2,
        county: data?.county,
        city: data?.city,
        postcode: data?.postcode,
        areas: areaIds,
        status: 1,
      };  

      const resp = await updateFreelancerSettings({ ...body, _method: 'PUT' });
      if (resp?.result === 'success') {
        setMessageError(null)
        setMessageSuccess("Your locations settings have now been saved.")
      }
      else {
        setMessageSuccess(null)
        setMessageError('Error updating location settings. ', resp.result)
      }
    } catch (error) {
      console.error('Error updating location settings. ', error);
    }
  };

  const loadData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const resp = await getFreelancerSettings();
      setData(resp);
      setIsLoading(false);
    }
  };

  const APIData = async () => {
    // const locationData = await getLocationAreasAsFreelancer();
    // setLocation(locationData);
  };

  useEffect(() => {
    APIData();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
   return <Loading />;
  }

  return (
    <div className="location-tab">
      
      {messageSuccess ? (
        <div className={'alert alert-success'}>{messageSuccess}</div>
        ) : null}                

      {messageError ? (
        <div className={'alert alert-danger'}>{messageError}</div>
        ) : null}

      <div className='user-email d-block '>
        <div className='d-flex '>
          <p className='title mt-2'>Address</p>
          <div className='w-100 address-row row g-0 mx-0 justify-content-end'>
            <div className='email address-col mr-0 w-100'>
              <CustomInput
                className='custom-input form-control'
                placeholder='Address 1 '
                value={data?.address1}
                onChange={(e) => setData({ ...data, address1: e?.target?.value })}
              />
            </div>
            <div className='email address-col mr-0 w-100'>
              <CustomInput
                className='custom-input form-control'
                placeholder='Address 2 '
                value={data?.address2}
                onChange={(e) => setData({ ...data, address2: e?.target?.value })}
              />
            </div>
            <div className='d-block address-col'>
              <CustomInput
                className='custom-input form-control'
                placeholder='City'
                value={data?.city}
                onChange={(e) => setData({ ...data, city: e?.target?.value })}
              />
            </div>
            <div className='d-block address-col'>
              <CustomInput
                className='custom-input form-control'
                placeholder='County'
                value={data?.county}
                onChange={(e) => setData({ ...data, county: e?.target?.value })}
              />
            </div>
            <div className='d-block address-col mr-0'>
              <CustomInput
                className='custom-input form-control'
                placeholder='Postcode'
                value={data?.postcode}
                onChange={(e) => setData({ ...data, postcode: e?.target?.value })}
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className='user-email d-block '>

              {/* <div className='d-flex '>
                <p className='title mt-2'>Areas Covered</p>
                <div className='d-block w-100'>
                  <div className='w-100 address-row row mx-0 g-0 '>
                    <div className='w-100 address-row row g-0 mx-0 justify-content-between mt-3'>
                      {location?.data
                        ?.filter((value, index, self) => {
                          return (
                            value?.location?.name 
                          );
                        })
                        .slice(0, undefined)
                        .map(
                          (value, index) =>
                            value?.location?.name && (
                              <div className='d-block address-col mr-0' key={index}>
                                <div className='checkbox mb-0'>
                                
                                  <input                                                       
                                    type='checkbox'
                                    id={`checkboxId-${value?.id}`}                                    
                                    checked={                     
                                      // eslint-disable-next-line eqeqeq
                                      areaList?.filter((area) => area?.id == value?.id).length > 0
                                    }                                    
                                    defaultChecked={                                      
                                      // eslint-disable-next-line eqeqeq
                                      areaList?.filter((area) => area?.id == value?.id).length > 0
                                    }
                                    onChange={(event) => {
                                      handleCheckboxChangeArea(event, value)
                                    }}
                                  />

                                  <label
                                    className='chackbox-label freelancer-settings__checkbox'
                                    htmlFor={`checkboxId-${value?.location?.id}`}
                                  >
                                    <span className='checkhbox-text text-start'>{value?.name} - ({value?.location?.name})</span>
                                  </label>
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div> */}




              <div className='user-email mt-4'>
                <div className='profile-title'>
                  <div className='title'></div>
                  </div>
                  <button className='btn btn-dark' onClick={handleSave} style={{ maxWidth: '170px', width: '100%' }}>
                    Save
                  </button>
                </div>
            </div>
    </div>
  );
};

export default Location;
