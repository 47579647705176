import React, { useEffect } from 'react';
import './Payment.css';
import { ReactComponent as ErrorIcon } from '../../../../assets/icon/Error.svg';
import { Button } from 'react-bootstrap';
import PaymentIMG from '../../../../assets/img/Illustration.png';
import { useState } from 'react';
import FreelancerModalWindow from '../../../../components/FreelancerModalWindow';
import { getFreelancerPayment, getFreelancerPaymentLink } from '../../../../services/requests';
import { capitalize } from '../../../../services/utils';
import Loading from '../../../../components/Loading';
const Payments = () => {
  const [isLoading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [data, setData] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const handleAccount = () => {
    if (paymentLink && paymentLink?.link && paymentLink?.link?.url) {
      window.location.href = paymentLink?.link?.url;
    }
  };
  const hadleDisconnect = () => {
    setConnected(false);
  };
  const loadData = async () => {
    if (!isLoading) {
      setLoading(true);
      const responce = await getFreelancerPayment();
      setData(responce);
      const responceLink = await getFreelancerPaymentLink();
      setPaymentLink(responceLink);
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) {
    return <Loading />;
  }
  const inActive = data?.account?.capabilities?.bancontact_payments === 'inactive';
  return (
    <div>
      <div className='mb-3 mt-2 settings-title'></div>
      {inActive && (
        <div class='payment-error-message'>
          Oops! It looks like your payment process is incomplete. Please complete the payment to proceed with
          your order. If you encounter any issues, feel free to contact our support team at
          <a href='support@example.com' className='ml-1 payment-email'>support@example.com</a> We apologize for any inconvenience caused. 
        </div>
      )}
      {data?.account === null ||
      (data?.account?.capabilities && Object.keys(data?.account?.capabilities)?.length === 0) ||
      inActive ? (
        <>
          <div className='d-flex align-items-center'>
            <div className='stripe-text mr-5'>Stripe connection status:</div>
            <div className='d-flex justify-content-center text-center align-items-center'>
              <div className='connected-button'>Not connected</div>
              <div className='error-svg custom-tooltip-wrapper'>
                <ErrorIcon />
                <div className='custom-tooltip top'>
                  <p>Connect your Stripe account to Tangl to charge customers and to collect payouts</p>
                </div>
              </div>
            </div>
          </div>
          <div className='payment-home mt-3'>
            <div>
              <img src={PaymentIMG} alt='banner-img' width='400px' height={'407px'} />
            </div>
            <div className='mt-3'>
              <button className='btn btn-primary' onClick={handleAccount}>
                Connect Stripe Account
              </button>
            </div>
          </div>
        </>
      ) : (
        <div>
          <table className='payment-tbl'>
            <tr>
              <td>Stripe connection status</td>
              <td className='d-flex connect-error-icon align-content-center'>
                <div className='connected mr-2'>Connected</div>
                <div className='d-flex align-items-center custom-tooltip-wrapper'>
                  <ErrorIcon />
                  <div className='custom-tooltip top'>
                    <p>Your Stripe Standard account is connected to the platform</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Stripe account ID</td>
              <td className='d-flex align-content-center account-id'>
                <div className='account mr-2'>{data?.account?.id}</div>
                <div className='d-flex align-items-center custom-tooltip-wrapper'>
                  <ErrorIcon />
                  <div className='custom-tooltip top'>
                    <p>Link to Stripe Account Dashboard</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Account type</td>
              <td>
                <div className='ml-1'>{capitalize(data?.account?.type)}</div>
              </td>
            </tr>
            <tr>
              <td>Bank name</td>
              <td>
                <div className='ml-1'>{data?.account?.external_accounts?.data[0]?.bank_name || 'N/A'}</div>
              </td>
            </tr>
            <tr>
              <td>Card payments</td>
              <td>
                <div className='connected'>{capitalize(data?.account?.capabilities?.card_payments)}</div>
              </td>
            </tr>
            <tr>
              <td>Payouts enabled</td>
              <td>
                <div className='connected'>{data?.account?.payouts_enabled && 'True'}</div>
              </td>
            </tr>
            <tr>
              <td>Additional requirements</td>
              <td>
                <div className='connected'>None</div>
              </td>
            </tr>
          </table>
          <div>
            <button
              className='disconnected-btn d-flex align-items-center'
              // onClick={() => setConnected(true)}
            >
              Disconnect Stripe Account
              <div className='custom-tooltip-wrapper ml-3'>
                <ErrorIcon />
                <div className='custom-tooltip top'>
                  <p>
                    After disconnecting account you will no longer be able to charge customers and collect
                    payouts until connecting again
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      )}

      <FreelancerModalWindow
        show={connected}
        className='treatment-delete-modal'
        centered='center'
        body={
          <div>
            <h6 className=''>Are you sure to disconnect Stripe Account?</h6>
            <div className='modal-text'>
              After disconnecting account you will no longer be able to charge customers and collect payouts
              until connecting again.
            </div>
          </div>
        }
        children={
          <div className='text-center'>
            <Button variant={'btn btn-light-shadow'} onClick={() => setConnected(false)}>
              Cancel
            </Button>
            <Button variant={'btn btn-dark'} onClick={hadleDisconnect}>
              Disconnect
            </Button>
          </div>
        }
        handleClose={() => setConnected(false)}
      />
    </div>
  );
};

export default Payments;