import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import WebsiteHeader from '../../../components/Website/Header';
import { getGuestFreelancer, getCart, getCartAddons, updateCart, getCartCheckoutSessionURL } from "../../../services/requests"
import './BookAppointment.css';
import moment from 'moment';
import WebsiteFooter from '../../../components/Website/Footer';
import ProfilePhoto from '../../../components/Freelancer/ProfilePhoto';
import { ReactComponent as IconLocation } from '../../../assets/icon/IconLocation.svg';
import { ReactComponent as IconCalendar } from '../../../assets/icon/IconCalendar.svg';
import { ReactComponent as IconClock } from '../../../assets/icon/IconClock.svg';
import { ReactComponent as IconMinus } from '../../../assets/icon/IconMinus.svg';
import { ReactComponent as IconPlus } from '../../../assets/icon/IconPlus.svg';
import { ReactComponent as IconMinusDisabled } from '../../../assets/icon/IconMinusDisabled.svg';
import { ReactComponent as IconPlusDisabled } from '../../../assets/icon/IconPlusDisabled.svg';
import { ReactComponent as IconEdit } from '../../../assets/icon/IconEdit.svg';

export const BookAppointmentCart = () => {

    const [isLoading, setLoading] = useState(false);
    const [freelancer, setFreelancer] = useState(null)
    const [cart, setCart] = useState(null)
    const [addons, setAddons] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const state = useSelector((state) => state);    
    const [errorMessage] = useState(null)
    const [customerNote, setCustomerNote] = useState()
    const [checkoutButtonDisabled, setCheckoutButtonDisabled] = useState(false)

    const [setCheckoutSessionURL] = useState(null)
    const [searchParams] = useSearchParams();

    const loadData = async (force) => {

      if (!isLoading) {      

        setLoading(true);

        if ((!cart || force) && !searchParams.get("success")) {
          await getCart().then(resultsCart =>  {

            setCart(resultsCart?.data)
            getCartAddons().then(resultsAddons => {
                setAddons(resultsAddons?.data.filter((addon) => 
                addon?.treatment_addon?.treatment_id === resultsCart?.data?.freelancer_treatment?.treatment_id))
            })

            setSelectedDate(moment(resultsCart?.data?.datetime))
            setSelectedTime(moment(resultsCart?.data?.datetime))

            getGuestFreelancer(resultsCart?.data?.freelancer_treatment?.freelancer_id).then(resultsFreelancer =>  {
                setFreelancer(resultsFreelancer?.data)
              })    

          })
        }
        setLoading(false);
      }

    };
    useEffect(() => {
      loadData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);  

    const navigate = useNavigate()
    
    const handleQuantityClick = async (action, type, id, quantity) => {

        if (action === "-") { quantity = quantity - 1 }
        else if (action === "+") { quantity = quantity + 1 }
      
        var data = { quantity: quantity }
        // eslint-disable-next-line eqeqeq
        if (type == "addon") {
          data.freelancer_treatment_addon_id = id;
        }
        else {
          data.freelancer_treatment_id = id;
        }

        await updateCart(data).then(results => {
          // eslint-disable-next-line eqeqeq
          if (results.result == "success") {
            loadData(true)
          }
        })
    }

    const handleCheckoutClick = async(cartId = null) => {

      setCheckoutButtonDisabled(true)

      if (state?.authenticated && state.role === "customer") {

        if (cartId === null) {
          cartId = cart?.id
        }
        getCartCheckoutSessionURL(cartId, customerNote).then((results) => {
          setCheckoutSessionURL(results?.checkout_session_url)  
          window.location.replace(results?.checkout_session_url);  
        })
      }

      else { navigate("/login?bookingLogin=true") }
    }

    // if thanks page
    if (searchParams.get("success")) {
      return (
        <><WebsiteHeader />
        <div className="container">
          <section className='book-appointment-freelancer__treatments row'>          
            <div className="col-4"></div>          
            <div className="col-4">
              <div className="row">
                <h5 className="book-appointment-freelancer__header-title">Thank You!</h5>
                <p style={{paddingBottom:'15px'}}>
                  Your order has now been placed and paid for. You'll receive an email shortly with 
                  more details on your booking and what to do next.
                </p>
              </div>
            </div>
          </section>
        </div>
        <WebsiteFooter /></>);
    }

    // if no cart yet
    else if (!cart && !searchParams.get("success")) {
      return (
      <><WebsiteHeader />
      <div className="container">
        <section className='book-appointment-freelancer__treatments row'>          
          <div className="col"></div>          
          <div className="col-11">
            <div className="row">
              <h5 className="book-appointment-freelancer__header-title">Your Cart</h5>
              <p>
                You have no items in your cart at the moment.
              </p>
            </div>
          </div>
        </section>
      </div>
      <WebsiteFooter /></>
      );
    }

    else {

    return (
    <>
        <WebsiteHeader />

        <section className='book-appointment-freelancer__back'>
        <div className='custom-container'>
        </div>
        </section>

        <div className="container">
        <section className='book-appointment-freelancer__treatments row'>
          
          <div className="col-1"></div>          
          <div className="col-7">

            <div className="row">
              <h5 className="book-appointment-freelancer__treatments--category-name">Your Cart</h5>
              {errorMessage ?
              <div style={{marginTop:'20px'}} className={'alert alert-danger'} dangerouslySetInnerHTML={{ __html: errorMessage }} />
              : '' }
            </div>
            
            <div className="row" style={{backgroundColor:'white', marginTop: '-15px'}}>
                <div className="col">
                    <div className="book-appointment-freelancer__treatments--treatment-name">{cart?.freelancer_treatment?.treatment?.name}</div>
                    <div className="book-appointment-freelancer__treatments--treatment-description">{cart?.freelancer_treatment?.treatment?.description}</div>
                </div>
                <div className="col book-appointment-cart__treatment-cost cart-price">
                  &euro;{cart?.freelancer_treatment?.cost?.toFixed(2)}
                </div>
                <div className="col book-appointment-cart-quantity">
                    {cart?.quantity === 1 ?
                    <Link style={{pointerEvents: 'none'}} className="book-appointment-cart-quantity-button"><IconMinusDisabled style={{transform: "scale(0.75)"}}/></Link>
                    :
                    <Link onClick={() => handleQuantityClick('-', 'treatment', cart?.freelancer_treatment?.id, cart?.quantity)} 
                          className="book-appointment-cart-quantity-button"><IconMinus style={{transform: "scale(0.75)"}}/></Link>
                    }
                    <span className="book-appointment-cart-quantity-number">{cart?.quantity}</span>                     
                    {cart?.quantity === 5 ?
                    <Link style={{pointerEvents: 'none'}} className="book-appointment-cart-quantity-button"><IconPlusDisabled style={{transform: "scale(0.75)"}}/></Link> 
                    :
                    <Link onClick={() => handleQuantityClick('+', 'treatment', cart?.freelancer_treatment?.id, cart?.quantity)} 
                          className="book-appointment-cart-quantity-button"><IconPlus style={{transform: "scale(0.75)"}}/></Link> 
                    }
                </div>
                <div className="col book-appointment-cart-cost">
                    &euro;{cart?.cost_treatment?.toFixed(2)}
                </div>
              </div>

                {addons?.length > 0 ? 
                  <>  

              <div className="row book-appointment-freelancer__treatments--addon-box-cart">                
                <div className="book-appointment-freelancer__treatments--addon-title" style={{paddingBottom: '10px'}}>Available Add-Ons:</div>
              </div>

                      {addons?.map((addon) =>
                      <>
              <div className="row book-appointment-freelancer__treatments--addon-box-row">
                <div className="col book-appointment-cart__treatments--addon-name">{addon?.treatment_addon?.name}</div>                          
                <div className="col book-appointment-cart__treatments--addon-cost cart-price">&euro;{addon?.cost.toFixed(2)}</div>
                <div className="col book-appointment-cart-quantity">
                            {(cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.quantity === 0 
                              || cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id).length === 0) ?
                                <Link style={{pointerEvents: 'none'}} className="book-appointment-cart-quantity-button"><IconMinusDisabled style={{transform: "scale(0.75)"}} /></Link>
                              :
                                <Link onClick={() => handleQuantityClick('-', 'addon', addon.id,
                                                    cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id).length > 0 ? 
                                                    cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.quantity
                                                    : 0
                                              )} 
                                              className="book-appointment-cart-quantity-button"><IconMinus style={{transform: "scale(0.75)"}}/></Link>
                            }
                                {cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id).length > 0 ? 
                                  <span className="book-appointment-cart-addon-quantity-number"><b>
                                    {cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.quantity}
                                    </b></span>
                                  : 
                                  <span className="book-appointment-cart-addon-quantity-number">0</span>
                                }   
                                {
                                // eslint-disable-next-line eqeqeq
                                cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.quantity == 5 ?
                                  <Link className="book-appointment-cart-quantity-button" style={{pointerEvents: 'none'}}><IconPlusDisabled style={{transform: "scale(0.75)"}} /></Link> 
                                :
                                  <Link onClick={() => handleQuantityClick('+', 'addon', addon.id,
                                                    cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id).length > 0 ? 
                                                    cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.quantity
                                                    : 0
                                                )} 
                                        className="book-appointment-cart-quantity-button"><IconPlus style={{transform: "scale(0.75)"}}/></Link> 
                                }
                </div>
                <div className={"col book-appointment-cart__treatments--addon-cost " +
                (cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.quantity > 0
                  ? 'addon-cost-selected':'')}>
                  {
                  // eslint-disable-next-line eqeqeq
                  cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id).length == 0 ? '€0.00' : 
                  '€' + cart?.addons?.filter((cartAddon) => addon.id === cartAddon.freelancer_treatment_addon_id)[0]?.cost_total?.toFixed(2)}
                </div>
              </div>
                      </>
                    )}
                    <div className="row" style={{backgroundColor:'#f7f0e8', paddingBottom:'10px', marginBottom:'20px'}}></div>
                  </>
                  : ''}

              <div className="row" style={{backgroundColor:'white', paddingBottom:'5px'}}>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col book-appointment-cart__booking-fee">Booking Fee:</div>
                <div className="col book-appointment-cart__booking-fee-value">&euro;{cart?.cost_fee?.toFixed(2)}</div>
              </div>

              <div className="row" style={{backgroundColor:'white', paddingBottom:'5px'}}>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col book-appointment-cart__subtotal">Subtotal:</div>
                <div className="col book-appointment-cart__subtotal-value">&euro;{cart?.cost_subtotal?.toFixed(2)}</div>
              </div>

              <div className="row" style={{backgroundColor:'white', paddingBottom:'5px'}}>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col book-appointment-cart__total">Total:</div>
                <div className="col book-appointment-cart__total-value">&euro;{cart?.cost_total?.toFixed(2)}</div>
              </div>

              <div className="row" style={{backgroundColor:'white', marginTop: '-15px'}}>
                <div className="col"></div>
                <div className="col book-appointment-freelancer__treatments--treatment-cost cart-price"></div>
                <div className="col book-appointment-cart-quantity"></div>
                <div className="col book-appointment-cart-cost align-right" style={{textAlign:'right'}}>
                  <button className="book-appointment-freelancer-__treatments--button-addtocart 
                                  book-appointment-header__button book-appointment-header__button--active"
                          style={{marginBottom:'45px'}}
                          onClick={() => handleCheckoutClick()}
                          disabled={checkoutButtonDisabled}
                                  >Book Now</button>
                </div>
              </div>
          </div>


          <div className="col-4">

<div className="col">
    <div className="book-appointment-freelancer__header--name">{freelancer?.first_name} {freelancer?.last_name}</div>
    <div className="book-appointment-freelancer__header--description">{freelancer?.description}</div>

      <div className="row">
        <div className="col-6">
      
    {state?.authenticated && state.role === "customer" ?
    <div className="book-appointment-freelancer__header--address">
        <IconLocation />                        
        <span style={{marginLeft:'5px'}}>{state.address1}</span><br />
        <span style={{marginLeft:'20px'}}>{state.address2}</span><br />
        <span style={{marginLeft:'20px'}}>{state.city}</span><br />
        <span style={{marginLeft:'20px'}}>{state.county}</span><br />
        <span style={{marginLeft:'20px'}}>{state.postcode}</span><br />

        {/*
        <button className='book-appointment-freelancers__choose-button change'>
          Change
        </button>                      
      */}
    </div>
    : '' }                    
    <div className="book-appointment-freelancer__header--date"><IconCalendar /><span style={{marginLeft:'10px'}}>{selectedDate?.format("Do MMM Y")}</span></div>
    <div className="book-appointment-freelancer__header--time"><IconClock /><span style={{marginLeft:'10px'}}>{selectedTime?.format("h:mm A")}</span></div>
      </div>
      <div className="col-2 book-appointment-freelancer__photo">                
        <ProfilePhoto freelancer={freelancer}/>
      </div>
    </div>

    <div className="book-appointment-freelancer__customer-note" style={{verticalAlign:'top', paddingTop:'12px'}}>
      <span style={{verticalAlign:'top'}}><IconEdit style={{width:'18px'}}/></span>
      <span>
          <textarea 
            placeholder="Include an optional note with your booking. E.g. parking or direction information."
            defaultValue={customerNote}
            onChange={e => setCustomerNote(e.target.value)}
            >
          </textarea>
      </span>
    </div>    

        </div>
      </div>

        </section>

        </div>
        <WebsiteFooter />
    </>
  );}

};
