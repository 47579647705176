import { useEffect, useState } from 'react';
import { getCustomerBookings } from '../../../../services/requests'
import ProfilePhotoSmall from '../../../../components/Freelancer/ProfilePhotoSmall'
import './Bookings.css'
import moment from 'moment';

const Bookings = () => {

    // getCustomerBookings
    const [bookings, setBookings] = useState(null)

    const loadData = async () => {
        await getCustomerBookings().then((bookings) => {
            setBookings(bookings?.data)
        })  
    }  

    useEffect(() => {
        loadData()
      }, [])  

    return (
<>
{bookings?.map((booking) => {

    var date = moment(new Date(booking?.datetime))
    
    return (
        <>
        <div className="container-fluid">
            <div className={'row align-items-center customer-bookings__booking-box ' + (date.isBefore() ? 'past' : '')}>

                <div className='col'>
                    <ProfilePhotoSmall />        
                    <span className="customer-bookings__freelancer-name">{booking?.freelancer?.first_name} {booking?.freelancer?.last_name}</span>
                </div>
                <div className='col'>
                    <span className="customer-bookings__treatment-category">{booking?.freelancer_treatment?.treatment?.treatment_group?.treatment_category?.name}</span><br />
                    <span className="customer-bookings__treatment-name ">{booking?.treatment_name}</span><br />

                    {booking?.customer_cart?.addons.length > 0 ?
                    <>
                    {booking?.customer_cart?.addons?.map((addon) => {
                        return (<>
                        {addon?.freelancer_treatment_addon?.treatment_addon?.name}<br />
                        </>);
                    })}                    
                    </>
                    : ''}
                </div>
                <div className='col'>
                    <span className="customer-bookings__date">{date.format("Do MMM Y")}</span><br />
                    <span className="customer-bookings__time">{date.format("h:mm A")}</span>
                </div>
                <div className='col'>
                    <b>Total Cost:</b> &euro;{booking?.cost_total?.toFixed(2)}
                    <br />
                    (inc fee of: &euro;{booking?.cost_fee?.toFixed(2)})
                </div>

            </div>
        </div>        
        </>
    );
    
})}





</>
    );
}

export default Bookings;