import {
    Navigate,
} from 'react-router-dom';
import {useSelector} from "react-redux";

const ProtectedAdminRoute = ({children}) => {
    const auth = useSelector((store) => store.authenticated);
    const role = useSelector((store) => store.role);
    if (!auth || role !== 'admin') {
        return <Navigate to="/admin/login" replace/>;
    }

    return children;
};
export default ProtectedAdminRoute;
