import { Form } from 'react-bootstrap';
import './style.css'

export const CustomTextarea = ({ label, value, name, onChange, placeholder }) => {
  return (
    <Form.Group>
      <Form.Label className='input-label'>{label}</Form.Label>
      <Form.Control
        as='textarea'
        rows={3}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className='custom-textarea '
      />
    </Form.Group>
  );
};
