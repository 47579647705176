import React from 'react';
import '../Login.css';
import '../../App.css';
import WebsiteHeader from '../../components/Website/Header';
import WebsiteFooter from '../../components/Website/Footer';

const FreelancerInfo = () => {

  return (
    <>
      <WebsiteHeader />
      <section id='login-section' className='vh-100'>
        <div className='line line-1'></div>
        <div className='line line-2'></div>
        <div className='line line-3'></div>
        <div className='line line-4'></div>
        <div className='custom-container'>
          <div className='row h-100'>
            <div className='col-lg-5 col-12 offset-lg-1 d-flex align-items-center main-login-form'>
              <div className='login-form d-flex flex-column m-lg-0 m-auto'>

                <span className='login-title'>Become a professional.</span>
                <span className='login-subtitle'>
                    <p>To join our 'Pro User' program or to learn more about it, simply send us an email at <a href="mailto: info@thegroomapp.com">info@thegroomapp.com</a>.</p>
                    <p>Our team will be delighted to assist you and answer any any questions you may have.</p>
                
                </span>
                <div className='account-text'>
                  <span>Already a pro user? <a href="/login">Login here</a>.</span>
                </div>
              </div>
            </div>
            <div className='col-5 offset-1 d-lg-block d-none'>
              <div id='freelancer-info-bg'></div>
            </div>
          </div>
        </div>
      </section>
      <WebsiteFooter />
    </>
  );
};

export default FreelancerInfo;
