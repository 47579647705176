import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import WebsiteHeader from '../../../components/Website/Header';
import WebsiteFooter from '../../../components/Website/Footer';
import {useParams} from "react-router-dom";
import { getGuestFreelancer, createCart } from "../../../services/requests"
import './BookAppointment.css';
import { getErrorMessage } from '../../../services/utils';
import moment from 'moment';
import ProfilePhoto from '../../../components/Freelancer/ProfilePhoto';
import { ReactComponent as BackArrow } from '../../../assets/icon/BackArrow.svg'
import { ReactComponent as IconLocation } from '../../../assets/icon/IconLocation.svg';
import { ReactComponent as IconCalendar } from '../../../assets/icon/IconCalendar.svg';
import { ReactComponent as IconClock } from '../../../assets/icon/IconClock.svg';

export const BookAppointmentFreelancer = () => {
  
    const {locationId, areaId, treatmentCategoryId, freelancerId, date, time} = useParams()

    const [isLoading, setLoading] = useState(false);
    const [freelancer, setFreelancer] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const state = useSelector((state) => state);    
    const [errorMessage, setErrorMessage] = useState(null)    

    const loadData = async () => {

      if (!isLoading) {      
        setLoading(true);

        if (!freelancer) {
          await getGuestFreelancer(freelancerId).then(results =>  {
            setFreelancer(results?.data)
          })
        }
        setLoading(false);

        setSelectedDate(moment.unix(date))
        setSelectedTime(moment.unix(time))
      }
    };
    useEffect(() => {
      loadData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freelancer]);  

    const navigate = useNavigate()
    
    const handleAddTreatmentToCartClick = async (treatment) => {
      addItemToCart(treatment.freelancer_treatments_id)
    }

    const handleAddAddOnToCartClick = (treatment, addon) => {
      addItemToCart(treatment.freelancer_treatments_id, addon.pivot.id)
    }

    const addItemToCart = async (treatmentId = null, treatmentAddOnId = null) => {

      setErrorMessage(null)

              selectedDate.set({h:selectedTime.format("H"), m:selectedTime.format("m")})
              let data = {
                quantity: 1,
                datetime: selectedDate.format('Y-MM-D HH:mm:00'),
                customers_date_time: selectedDate.format('Y-MM-D HH:mm:00'),
                freelancer_treatment_id: treatmentId,                
              }
              if (treatmentAddOnId) {
                data = { ...data, 
                  freelancer_treatment_addon_id: treatmentAddOnId }
              }

              await createCart(data).then( results => {
                // eslint-disable-next-line eqeqeq
                if (results.result == "error") { setErrorMessage(getErrorMessage(results)) }
                else { navigate(`/book-appointment/cart`) }      
              })
    }

    var productCountByCategory = {}
    // eslint-disable-next-line array-callback-return
    freelancer?.treatment_categories.map((category) => {
      // eslint-disable-next-line array-callback-return
      freelancer?.treatment_groups.filter((group) => group.treatment_category_id === category.id).map((group) => {
        var currentCount = productCountByCategory[category.id]
        if (!currentCount) { currentCount = 0 }
        productCountByCategory[category.id] = currentCount + freelancer?.treatments.filter((treatment) => treatment.treatment_group_id === group.id).length
      })
    })                 

    return (
    <>
        <WebsiteHeader />

        <section className='book-appointment-freelancer__back'>
        <div className='custom-container'>
            <Link to={`/book-appointment/${locationId}/${areaId}/${treatmentCategoryId}/${selectedDate?.unix()}/${selectedTime?.unix()}`}><BackArrow/> Back</Link>
        </div>
        </section>

        <div className="container">
        <section className='book-appointment-freelancer__treatments row'>
          
          <div className="col-1"></div>
          <div className="col-7">

          {errorMessage ?
          <div style={{marginTop:'20px'}} className={'alert alert-danger'} dangerouslySetInnerHTML={{ __html: errorMessage }} />
          : '' }

          {freelancer?.treatment_categories.map((category) =>                      
          <>
          { (freelancer?.treatment_groups.filter((group) => group.treatment_category_id === category.id).length > 0
            && productCountByCategory[category?.id] > 0
          ) ?
          <>
            <h5 className="book-appointment-freelancer__treatments--category-name">{category?.name}</h5>
            
            {freelancer?.treatment_groups.filter((group) => group.treatment_category_id === category.id).map((group) =>
            
          <>
            {freelancer?.treatments.filter((treatment) => treatment.treatment_group_id === group.id).length > 0 ?            

            <>
              <h6 className="book-appointment-freelancer__treatments--group-name">{group?.name}</h6>
              {freelancer?.treatments.filter((treatment) => treatment.treatment_group_id === group.id).map((treatment) =>
              <>
                <div className="row">
                  <div className="col">
                    <div className="book-appointment-freelancer__treatments--treatment-name">{treatment?.name}</div>
                    <div className="book-appointment-freelancer__treatments--treatment-description">{treatment?.description}</div>
                  </div>
                  <div className="col book-appointment-freelancer__treatments--treatment-duration">{treatment?.duration} mins</div>
                  <div className="col book-appointment-freelancer__treatments--treatment-cost cart-price">&euro;{treatment?.cost}.00</div>
                  <div className="col" style={{textAlign:'right'}}>
                    <button className="book-appointment-freelancer-__treatments--button-addtocart 
                                  book-appointment-header__button book-appointment-header__button--active"
                                  onClick={() => handleAddTreatmentToCartClick(treatment)}>Add To Cart</button>
                  </div>
                </div>
                  {freelancer?.treatment_addons.filter((addon) => addon.treatment_id === treatment.id).length > 0 ? 
                  <>  
                    <div className="book-appointment-freelancer__treatments--addon-box">
                    <div className="book-appointment-freelancer__treatments--addon-title">Add On</div>
                    
                      {freelancer?.treatment_addons.filter((addon) => addon.treatment_id === treatment.id).map((addon) =>
                      <>
                        <div className="row">
                          <div className="col">
                            <div className="book-appointment-freelancer__treatments--addon-name">{addon?.name}</div>
                          </div>
                          <div className="col book-appointment-freelancer__treatments--addon-duration">{addon?.duration} mins</div>
                          <div className="col book-appointment-freelancer__treatments--addon-cost cart-price">&euro;{addon?.cost}.00</div>
                          <div className="col" style={{textAlign:'right'}}>
                            <button className="book-appointment-freelancer-__treatments--addon-button-addtocart 
                                          book-appointment-header__button book-appointment-header__button--active"
                                          onClick={() => handleAddAddOnToCartClick(treatment, addon)}
                                          >Add To Cart</button>
                          </div>
                        </div>
                      </>
                    )}
                    </div>
                  </>
                  : ''}
                  </>
              )}
            </>


          :''}
          </>


            )}

            <hr />
            </>
            :''}
          </>          
        )}

          </div>
          
          <div className="col-4">

                <div className="col">
                    <div className="book-appointment-freelancer__header--name">{freelancer?.first_name} {freelancer?.last_name}</div>
                    <div className="book-appointment-freelancer__header--description">{freelancer?.description}</div>

                      <div className="row">
                        <div className="col-6">
                      
                    {state?.authenticated && state.role === "customer" ?
                    <div className="book-appointment-freelancer__header--address">
                        <IconLocation />                        
                        <span style={{marginLeft:'5px'}}>{state.address1}</span><br />
                        <span style={{marginLeft:'20px'}}>{state.address2}</span><br />
                        <span style={{marginLeft:'20px'}}>{state.city}</span><br />
                        <span style={{marginLeft:'20px'}}>{state.county}</span><br />
                        <span style={{marginLeft:'20px'}}>{state.postcode}</span><br />

                        {/*
                        <button className='book-appointment-freelancers__choose-button change'>
                          Change
                        </button>                      
                      */}
                    </div>
                    : '' }                    
                    <div className="book-appointment-freelancer__header--date"><IconCalendar /><span style={{marginLeft:'10px'}}>{selectedDate?.format("Do MMM Y")}</span></div>
                    <div className="book-appointment-freelancer__header--time"><IconClock /><span style={{marginLeft:'10px'}}>{selectedTime?.format("h:mm A")}</span></div>
                      </div>
                      <div className="col-2 book-appointment-freelancer__photo">                
                        <ProfilePhoto freelancer={freelancer}/>
                      </div>
                    </div>


                        </div>
                      </div>
        
        </section>
        </div>

        <WebsiteFooter />
    </>
  );
};
