import React, {useEffect, useState} from 'react';
import {getTreatment} from '../services/requests';
import Loading from './Loading';

const TreatmentForm = ({ id, hideAction, saveText }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [addAddon] = useState(false);
  const [data, setData] = useState({ name: '', description: '', preparation: '' });
  const [createAddons, setCreateAddons] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const loadData = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (id) {
        const resp = await getTreatment(id);
        setData(resp);

        setCreateAddons(
          resp?.treatment_addons.map((addon) => {
            return { ...addon, status: 'default' };
          })
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateForm = () => {
    const formErrors = [];
    if (!data.name) {
      formErrors?.push('Please enter a treatment name.');
    }
    if (!data.description) {
      formErrors?.push('Please enter a treatment description.');
    }
    if (formErrors.length > 0) {
      setErrorMessage(formErrors?.join('<br/>'));
      return false;
    } else {
      setErrorMessage('');
      return true;
    }
  };
  const submitForm = async () => {
    if (validateForm()) {
      await hideAction({ ...data, treatment_addons: createAddons });
    }
  };

  const inputChange = (e) => {
    setData({...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <form className={addAddon ? 'd-none' : 'd-block'}>
      <div className='form-group'>
        {errorMessage && (
          <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: errorMessage }} />
        )}
        <label htmlFor='treatmentName' className="input-label">Name</label>

        <input
          type='text'
          className='form-control'
          id='treatmentName'
          value={data.name}
          onChange={inputChange}
          name={'name'}
          placeholder=''
        />
      </div>
      <div className='form-group'>
        <label htmlFor='treatmentDescription' className="input-label">Description</label>
        <textarea
          name='description'
          value={data.description}
          onChange={inputChange}
          rows='4'
          className='form-control'
          id='treatmentDescription'
          placeholder=''
        ></textarea>
      </div>
      <div className='form-group'>
        <label htmlFor='treatmentPreparation' className="input-label">Preparation</label>
        <textarea
          type='text'
          className='form-control'
          id='treatmentPreparation'
          value={data.preparation}
          onChange={inputChange}
          name={'preparation'}
          placeholder=''
          rows='3'
        ></textarea>
      </div>

      {/* 
      <button
        type='button'
        className='btn btnAddTrtGroup mb-2'
        onClick={() => {
          setCreateAddons([...createAddons, { name: '' }]);
        }}
      >
        Add Treatment Add-On
      </button>
      {createAddons.map((addon, idx) => {
        if (addon.status !== 'deleted')
          return (
            <div className='form-group' key={idx}>
              <label htmlFor={'inputAddon' + idx}>Add-On</label>
              <div className='d-flex align-items-center w-100'>
                <input
                  onChange={(event) => handleOnChangeAddons(event, idx)}
                  type='text'
                  className='form-control'
                  value={addon.name}
                  id={'inputAddon' + idx}
                  placeholder='Add-On'
                />
                <span
                  onClick={() => {
                    let deletingArr = [...createAddons];
                    deletingArr[idx].status = 'deleted';
                    setCreateAddons(deletingArr);
                  }}
                  className='ml-2 delete-area-input'
                  aria-hidden='true'
                >
                  &times;
                </span>
              </div>
            </div>
          );
      })}
    */}

      <div className='form-group'></div>
      <div className='form-group'>
        <button onClick={submitForm} type='button' className='btn btn-primary'>
          Save
        </button>
      </div>
    </form>
  );
};
export default TreatmentForm;
