import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  Dropdown,
  DropdownButton,
  useAccordionButton,
} from 'react-bootstrap';
import './FreelancerTreatmentCategories.css';
import { ReactComponent as Delete } from '../../../assets/icon/IconDelete.svg';
import { ReactComponent as Edit } from '../../../assets/icon/IconEdit.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icon/ChevronDown.svg';
import { ReactComponent as Close } from '../../../assets/icon/Close.svg';
import FreelancerModalWindow from '../../../components/FreelancerModalWindow';
import ModalWindow from '../../../components/ModalWindow';

import { CustomInput } from '../../../components/CustomInput';
import {
  getfreelancreTreatmentList,
  getFreelancerGroup,
  createFreelancerGroup,
  deleteFreelancerGroup,
  createFreelancerTreatment,
  updateFreelancerTreatment,
  deleteFreelancerTreatment,
  deleteFreelancerAddon,
  getfreelancerCategory,
  createFreelancerAddon,
  updateFreelancerAddon,
} from '../../../services/requests';
import Loading from '../../../components/Loading';

const FreelancerTreatmentCategories = () => {

  const [data, setData] = useState(null);
  const [treatmentDataList, setTreatmentDataList] = useState(null);
  const [id, setId] = useState(null);
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  const [activeInnerAccordionKey, setActiveInnerAccordionKey] = useState('0');
  const [inProcess, setInProcess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addonDeleteModal, setAddonDeleteModal] = useState(false);
  const [addTreatmentModal, setAddTreatmentModal] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [delateGroupModal, setDeleteGroupModal] = useState(false);
  const [editTreatmentModal, setEditTreatmentModal] = useState(false);
  const [addonTreatmentModal, setAddonTreatmentModal] = useState(false);
  const [editaddonTreatmentModal, setEditAddonTreatmentModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [singleGroupData, setSingleGroupData] = useState(null);
  const [singleGroupDeleteData, setSingleGroupDeleteData] = useState({ filteredItem: null, group: null });
  const [singleTreatmentData, setSingleTreatmentData] = useState({ filteredItem: null, group: null });
  const [addonObjectModalData, setAddonObjectModalData] = useState({ treatment: null, addon: null });
  const [treatmentData, setTreatmentData] = useState({
    selectedOption: '',
    description: '',
    preparation: '',
    cost: '',
  });
  const [treatmentObject, setTreatmentObject] = useState(null);
  const [addonAdd, setAddonAdd] = useState({
    filteredItem: null,
    group: null,
    treatment: null,
  });
  const [addonObject, setAddonObject] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [selectedTreatmentOptionId, setSelectedTreatmentOptionId] = useState(null);
  const [selectedAddonOptionId, setSelectedAddonOptionId] = useState(null);

   // Accordion
   const handleAccordionToggle = (eventKey) => {
    setActiveAccordionKey(eventKey === activeAccordionKey ? null : eventKey);
  };
  const handleInnerAccordionToggle = (eventKey) => {
    setActiveInnerAccordionKey(eventKey === activeInnerAccordionKey ? null : eventKey);
  };

  const validateForm = (data, fieldErrorMessages) => {
    const formErrors = [];
    Object?.entries(fieldErrorMessages)?.forEach(([field, errorMessage]) => {
      if (!data?.[field]) {
        formErrors?.push(errorMessage);
      }
    });
    if (data?.preparation && !/^\d+$/.test(data?.preparation)) {
      formErrors?.push('Only numbers are allowed in the treatment duration field.');
    }
    if (data?.cost && !/^\d+$/.test(data?.cost)) {
      formErrors?.push('Only numbers are allowed in the treatment price field.');
    }
    setErrorMessage(formErrors?.join('<br/>'));
    return formErrors?.length === 0;
  };
  const treatmentValidateForm = () => {
    const fieldErrorMessages = {
      selectedOption: 'Please select a treatment.',
      preparation: 'Please enter a time.',
      cost: 'Please enter a price.',
    };
    return validateForm(treatmentData, fieldErrorMessages);
  };

  const addonValidateForm = () => {
    const fieldErrorMessages = {
      selectedOption: 'Please select a treatment.',
      cost: 'Please enter a price.',
    };
    return validateForm(treatmentData, fieldErrorMessages);
  };

  const handleOptionSelectGroup = (eventKey) => {
    setTreatmentData((prevState) => ({
      ...prevState,
      selectedOption: eventKey,
    }));
    setTreatmentObject((prevState) => ({
      ...prevState,
      name: eventKey,
    }));
    setAddonObject((prevState) => ({
      ...prevState,
      name: eventKey,
    }));
    //GROUP ID
    const selectedGroup = (treatmentDataList || [])?.find((category) => {
      return category?.id === singleGroupData?.id;
    });
    const groupId = selectedGroup
      ? selectedGroup?.treatment_groups?.find((group) => group?.name === eventKey)?.id || null
      : null;
    if (groupId) {
      setSelectedOptionId(groupId);
    }
    //TREATMENT ID
    const selectedTreatment = (treatmentDataList || []).find((category) => {
      return category?.id === singleTreatmentData?.filteredItem?.id;
    });
    const selectedTreatments = selectedTreatment?.treatment_groups?.find((treatment) => {
      return treatment?.id === singleTreatmentData?.group?.id;
    });

    const treatmentsId = selectedTreatments
      ? selectedTreatments?.treatments?.find((treatment) => treatment?.name === eventKey)?.id || null
      : null;

    if (treatmentsId) {
      setSelectedTreatmentOptionId(treatmentsId);
    }
    // ADDONS ID
    const selectedGroupAddon = (treatmentDataList || [])?.find((category) => {
      return category?.id === addonAdd?.filteredItem?.id;
    });

    const selectedTreatmentsAddons = selectedGroupAddon?.treatment_groups?.find((treatment) => {
      return treatment?.id === addonAdd?.group?.id;
    });
    const selectedAddon = selectedTreatmentsAddons?.treatments?.find((treatment) => {
      return treatment?.id === addonAdd?.treatment?.id;
    });
    const addonId = selectedAddon
      ? selectedAddon?.treatment_addons?.find((addon) => addon?.name === eventKey)?.id || null
      : null;
    if (addonId) {
      setSelectedAddonOptionId(addonId);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event?.target;
    setTreatmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddTreatmentModal = (filteredItem, group) => {
    setAddTreatmentModal(true);
    setSingleTreatmentData({ filteredItem, group });
  };
  const handleTreatment = async () => {
    const body = {
      duration: treatmentData?.preparation,
      cost: treatmentData?.cost,
      treatment_id: selectedTreatmentOptionId,
      status: 'active',
    };
    if (treatmentValidateForm()) {
      const resp = await createFreelancerTreatment(body);
      if (resp?.result === 'success') {
      setAddTreatmentModal(false);
      emptyField(setTreatmentData, setErrorMessage);
      await loadData();
    }}

  };
  const handleEditTreatment = async () => {
    const { duration, cost } = treatmentObject?.pivot;
    if (!duration || isNaN(duration) || parseInt(duration) <= 0) {
      setErrorMessage('Please enter a valid time (a positive number).');
      return;
    }
    if (!cost || isNaN(cost) || parseFloat(cost) <= 0) {
      setErrorMessage('Please enter a valid price (a positive number).');
      return;
    }
    const body = {
      duration: treatmentObject?.pivot?.duration,
      cost: treatmentObject?.pivot?.cost,
    };
    if (!inProcess) {
      setInProcess(true);
      const resp = await updateFreelancerTreatment(treatmentObject?.pivot?.id, { ...body });
      if (resp?.result === 'success') {
        setEditTreatmentModal(false);
        setErrorMessage('');
        await loadData();
      }
      setInProcess(false);
    }
  };
  const handleAddon = async () => {
    const body = {
      cost: treatmentData?.cost,
      status: 'active',
      treatment_addon_id: selectedAddonOptionId,
    };
    if (addonValidateForm()) {
      const resp = await createFreelancerAddon(body);
      if (resp?.result === 'success') {
        setAddonTreatmentModal(false);
        emptyField(setTreatmentData, setErrorMessage);
        await loadData();
      }
    }
  };
  const handleEditAddon = async () => {
    const { cost } = addonObject?.pivot;
    if (!cost || isNaN(cost) || parseFloat(cost) <= 0) {
      setErrorMessage('Please enter a valid price (a positive number).');
      return;
    }
    const body = {
      cost: addonObject?.pivot?.cost,
    };
    const resp = await updateFreelancerAddon(addonObject?.pivot?.id, { ...body });
    if (resp?.result === 'success') {
      setEditAddonTreatmentModal(false);
      setErrorMessage('');
      await loadData();
    }
  };
  const handleTreatmentDelete = async () => {
    const resDelete = await deleteFreelancerTreatment(treatmentObject?.pivot?.id);
    if (resDelete?.result === 'success') {
      setDeleteModal(false);
      await loadData();
    }
  };
  const handleAddonDelete = async () => {
    const resDelete = await deleteFreelancerAddon(addonObjectModalData?.addon?.pivot?.id);
    if (resDelete?.result === 'success') {
      setAddonDeleteModal(false);
      await loadData();
    }
  };
  const handleTreatmentObjectData = (treatment, group) => {
    setDeleteModal(true);
    setTreatmentObject(treatment);
  };
  const handleTreatmentEditObjectData = (treatment) => {
    setEditTreatmentModal(true);
    setTreatmentObject(treatment);
  };
  const handleAddonManage = (filteredItem, group, treatment) => {
    setAddonTreatmentModal(true);
    setAddonAdd({ filteredItem, group, treatment });
  };
  const handleAddonObjectData = (treatment, addon) => {
    setAddonDeleteModal(true);
    setAddonObject(addon);
    setAddonObjectModalData({ treatment, addon });
  };
  const handleAddonEditObjectData = (addon) => {
    setEditAddonTreatmentModal(true);
    setAddonObject(addon);
  };

  const loadData = async () => {
    if (!isLoading) {
      const respCategories = await getfreelancerCategory();
      setData(respCategories);
      const resp = await getfreelancreTreatmentList();
      setTreatmentDataList(resp);
      const result = await getFreelancerGroup();
      setId(result);
    }
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <button
        type='button'
        style={{ border: 'none', background: 'transparent' }}
        className={`downs-arrow ${isCurrentEventKey ? 'active' : ''}`}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }
  const handleGroupModal = (item) => {
    setAddGroupModal(true);
    setSingleGroupData(item);
  };
  const handleGroupAddSubmit = async () => {
    const body = {
      treatment_group_id: matchGroupId?.includes(selectedOptionId) ? null : selectedOptionId,
    };
      const respGroup = await createFreelancerGroup(body);
      if (respGroup?.result === 'success') {
        setAddGroupModal(false);
        emptyField(setTreatmentData, setErrorMessage);
        await loadData();
      }
  };
  const handleGroupDeleteData = (filteredItem, group) => {
    setDeleteGroupModal(true);
    setSingleGroupDeleteData({ filteredItem, group });
  };
  const handleGroupDelete = async () => {
      const resDelete = await deleteFreelancerGroup(singleGroupDeleteData?.group?.pivot?.id);
      if (resDelete?.result === 'success') {
        setDeleteGroupModal(false);
        await loadData();
      }
  };

  const matchGroupId = id?.map((item) => item?.id);
  //dropdown Group list value
  const valueCheckGroup = data
    ?.filter((filteredItem) =>
      filteredItem?.treatment_groups?.some((group) => group?.treatment_category_id === singleGroupData?.id)
    )
    .map((filteredItem1, innerIndex) => {
      return filteredItem1?.treatment_groups?.map((group) => {
        return group?.name;
      });
    });

  // dropdown treatments list value
  const valueCheckTreatment = data?.flatMap((filteredItem) =>
    filteredItem?.treatment_groups?.some(
      (group) => group?.treatment_category_id === singleTreatmentData?.filteredItem?.id
    )
      ? filteredItem?.treatment_groups?.flatMap((group) =>
          group?.treatments?.some(
            (treatment) => treatment?.treatment_group_id === singleTreatmentData?.group?.id
          )
            ? group?.treatments?.map((treatment) => treatment?.name)
            : []
        )
      : []
  );

  // dropdown addons list value
  const valueCheckaddons = data?.flatMap((filteredItem) =>
    filteredItem?.treatment_groups?.some(
      (group) => group?.treatment_category_id === addonAdd?.filteredItem?.id
    )
      ? filteredItem?.treatment_groups?.flatMap((group) =>
          group?.treatments?.some((treatment) => treatment?.treatment_group_id === addonAdd?.group?.id)
            ? group?.treatments?.flatMap((treatment) =>
                treatment?.treatment_addons?.some((addon) => addon?.treatment_id === addonAdd?.treatment?.id)
                  ? treatment?.treatment_addons?.map((item) => item?.name) || []
                  : []
              )
            : []
        )
      : []
  );
  //valueCheckTreatment Empty group
  const treatmentNames = treatmentDataList
    ?.filter((filteredItem) =>
      filteredItem?.treatment_groups?.some(
        (group) => group?.treatment_category_id === singleTreatmentData?.filteredItem?.id
      )
    )
    .map((filteredItem1) => {
      return filteredItem1?.treatment_groups
        ?.filter((group) =>
          group?.treatments?.some(
            (treatment) => treatment?.treatment_group_id === singleTreatmentData?.group?.id
          )
        )
        .map((group) =>
          group?.treatments
            .filter((treatment) => treatment?.treatment_group_id === singleTreatmentData?.group?.id)
            .map((treatment) => treatment?.name)
        );
    })
    .flat()
    .filter(Boolean);
  const validTreatments = treatmentNames?.flat()?.filter((name) => !valueCheckTreatment?.includes(name));
  const istreatmentEmpty = validTreatments?.length === 0;
  //valueCheckTreatment Empty group
  const filteredAddonsNames = treatmentDataList?.filter((item) => item?.id === addonAdd?.filteredItem?.id);
  const treatmentNamesWithAddons = filteredAddonsNames
    ?.map((item) => {
      const matchingGroups = item?.treatment_groups?.filter(
        (groupItem) => groupItem?.id === addonAdd?.group?.id
      );
      return matchingGroups?.map((groupItem) => {
        const matchingTreatments = groupItem?.treatments?.filter(
          (treatmentItem) => treatmentItem?.id === addonAdd?.treatment?.id
        );
        const addons = matchingTreatments?.flatMap((treatmentItem) => {
          const matchingAddons = treatmentItem?.treatment_addons?.filter(
            (addonItem) => addonItem?.treatment_id === addonAdd?.treatment?.id
          );
          return matchingAddons?.map((addonItem) => addonItem?.name);
        });
        return addons;
      });
    })
    .flat()
    .filter(Boolean);
  const validAddons = treatmentNamesWithAddons?.flat()?.filter((name) => !valueCheckaddons?.includes(name));
  const isAddonEmpty = validAddons?.length === 0;

  //valueCheckGroup Empty group
  const groupNames = treatmentDataList
    ?.filter((filteredItem) =>
      filteredItem?.treatment_groups?.some((group) => group?.treatment_category_id === singleGroupData?.id)
    )
    .map((filteredItem1, innerIndex) => {
      return filteredItem1?.treatment_groups?.map((group) => group?.name);
    })
    .flat()
    .filter(Boolean);
  const validGroups = groupNames?.filter((name) => !valueCheckGroup[0]?.includes(name));
  const isGroupEmpty = validGroups?.length === 0;

  const handleSwitchToggle = async (event, item, itemType) => {
    const isChecked = event?.target?.checked;
    const status = isChecked ? 'active' : 'disabled';
    const itemId = item?.pivot?.id;
    if (!isLoading) {
      setLoading(true);
      let resp;
      if (itemType === 'treatment') {
        resp = await updateFreelancerTreatment(itemId, { status });
      } else if (itemType === 'addon') {
        resp = await updateFreelancerAddon(itemId, { status });
      }
      if (resp?.result === 'success') {
        await loadData();
      }
      setLoading(false);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return (
    <main id="main-section">
        <div className="container-fluid">
            <div className="row header-section">
                <div className="col-sm-8">
                    <h2 className="titleHeading2">Treatments</h2>
                </div>
            </div>
      
        <Accordion activeKey={activeAccordionKey} onSelect={handleAccordionToggle} id='custom-accordion'>
          {data?.map((item,index) => (
            <Card key={item?.id} eventKey={index?.toString()} className='main-card mb-0'>
              <Card.Header className='main-header'>
                {item?.name}
                <div className='d-flex'>
                  <div className='mr-3'>
                    <button
                      type='button'
                      className='btn btnAddTreatment'
                      data-toggle='modal'
                      data-target='#addAreaModal'
                      onClick={() => {
                        handleGroupModal(item)
                      }}
                    >
                      Add Treatment Group
                    </button>
                  </div>
                  <div>
                    <CustomToggle eventKey={item?.id} className='accordion-button'>
                      <ChevronDown />
                    </CustomToggle>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey={item?.id}>
                <Card.Body className='accordion-body main-body'>
                  {item?.treatment_groups?.length > 0 ? (
                    <>
                      {data
                        ?.filter((filteredItem) =>
                          filteredItem?.treatment_groups?.some(
                            (group) => group?.treatment_category_id === item?.id
                          )
                        )
                        .map((filteredItem) => {
                          return filteredItem?.treatment_groups?.map((group) => {
                            return (
                              <div key={group?.id}>
                                <div className='d-flex w-100 justify-content-between' >
                                  <h6>{group?.name}</h6>
                                  <div className='d-flex align-items-center'>
                                    <div className='cursor-button mr-4 mb-1'>
                                      <Delete onClick={() =>  {
                                        handleGroupDeleteData(filteredItem, group)
                                      }} />
                                    </div>
                                    <div>
                                      <button
                                        
                                        type='button'
                                        className='btn btnAddTreatment'
                                        data-toggle='modal'
                                        data-target='#addAreaModal'
                                        onClick={() => handleAddTreatmentModal(filteredItem, group)}
                                      >
                                        Add Treatment
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <Accordion
                                  style={{marginTop:'20px'}}
                                  activeKey={activeInnerAccordionKey}
                                  onSelect={handleInnerAccordionToggle}
                                >
                                  <Card>
                                    {group?.treatments && group?.treatments?.length > 0 ? (
                                      <Card.Header className='d-block'>
                                        {group?.treatments?.map((treatment) => {
                                          return (
                                            <div key={treatment?.id}>
                                              <div
                                                className='grid-container w-100 align-items-center'
                                              >
                                                <div>
                                                  <div className='d-flex'>
                                                    <div
                                                      className={`${
                                                        treatment?.pivot?.status === 'disabled'
                                                          ? 'disabled-text treatment-title '
                                                          : 'treatment-title'
                                                      }`}
                                                    >
                                                      {treatment?.name}
                                                    </div>

                                                    {treatment?.pivot?.status === 'disabled' && (
                                                      <div className='disabled-switch'>
                                                        
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div
                                                    className={`${
                                                      treatment?.pivot?.status === 'disabled'
                                                        ? 'disabled-text treatment-subtitle '
                                                        : 'treatment-subtitle'
                                                    }`}
                                                  >
                                                    {treatment?.description}
                                                  </div>
                                                </div>
                                                <div
                                                  className={`${
                                                    treatment?.pivot?.status === 'disabled'
                                                      ? 'disabled-text price-text text-right treatment-subtitle '
                                                      : 'price-text text-right treatment-subtitle'
                                                  }`}
                                                >
                                                  €{treatment?.pivot?.cost}
                                                </div>
                                                <div
                                                  className={`${
                                                    treatment?.pivot?.status === 'disabled'
                                                      ? 'disabled-text text-right treatment-subtitle ml-2 '
                                                      : 'text-right treatment-subtitle ml-2'
                                                  }`}
                                                >
                                                  {treatment?.pivot?.duration ?treatment?.pivot?.duration :0}
                                                   {' '}mins
                                                </div>
                                                <div className='m-0 justify-content-end d-flex treatment-switch'>
                                                  <div className='custom-switch'>
                                                    <input
                                                      type='checkbox'
                                                      id={'switch' + treatment?.id}
                                                      className='d-none'
                                                      checked={treatment?.pivot?.status === 'active'}
                                                      onChange={(event) =>
                                                        handleSwitchToggle(event, treatment, 'treatment')
                                                      }
                                                    />

                                                    <label
                                                      htmlFor={'switch' + treatment?.id}
                                                      className='mb-0'
                                                    >
                                                      <span className='custom-switch-box'>
                                                        <span className='custom-switch-dot'></span>
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>

                                                <div className='text-right cursor-button mr-3'>
                                                  {treatment?.pivot?.status === 'active' ? (
                                                    <Edit
                                                      onClick={() => handleTreatmentEditObjectData(treatment)}
                                                    />
                                                  ) : (
                                                    <Edit disabled />
                                                  )}
                                                </div>

                                                <div className='cursor-button'>
                                                  {treatment?.pivot?.status === 'active' ? (
                                                    <Delete
                                                      onClick={() =>
                                                        handleTreatmentObjectData(treatment, group)
                                                      }
                                                    />
                                                  ) : (
                                                    <Delete disabled />
                                                  )}
                                                </div>
                                                <div>
                                                  {treatment?.pivot?.status === 'active' ? (
                                                    <CustomToggle
                                                      eventKey={treatment?.id}
                                                      className='accordion-button'
                                                    >
                                                      <ChevronDown />
                                                    </CustomToggle>
                                                  ) : (
                                                      <ChevronDown disabled  className='ml-2'/>
                                                  )}
                                                </div>
                                              </div>
                                              <Accordion.Collapse eventKey={treatment?.id} className='ml-2'>
                                                <Card.Body className='addon-card'>
                                                  <div className='d-flex w-100 align-items-center grid-container addon-container justify-content-between'>
                                                    <div className='add-ons-text'>Add-Ons</div>
                                                    <button
                                                      style={{marginTop:'15px', marginBottom:'15px'}}
                                                      type='button'
                                                      className='btn btnAddTreatment'
                                                      data-toggle='modal'
                                                      data-target='#addAreaModal'
                                                      onClick={() =>
                                                        handleAddonManage(filteredItem, group, treatment)
                                                      }
                                                    >
                                                      Add Add-On
                                                    </button>
                                                  </div>
                                                  {treatment?.treatment_addons &&
                                                  treatment?.treatment_addons?.length > 0 ? (
                                                    <>
                                                      {treatment?.treatment_addons?.map((addon) => {
                                                        return (
                                                          <div
                                                            className='grid-container addon-container'
                                                            key={addon?.id}
                                                          >
                                                            <div className='d-flex'>
                                                              <div
                                                                className={`${
                                                                  addon?.pivot?.status === 'disabled' &&
                                                                  'disabled-text'
                                                                }`}
                                                              >
                                                                {addon?.name}
                                                              </div>
                                                              <div>
                                                                {addon?.pivot?.status === 'disabled' && (
                                                                  <div className='disabled-switch'>
                                                                    Disabled add-on
                                                                  </div>
                                                                )}
                                                              </div>
                                                            </div>
                                                            <div className='text-left treatment-subtitle  ml-2'>
                                                              {/* {addon?.preparation ? addon?.preparation : 0}{' '}
                                                              mins */}
                                                            </div>
                                                            <div
                                                              className={`${
                                                                addon?.pivot?.status === 'disabled'
                                                                  ? 'disabled-text text-right treatment-subtitle price-text '
                                                                  : 'price-text text-right treatment-subtitle'
                                                              }`}
                                                            >
                                                              €{addon?.pivot?.cost}
                                                            </div>
                                                            {/* <div>Blank Div</div> */}
                                                            <div className='m-0 mr-3 justify-content-end d-flex addon-switch'>
                                                              <div className='custom-switch '>
                                                                <input
                                                                  type='checkbox'
                                                                  id={'switch' + addon?.id}
                                                                  className='d-none'
                                                                  checked={addon?.pivot?.status === 'active'}
                                                                  onChange={(event) =>
                                                                    handleSwitchToggle(event, addon, 'addon')
                                                                  }
                                                                />
                                                                <label
                                                                  htmlFor={'switch' + addon?.id}
                                                                  className='mb-0'
                                                                >
                                                                  <span className='custom-switch-box'>
                                                                    <span className='custom-switch-dot'></span>
                                                                  </span>
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className='text-right cursor-button mr-4'>
                                                              {addon?.pivot?.status === 'active' ? (
                                                                <Edit
                                                                  onClick={() =>
                                                                    handleAddonEditObjectData(addon)
                                                                  }
                                                                />
                                                              ) : (
                                                                <Edit disabled />
                                                              )}
                                                            </div>

                                                            <div className='cursor-button'>
                                                              {addon?.pivot?.status === 'active' ? (
                                                                <Delete
                                                                  onClick={() =>
                                                                    handleAddonObjectData(treatment, addon)
                                                                  }
                                                                />
                                                              ) : (
                                                                <Delete disabled />
                                                              )}
                                                            </div>
                                                            <div>{/* Blank Div */}</div>
                                                          </div>
                                                        );
                                                      })}
                                                    </>
                                                  ) : (

                                                    <div
                                                            className='grid-container addon-container'>
                                                    <p style={{fontSize: '14px', fontWeight:'300'}}><i>
                                                      You haven't any add-ons for this treatment.
                                                    </i></p>
                                                    </div>
                                                  )}
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </div>
                                          );
                                        })}
                                      </Card.Header>
                                    ) : (
                                      <p><i>You haven't added any treatments to this group yet.</i></p>
                                    )}
                                  </Card>
                                </Accordion>
                              </div>
                            );
                          });
                        })}
                    </>
                  ) : (
                    <p><i>You haven't added any groups to this category yet.</i></p>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>

      <ModalWindow
        show={delateGroupModal}
        className='treatment-delete-modal'
        centered='center'
        title={'Remove Group'}
        body={          
          'Are you sure you want to remove this group from ' + singleGroupDeleteData?.filteredItem?.name + '?'
        }
        hideAction={async () => { handleGroupDelete() }}
        handleClose={() => { setDeleteGroupModal(false); }}
      />

      <ModalWindow
        show={deleteModal}
        className='treatment-delete-modal'
        centered='center'
        title={'Remove Treatment'}
        body={
          'Are you sure you want to remove this treatment?'
        }
        hideAction={async () => { handleTreatmentDelete() }}
        handleClose={() => { setDeleteModal(false); }}
      />

      <ModalWindow
        show={addonDeleteModal}
        className='treatment-delete-modal'
        centered='center'
        title={'Remove Treatment Add-On'}
        body={
          'Are you sure you want to remove this treatment add-on?'
        }
        hideAction={async () => { handleAddonDelete() }}
        handleClose={() => { setAddonDeleteModal(false); }}
      />      

      <FreelancerModalWindow
        show={addGroupModal}
        className='treatment-delete-modal add-treatment-modal'
        centered='center'
        body={
          <div className='modal-body'>
            <div className='d-flex justify-content-between w-100 mb-3'>
              <h5>Add Treatment Group</h5>
              <div
                className='close-modal'
                onClick={() => {
                  setAddGroupModal(false);
                  emptyField(setTreatmentData, setErrorMessage);
                }}
              >
                <Close />
              </div>
            </div>
            <div className='schedule-subtitle mb-2' id='dropdown-basic-button-treatment'>
              {errorMessage && (
                <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              
              {treatmentData && (
                <DropdownButton
                  title={
                    treatmentData?.selectedOption ? (
                      treatmentData?.selectedOption
                    ) : (
                      <span>&nbsp;</span>
                    )
                  }
                  onSelect={handleOptionSelectGroup}
                >
                  {isGroupEmpty ? (
                    <Dropdown.Item disabled className='fw-bolder'>
                      No group found
                    </Dropdown.Item>
                  ) : (
                    validGroups?.map((name, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={name}
                        active={treatmentData?.selectedOption === name}
                      >
                        {name}
                      </Dropdown.Item>
                    ))
                  )}
                </DropdownButton>
              )}
            </div>
          </div>
        }
        children={
          <div className='text-center'>
            <Button
              variant={'btn btn-dark'}
              type='button'
              onClick={handleGroupAddSubmit}
              disabled={treatmentData?.selectedOption === ''}
            >
              Save
            </Button>
          </div>
        }
        handleClose={() => {
          setAddGroupModal(false);
          emptyField(setTreatmentData, setErrorMessage);
        }}
      />










      <FreelancerModalWindow
        show={addTreatmentModal}
        className='treatment-delete-modal add-treatment-modal'
        centered='center'
        body={
          <div className='modal-body'>
            <div className='d-flex justify-content-between w-100 mb-3'>
              <h5>Add Treatment</h5>
              <div
                className='close-modal'
                onClick={() => {
                  setAddTreatmentModal(false);
                  emptyField(setTreatmentData, setErrorMessage);
                }}
              >
                <Close />
              </div>
            </div>
            <div className='schedule-subtitle mb-2' id='dropdown-basic-button-treatment'>
              {errorMessage && (
                <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              <label className='input-label form-label'>Treatment</label>
              {treatmentData && (
                <DropdownButton
                  title={
                    treatmentData?.selectedOption ? (
                      treatmentData?.selectedOption
                    ) : (
                      <span></span>
                    )
                  }
                  onSelect={handleOptionSelectGroup}
                >
                  {istreatmentEmpty ? (
                    <Dropdown.Item disabled className='fw-bolder'>
                      No Treatment found
                    </Dropdown.Item>
                  ) : (
                    validTreatments?.map((name, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={name}
                        active={treatmentData?.selectedOption === name}
                      >
                        {name}
                      </Dropdown.Item>
                    ))
                  )}
                </DropdownButton>
              )}
            </div>
            <div className='mb-2'>
              <CustomInput
                type='text'
                label='Duration (minutes)'
                placeholder=''
                value={treatmentData?.preparation}
                onChange={handleChange}
                name='preparation'
              />
            </div>
            <div>
              <CustomInput
                type='text'
                label='Price (€)'
                placeholder=''
                value={treatmentData?.cost}
                name='cost'
                onChange={handleChange}
              />
            </div>
          </div>
        }
        children={
          <div className='text-center'>
            <Button variant={'btn btn-dark'} type='button' onClick={handleTreatment}>
              Save
            </Button>
          </div>
        }
        handleClose={() => {
          setAddTreatmentModal(false);
          emptyField(setTreatmentData, setErrorMessage);
        }}
      />
      <FreelancerModalWindow
        show={editTreatmentModal}
        className='treatment-delete-modal add-treatment-modal'
        centered='center'
        body={
          <div className='modal-body'>
            <div className='d-flex justify-content-between w-100 mb-3'>
              <h4>Edit Treatment</h4>
              <div
                className='close-modal'
                onClick={() => {
                  setEditTreatmentModal(false);
                  setErrorMessage('');
                }}
              >
                <Close />
              </div>
            </div>
            <div className='schedule-subtitle mb-2' id='dropdown-basic-button-treatment'>
              {errorMessage && (
                <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              <label className='input-label form-label'>Treatment</label>
              {treatmentObject && (
                <DropdownButton title={treatmentObject?.name} onSelect={handleOptionSelectGroup} disabled>
                  {istreatmentEmpty ? (
                    <Dropdown.Item disabled className='fw-bolder'>
                      No Treatment found.
                    </Dropdown.Item>
                  ) : (
                    validTreatments?.map((name, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={name}
                      >
                        {name}
                      </Dropdown.Item>
                    ))
                  )}
                </DropdownButton>
              )}
            </div>
            <div className='mb-2'>
              <CustomInput
                type='text'
                label='Duration (minutes)'
                placeholder=''
                value={treatmentObject?.pivot?.duration}
                onChange={(event) => {
                  let duration = {
                    ...treatmentObject,
                    pivot: {
                      ...treatmentObject?.pivot,
                      duration: event?.target?.value,
                    },
                  };
                  setTreatmentObject(duration);
                }}
              />
            </div>
            <div>
              <CustomInput
                type='text'
                label='Price (€)'
                placeholder=''
                value={treatmentObject?.pivot?.cost}
                onChange={(event) => {
                  let cost = {
                    ...treatmentObject,
                    pivot: {
                      ...treatmentObject?.pivot,
                      cost: event?.target?.value,
                    },
                  };
                  setTreatmentObject(cost);
                }}
              />
            </div>
          </div>
        }
        children={
          <div className='text-center'>
            <Button variant={'btn btn-dark'} type='button' onClick={handleEditTreatment}>
              Save
            </Button>
          </div>
        }
        handleClose={() => {
          setEditTreatmentModal(false);
          setErrorMessage('');
        }}
      />
      <FreelancerModalWindow
        show={addonTreatmentModal}
        className='treatment-delete-modal add-treatment-modal'
        centered='center'
        body={
          <div className='modal-body'>
            <div className='d-flex justify-content-between w-100 mb-3'>
              <h4>Add Treatment Add-On</h4>
              <div
                className='close-modal'
                onClick={() => {
                  setAddonTreatmentModal(false);
                  emptyField(setTreatmentData, setErrorMessage);
                }}
              >
                <Close />
              </div>
            </div>
            <div className='schedule-subtitle mb-2' id='dropdown-basic-button-treatment'>
              {errorMessage && (
                <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              <label className='input-label form-label'>Add-On</label>
              {treatmentData && (
                <DropdownButton
                  title={
                    treatmentData?.selectedOption
                      ? treatmentData?.selectedOption
                      : ''
                  }
                  onSelect={handleOptionSelectGroup}
                >
                  {isAddonEmpty ? (
                    <Dropdown.Item disabled className='fw-bolder'>
                      No Add-on found
                    </Dropdown.Item>
                  ) : (
                    validAddons?.map((name, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={name}
                        active={treatmentData?.selectedOption === name}
                      >
                        {name}
                      </Dropdown.Item>
                    ))
                  )}
                </DropdownButton>
              )}
            </div>
            <div>
              <CustomInput
                type='text'
                label='Price (€)'
                placeholder=''
                value={treatmentData?.cost}
                name='cost'
                onChange={handleChange}
              />
            </div>
          </div>
        }
        children={
          <div className='text-center'>
            <Button variant={'btn btn-dark'} type='button' onClick={handleAddon}>
              Save
            </Button>
          </div>
        }
        handleClose={() => {
          setAddonTreatmentModal(false);
          emptyField(setTreatmentData, setErrorMessage);
        }}
      />
      <FreelancerModalWindow
        show={editaddonTreatmentModal}
        className='treatment-delete-modal add-treatment-modal'
        centered='center'
        body={
          <div className='modal-body'>
            <div className='d-flex justify-content-between w-100 mb-3'>
              <h4>Edit Treatment Add-On</h4>
              <div
                className='close-modal'
                onClick={() => {
                  setEditAddonTreatmentModal(false);
                  setErrorMessage('');
                }}
              >
                <Close />
              </div>
            </div>
            <div className='schedule-subtitle mb-2' id='dropdown-basic-button-treatment'>
              {errorMessage && (
                <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: errorMessage }} />
              )}
              <label className="input-label form-label">Add-On</label>
              {addonObject && (
                <DropdownButton
                  title={addonObject?.name ? addonObject?.name : ''}
                  onSelect={handleOptionSelectGroup} disabled
                >
                  {isAddonEmpty ? (
                    <Dropdown.Item disabled className='fw-bolder'>
                      No Add-on found
                    </Dropdown.Item>
                  ) : (
                    validAddons?.map((name, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={name}
                        active={addonObject?.selectedOption === name}
                      >
                        {name}
                      </Dropdown.Item>
                    ))
                  )}
                </DropdownButton>
              )}
            </div>
            <div>
              <CustomInput
                type='text'
                label='Price'
                placeholder=''
                value={addonObject?.pivot?.cost}
                onChange={(event) => {
                  let cost = {
                    ...addonObject,
                    pivot: {
                      ...addonObject.pivot,
                      cost: event?.target?.value,
                    },
                  };
                  setAddonObject(cost);
                }}
              />
            </div>
          </div>
        }
        children={
          <div className='text-center'>
            <Button variant={'btn btn-primary'} type='button' onClick={handleEditAddon}>
              Save
            </Button>
          </div>
        }
        handleClose={() => {
          setEditAddonTreatmentModal(false);
          setErrorMessage('');
        }}
      />
    </main>
  );
};
export default FreelancerTreatmentCategories;

function emptyField(setTreatmentData, setErrorMessage) {
  setTreatmentData({
    selectedOption: '',
    preparation: '',
    cost: '',
  });
  setErrorMessage('');
}
