import React, {useEffect, useState} from 'react';
import {getAdminNew} from "../services/requests";
import Loading from "./Loading";
import { CustomEditor } from './CustomEditor/CustomEditor';

const NewsForm = ({id, hideAction, saveText}) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({title: "", body: "", is_published: 1});
    const [errorMessage, setErrorMessage] = useState('');
    const validateForm = () => {
        const formErrors = [];
        if (!data?.title) {
            formErrors.push("Please enter a title.");
        }
        if (!data?.body) {
            formErrors.push("Please enter a body.");
        }
        setErrorMessage(formErrors?.join('<br/>'));
        return formErrors.length === 0;
    };
    const loadData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            if (id) {
                const resp = await getAdminNew(id);
                setData(resp.data);
            }
        }
    }

    useEffect(() => {
        loadData().then(() => {
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = async () => {
        if (validateForm()) {
            await hideAction(data);
        }
    }

    const handleEditorChange = (event, editor) => {
        const editorData = editor?.getData();
        setData({...data, "body": editorData});
    };

    const inputChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }
    useEffect(() => {
        if (id) {
            loadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    if (isLoading) {
        return <Loading/>;
    }
    return <form>
        <div className="form-group">
            {errorMessage && <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: errorMessage}}/>}
            <label htmlFor="newsTitle" className="input-label">Title</label>
            <input type="text" className="form-control" id="newsTitle" value={data?.title} onChange={inputChange}
                   name={'title'} placeholder=""/>
        </div>
        <div className="form-group">
            <div className='label-text'>Body</div>
            <CustomEditor data={data?.body} onChange={handleEditorChange} />
        </div>

        <div className="form-group">
            <label htmlFor="newsPublished" className="input-label">Status</label>
            <select className="form-control" name="is_published" id="is_published" 
                defaultValue={data?.is_published}
                onChange={inputChange}
                >
                <option value="true">Published</option>
                <option value="false">Hidden</option>
            </select>         
        </div>

        <div className="form-group">
            <button onClick={submitForm} type="button"
                    className="btn btn-primary">Save</button>
        </div>
        
    </form>;
}
export default NewsForm;
