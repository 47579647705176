import React, {useEffect, useState} from 'react';
import {getTreatmentAddon} from "../services/requests";
import Loading from "./Loading";

const AddonForm = ({id, treatmentId, hideAction, saveText}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({name: ""});
    const [errorMessage, setErrorMessage] = useState('');
    const validateForm = () => {
        if (!data.name) {
          setErrorMessage('Please enter a name.');
          return false;
        }
        setErrorMessage('');
        return true;
      };
    const loadData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            if (id) {
                const resp = await getTreatmentAddon(id);
                setData(resp);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitForm = async () => {

        if (validateForm()) {
            await hideAction(data);
        }
    }
    const inputChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }
    useEffect(() => {
        if (id) {
            loadData();
        }
        else if (treatmentId) {
            setData({...data, 'treatment_id': treatmentId});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    if (isLoading) {
        return <Loading/>;
    }
    return <form>
        <div className="form-group">
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <label htmlFor="addonName" className="input-label">Name</label>
            <input type="text" className="form-control" id="addonName" value={data.name} onChange={inputChange}
                   name={'name'} placeholder=""/>
            <input type="hidden" name="treatment_id" id="treatment_id" value={treatmentId} />
        </div>
        <div className="form-group">
        </div>
        <div className="form-group">
            <button onClick={submitForm} type="button"
                    className="btn btn-primary">{saveText ? saveText : 'Save'}</button>
        </div>
    </form>;
}
export default AddonForm;
