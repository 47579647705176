import React, {Fragment, useEffect, useState} from 'react';
import {getTreatmentCategory} from "../services/requests";
import Loading from "./Loading";
import ModalForm from "./ModalForm";
import GroupForm from "./GroupForm";

const CategoryForm = ({id, hideAction, saveText, showTreatmentGroups, handleFileChange}) => {
    const API_URL = process.env.REACT_APP_IMAGE;
    const [isLoading, setIsLoading] = useState(false);
    const [addGroup, setAddGroup] = useState(false);
    const [data, setData] = useState({name: ""});
    const [groups, setGroups] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const validateForm = () => {
        if (!data.name) {
            setErrorMessage('Please enter a category name.');
            return false;
        }
        setErrorMessage('');
        return true;
    };
    const loadData = async () => {
        if (!isLoading) {
            setIsLoading(true);
            if (id) {
                const resp = await getTreatmentCategory(id, true);
                return resp;
            }
        }
    }

    const submitForm = async () => {
        if (validateForm()) {
            await hideAction(data);
        }
    }
    const inputChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }
    const groupChange = (e, idx) => {
        let updatedGroup = [...groups];
        updatedGroup[idx].name = e.target.value;
        setGroups(updatedGroup);
    }
    useEffect(() => {
        if (id) {
            loadData().then((resp) => {
                setData(resp);
                setGroups(resp.treatment_groups);
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    if (isLoading) {
        return <Loading/>;
    }
    return <form>
        <div className="form-group">
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

            <label htmlFor="catName" className="input-label">Name</label>
            <input style={{marginBottom: '20px'}}
                    type="text" className="form-control" id="catName" defaultValue={data.name} onChange={inputChange}
                   name={'name'} placeholder=""/>
            
            <label htmlFor="catImage" className="input-label">Image</label>
            <input  style={{marginBottom: '20px'}}
                    type="file" className="form-control" id="image" onChange={handleFileChange}
                    name="image" placeholder="" />

            {data.image && 
            <img src={API_URL + '/treatment_categories/' + data.image} alt="" 
                style={{width: 100, height: 100}} />}
                 
        </div>
        <Fragment>

            {showTreatmentGroups === "true" ?

            <>
            <button type="button" className="btn btnAddTrtGroup" onClick={() => {
                setAddGroup(true);
            }}>Add Treatment Group
            </button>            
            <ModalForm show={addGroup} title={'Add Group'}
                       body={<GroupForm hideAction={
                           async (groupData) => {
                               if (id) groupData.treatment_category_id = id;
                               const newGroups = [...groups, groupData];
                               setGroups(newGroups);
                               setData({...data, treatment_groups: newGroups})
                               setAddGroup(false);
                           }
                       }/>}
                       saveText={'Save'}
                       handleClose={() => {
                           setAddGroup(false)
                       }}
            />
            <ul className="groups-list">
                {!addGroup ? groups?.map((group, idx) => {
                    return (
                        <li key={idx}>
                            <input className="form-input" type="text" id={'group-' + idx}
                                   defaultValue={group.name || ''}
                                   onChange={(event) => groupChange(event, idx)}
                                   name={'group-' + idx}/>
                        </li>
                    )
                }) : null}
            </ul>
            </>
            :''}
        </Fragment>
        <div className="form-group">
        </div>
        <div className="form-group">
            <button onClick={submitForm} type="button"
                    className="btn btn-primary">{saveText ? saveText : 'Save'}</button>
        </div>
    </form>;
}
export default CategoryForm;
